export const WORDS = [
    'отпад',
    'крема',
    'женка',
    'тутун',
    'робот',
    'лекар',
    'ефект',
    'куфер',
    'пејач',
    'плажа',
    'долап',
    'чинар',
    'мотор',
    'барок',
    'жртва',
    'пруга',
    'ќумур',
    'тигар',
    'крило',
    'флека',
    'малер',
    'ѓавол',
    'знаме',
    'архив',
    'трага',
    'зелка',
    'астма',
    'рамка',
    'камин',
    'бујни',
    'семка',
    'шилец',
    'ловец',
    'ремен',
    'афект',
    'идила',
    'скала',
    'литар',
    'сепса',
    'испит',
    'румба',
    'внука',
    'стега',
    'потег',
    'сплав',
    'повик',
    'водич',
    'пекол',
    'просо',
    'врата',
    'жалба',
    'почва',
    'ајвар',
    'даска',
    'полза',
    'блуза',
    'метод',
    'молба',
    'крчма',
    'весло',
    'трупа',
    'племе',
    'копје',
    'билет',
    'чадор',
    'бајче',
    'склад',
    'вагон',
    'жагор',
    'банда',
    'ресор',
    'место',
    'синап',
    'бакар',
    'зурла',
    'газда',
    'масер',
    'порок',
    'замка',
    'ѓубре',
    'шанса',
    'бујна',
    'север',
    'мазут',
    'афион',
    'агент',
    'сокак',
    'јуриш',
    'скица',
    'салеп',
    'ликер',
    'болва',
    'доказ',
    'зајре',
    'барут',
    'џигер',
    'цивил',
    'амбар',
    'тапет',
    'балет',
    'зебра',
    'брука',
    'билка',
    'екипа',
    'клада',
    'лидер',
    'бурни',
    'бисер',
    'цицач',
    'вејка',
    'опфат',
    'нанос',
    'бамја',
    'става',
    'молер',
    'меана',
    'икона',
    'богат',
    'покер',
    'залет',
    'болен',
    'одбор',
    'пораз',
    'злоба',
    'армас',
    'вазна',
    'боење',
    'дарба',
    'тунел',
    'облик',
    'кукла',
    'акмак',
    'битен',
    'вишна',
    'оглас',
    'палма',
    'чорба',
    'креда',
    'ајван',
    'малку',
    'вовед',
    'учење',
    'белка',
    'канта',
    'вотка',
    'отпор',
    'гусар',
    'јатка',
    'мозок',
    'десет',
    'дувло',
    'клише',
    'хумус',
    'бомба',
    'оклоп',
    'собир',
    'браво',
    'симит',
    'фалба',
    'штука',
    'бизон',
    'услов',
    'алтар',
    'сенка',
    'земја',
    'гениј',
    'момче',
    'ткиво',
    'кугла',
    'народ',
    'потоп',
    'зенит',
    'аграр',
    'скеле',
    'данок',
    'табла',
    'рибар',
    'торба',
    'детаљ',
    'хајка',
    'роман',
    'памет',
    'камен',
    'вреќа',
    'расол',
    'пудра',
    'бавча',
    'копно',
    'чакал',
    'љубов',
    'избор',
    'крава',
    'отсек',
    'играч',
    'книга',
    'лихва',
    'нитка',
    'аскет',
    'судир',
    'бунар',
    'сфера',
    'проза',
    'запад',
    'боцка',
    'жребе',
    'мачка',
    'чизма',
    'исход',
    'одвод',
    'волна',
    'тајна',
    'торзо',
    'мрена',
    'рогач',
    'буџет',
    'јатак',
    'песок',
    'акорд',
    'мачор',
    'створ',
    'шајка',
    'вирус',
    'алтан',
    'снага',
    'закуп',
    'кокос',
    'океан',
    'отчет',
    'леток',
    'псалт',
    'урина',
    'синус',
    'помен',
    'ќерка',
    'фокус',
    'легло',
    'целер',
    'калем',
    'бакал',
    'успех',
    'чудак',
    'тетин',
    'мелез',
    'журка',
    'рубин',
    'носач',
    'врева',
    'птица',
    'хунта',
    'палто',
    'берба',
    'грива',
    'мечта',
    'олово',
    'рампа',
    'зијан',
    'ларва',
    'салдо',
    'маска',
    'обрач',
    'цифра',
    'класа',
    'виски',
    'цреша',
    'арома',
    'кадар',
    'ќофте',
    'школа',
    'крзно',
    'ветар',
    'седло',
    'локал',
    'сосед',
    'бубаќ',
    'пожар',
    'зафат',
    'батак',
    'ѓувеч',
    'тепих',
    'житен',
    'молња',
    'војна',
    'редар',
    'карта',
    'вокал',
    'слика',
    'криза',
    'непце',
    'конак',
    'котва',
    'обред',
    'набор',
    'волеј',
    'тенис',
    'излез',
    'хиена',
    'актер',
    'немоќ',
    'жилет',
    'минус',
    'чанта',
    'фазан',
    'склоп',
    'цевка',
    'цокле',
    'белец',
    'желка',
    'талог',
    'џагор',
    'софра',
    'тесла',
    'јамка',
    'одело',
    'маана',
    'накит',
    'столб',
    'околу',
    'кујна',
    'нарав',
    'патка',
    'борец',
    'гуска',
    'дуќан',
    'терор',
    'траса',
    'орган',
    'улога',
    'лебед',
    'беќар',
    'отказ',
    'страв',
    'танго',
    'памук',
    'тиква',
    'вујна',
    'регал',
    'монах',
    'сласт',
    'забар',
    'шумар',
    'виола',
    'казна',
    'декор',
    'канал',
    'лесно',
    'догма',
    'морал',
    'бајка',
    'пчела',
    'масив',
    'фарма',
    'шпица',
    'радар',
    'брада',
    'декан',
    'пакет',
    'биено',
    'глоба',
    'житие',
    'излет',
    'сорта',
    'букле',
    'лисец',
    'ребро',
    'круша',
    'налог',
    'извоз',
    'шпага',
    'ваган',
    'коњак',
    'базен',
    'шпајз',
    'петле',
    'аџија',
    'писмо',
    'немир',
    'умник',
    'вафла',
    'битка',
    'бруто',
    'цвеќе',
    'фурна',
    'бучни',
    'живот',
    'берза',
    'барај',
    'божем',
    'китка',
    'млеко',
    'вежба',
    'летач',
    'плата',
    'верба',
    'сабја',
    'сомун',
    'божиќ',
    'сарма',
    'бутик',
    'татко',
    'израз',
    'клупа',
    'жабар',
    'мазга',
    'бадем',
    'табло',
    'рачка',
    'лишај',
    'нацрт',
    'шеќер',
    'ореол',
    'норма',
    'допир',
    'арија',
    'вазал',
    'талон',
    'манџа',
    'однос',
    'бурек',
    'писар',
    'драма',
    'пушач',
    'вујко',
    'наука',
    'тапан',
    'цимет',
    'округ',
    'девет',
    'глужд',
    'шешир',
    'абдал',
    'еднаш',
    'спорт',
    'бовча',
    'разум',
    'хокеј',
    'исказ',
    'занес',
    'мотив',
    'багаж',
    'трска',
    'таван',
    'биста',
    'божур',
    'среда',
    'фоаје',
    'ѕвоно',
    'ендек',
    'стадо',
    'везба',
    'досие',
    'испис',
    'исток',
    'брест',
    'фраер',
    'откуп',
    'бајач',
    'одмор',
    'лушпа',
    'април',
    'корал',
    'запис',
    'шивач',
    'папка',
    'отров',
    'пеење',
    'сатар',
    'граѓа',
    'брава',
    'папок',
    'борци',
    'ковач',
    'бреме',
    'автор',
    'перде',
    'тираж',
    'жител',
    'џокеј',
    'вошка',
    'пцост',
    'албум',
    'слово',
    'гулаб',
    'бреза',
    'арсен',
    'штица',
    'алеја',
    'напис',
    'точка',
    'пркос',
    'фенер',
    'бибер',
    'баник',
    'нагон',
    'проба',
    'разно',
    'мамка',
    'лозје',
    'брана',
    'омлет',
    'печка',
    'оддел',
    'облог',
    'ронка',
    'скара',
    'слива',
    'рожба',
    'очила',
    'измет',
    'фиока',
    'извор',
    'рагби',
    'поема',
    'сируп',
    'улица',
    'фишек',
    'резба',
    'каење',
    'етапа',
    'пасош',
    'извод',
    'лулка',
    'музеј',
    'балон',
    'алиби',
    'инает',
    'графа',
    'вечен',
    'ѕиври',
    'надеж',
    'бесач',
    'датум',
    'табор',
    'бочва',
    'греда',
    'химна',
    'власт',
    'фосил',
    'мрежа',
    'труба',
    'бедро',
    'ќорав',
    'магла',
    'чешел',
    'дојка',
    'грозд',
    'мирис',
    'терен',
    'порив',
    'јадеж',
    'јанѕа',
    'фунта',
    'пушка',
    'сурла',
    'бозел',
    'масон',
    'золва',
    'аршин',
    'тесто',
    'среќа',
    'денар',
    'штама',
    'венец',
    'бурно',
    'барем',
    'чалам',
    'фајде',
    'бујно',
    'курир',
    'лузна',
    'клима',
    'поток',
    'скуша',
    'залак',
    'екран',
    'номад',
    'борач',
    'бунда',
    'замок',
    'леѓен',
    'езеро',
    'мотел',
    'злато',
    'салон',
    'дивеч',
    'чекан',
    'ќелав',
    'масло',
    'чекор',
    'црнец',
    'форум',
    'модел',
    'џумка',
    'афера',
    'анекс',
    'јунак',
    'посед',
    'левак',
    'јарец',
    'сојуз',
    'кикот',
    'штета',
    'циник',
    'пајак',
    'бедем',
    'фирма',
    'лежај',
    'хотел',
    'душек',
    'нафта',
    'клапа',
    'бакла',
    'ќутук',
    'убиец',
    'белег',
    'бајат',
    'метар',
    'калап',
    'орден',
    'стена',
    'ерген',
    'бетон',
    'харем',
    'оброк',
    'сауна',
    'борба',
    'голем',
    'багер',
    'завој',
    'врска',
    'бенка',
    'јасен',
    'пауза',
    'олтар',
    'сапун',
    'матка',
    'сонда',
    'бемка',
    'пехар',
    'близу',
    'ролат',
    'бигор',
    'трнка',
    'бивол',
    'слуга',
    'аскер',
    'суета',
    'еснаф',
    'конус',
    'ортак',
    'сплет',
    'коцка',
    'факел',
    'шатка',
    'човек',
    'брише',
    'ламба',
    'кавга',
    'копар',
    'нешто',
    'санка',
    'јазол',
    'лагер',
    'змија',
    'порој',
    'мерка',
    'брчка',
    'дочек',
    'ајдук',
    'опера',
    'танец',
    'будна',
    'амбис',
    'гужва',
    'мопед',
    'капар',
    'палка',
    'многу',
    'доход',
    'назад',
    'песна',
    'слана',
    'визба',
    'свила',
    'буква',
    'уцена',
    'мираз',
    'мечка',
    'трева',
    'фраза',
    'закон',
    'јасли',
    'ќебап',
    'стрип',
    'возач',
    'шнола',
    'овчар',
    'товар',
    'лозар',
    'ѓезве',
    'канџа',
    'џокер',
    'искра',
    'капка',
    'јајце',
    'цулуф',
    'жетва',
    'храна',
    'логор',
    'векна',
    'ангел',
    'бодеж',
    'залог',
    'ќитап',
    'дотур',
    'црква',
    'циста',
    'падеж',
    'чорап',
    'дипла',
    'перце',
    'метла',
    'видик',
    'домат',
    'сокол',
    'гумно',
    'смена',
    'залив',
    'напад',
    'херој',
    'рчење',
    'калфа',
    'ребус',
    'пенал',
    'чавка',
    'јазик',
    'флота',
    'прчка',
    'флора',
    'поход',
    'цртеж',
    'ќумбе',
    'време',
    'поука',
    'ламја',
    'црево',
    'хумор',
    'дофат',
    'фјорд',
    'челик',
    'атлет',
    'пекар',
    'ќенев',
    'ќотек',
    'чешма',
    'ѓакон',
    'баење',
    'фикус',
    'сачма',
    'намаз',
    'тајфа',
    'оцена',
    'украс',
    'фетиш',
    'ништо',
    'ручек',
    'тегла',
    'пупка',
    'прасе',
    'сидро',
    'фарса',
    'апоен',
    'сатен',
    'бучна',
    'кифла',
    'фауна',
    'фитиљ',
    'квота',
    'круна',
    'полет',
    'скроб',
    'вепар',
    'чемер',
    'опсег',
    'какао',
    'насад',
    'чамец',
    'милја',
    'метеж',
    'друга',
    'плоча',
    'шолја',
    'аташе',
    'рекет',
    'месар',
    'бакне',
    'такси',
    'аларм',
    'собор',
    'џелат',
    'кабел',
    'барон',
    'пумпа',
    'радио',
    'бавен',
    'кочан',
    'завет',
    'замор',
    'капак',
    'гајле',
    'сајла',
    'рулет',
    'насип',
    'ракав',
    'битие',
    'осуда',
    'шпион',
    'резил',
    'залез',
    'свиња',
    'шапка',
    'мебел',
    'назив',
    'износ',
    'болка',
    'тетка',
    'строј',
    'ведар',
    'чочек',
    'ѕидар',
    'стока',
    'врвца',
    'маќеа',
    'сифон',
    'повод',
    'облак',
    'будни',
    'погон',
    'сирак',
    'завод',
    'будно',
    'атлас',
    'четка',
    'расад',
    'текст',
    'шепот',
    'дајре',
    'гајба',
    'обука',
    'итрец',
    'ранец',
    'топка',
    'обѕир',
    'бучно',
    'сонце',
    'гулаш',
    'аванс',
    'репка',
    'прием',
    'образ',
    'лејка',
    'слога',
    'секта',
    'кловн',
    'ролна',
    'пилаф',
    'аудио',
    'корен',
    'бокал',
    'табак',
    'рудар',
    'басна',
    'напор',
    'гозба',
    'џвака',
    'вајар',
    'епоха',
    'фарба',
    'еским',
    'тиран',
    'бишка',
    'лудак',
    'бурна',
    'пиење',
    'гејша',
    'мајка',
    'аргат',
    'режим',
    'менза',
    'чувар',
    'свита',
    'сомот',
    'излог',
    'џезве',
    'диета',
    'згода',
    'солза',
    'грижа',
    'лезет',
    'сцена',
    'цигла',
    'замав',
    'абење',
    'јавор',
    'молив',
    'денес',
    'везир',
    'арена',
    'јадро',
    'закоп',
    'огрев',
    'свеќа',
    'глина',
    'дукат',
    'начин',
    'сваќа',
    'тумор',
    'свест',
    'маџун',
    'ризик',
    'буден',
    'авион',
    'харфа',
    'водач',
    'аорта',
    'врста',
    'етика',
    'осило',
    'букет',
    'доцна',
    'чирак',
    'допис',
    'совет',
    'бурма',
    'одлив',
    'збрка',
    'тужба',
    'порта',
    'долар',
    'сусам',
    'гарда',
    'осврт',
    'првак',
    'вечер',
    'конец',
    'бурен',
    'мрава',
    'бутан',
    'бутка',
    'аждер',
    'банка',
    'пошта',
    'јадец',
    'кауза',
    'аждеј',
    'јавач',
    'устав',
    'ќумез',
    'невен',
    'одред',
    'влада',
    'амеба',
    'форма',
    'паста',
]
