export const VALID_GUESSES = [
    'абава',
    'абана',
    'абата',
    'абдал',
    'абела',
    'абеле',
    'абело',
    'абена',
    'абени',
    'абено',
    'абења',
    'абење',
    'абера',
    'абере',
    'абери',
    'аберу',
    'абете',
    'абеше',
    'абиве',
    'абиме',
    'абине',
    'абите',
    'абраш',
    'авава',
    'авана',
    'аване',
    'авани',
    'аванс',
    'авану',
    'авата',
    'аверс',
    'авион',
    'авлии',
    'авмак',
    'автор',
    'авџии',
    'агава',
    'агана',
    'агата',
    'агенс',
    'агент',
    'агиве',
    'агине',
    'агите',
    'агица',
    'агице',
    'агици',
    'агицо',
    'агнец',
    'агола',
    'аголе',
    'аголу',
    'аграр',
    'агува',
    'адава',
    'адама',
    'адаме',
    'адами',
    'адаму',
    'адана',
    'адата',
    'адаџо',
    'адаша',
    'адаши',
    'адашу',
    'адема',
    'адеме',
    'адеми',
    'адему',
    'адета',
    'адете',
    'адети',
    'адету',
    'адиве',
    'адине',
    'адите',
    'адови',
    'адута',
    'адуте',
    'адути',
    'адуту',
    'аждаи',
    'аждеи',
    'аждеј',
    'аждер',
    'аждра',
    'аждри',
    'аждро',
    'азава',
    'азана',
    'азата',
    'азбии',
    'азиве',
    'азила',
    'азиле',
    'азили',
    'азилу',
    'азине',
    'азите',
    'азлак',
    'азнар',
    'азота',
    'азоте',
    'азоти',
    'азоту',
    'азура',
    'азуре',
    'азури',
    'азуру',
    'аиров',
    'аирон',
    'аирот',
    'ајана',
    'ајане',
    'ајани',
    'ајану',
    'ајара',
    'ајаре',
    'ајари',
    'ајару',
    'ајата',
    'ајате',
    'ајати',
    'ајату',
    'ајван',
    'ајвар',
    'ајгар',
    'ајдар',
    'ајдук',
    'ајдут',
    'ајлак',
    'ајљаз',
    'ајрат',
    'акала',
    'акале',
    'акали',
    'акалу',
    'акања',
    'акање',
    'акмак',
    'акови',
    'акорд',
    'акран',
    'акреп',
    'актер',
    'актив',
    'актов',
    'актон',
    'актот',
    'акции',
    'акчии',
    'акшам',
    'алава',
    'алаја',
    'алају',
    'алака',
    'алаку',
    'алала',
    'алале',
    'алали',
    'алалу',
    'алана',
    'алања',
    'алање',
    'аларм',
    'алата',
    'алате',
    'алати',
    'алату',
    'алаци',
    'алаче',
    'алаџа',
    'алаџи',
    'алаџо',
    'албум',
    'алево',
    'алеја',
    'алејо',
    'алено',
    'алест',
    'алето',
    'алиби',
    'аливе',
    'алине',
    'алиња',
    'алите',
    'алмаз',
    'алови',
    'алтан',
    'алтар',
    'алтов',
    'алтон',
    'алтот',
    'алчак',
    'алчен',
    'алчна',
    'алчни',
    'алчно',
    'амала',
    'амале',
    'амали',
    'амалу',
    'амама',
    'амаме',
    'амами',
    'амаму',
    'амбар',
    'амбер',
    'амбис',
    'амвон',
    'амеба',
    'амеби',
    'амебо',
    'ампер',
    'амура',
    'амуре',
    'амури',
    'амуру',
    'амута',
    'амуте',
    'амути',
    'амуту',
    'анали',
    'анама',
    'анами',
    'анамо',
    'ангел',
    'анѓел',
    'ангро',
    'анекс',
    'анови',
    'анода',
    'антре',
    'анфас',
    'анџак',
    'анџар',
    'анџии',
    'аорта',
    'аорти',
    'аорто',
    'апаша',
    'апаши',
    'апашу',
    'апежа',
    'апежи',
    'апежу',
    'апела',
    'апеле',
    'апели',
    'апелу',
    'апења',
    'апење',
    'апови',
    'апово',
    'апоен',
    'апоно',
    'апото',
    'април',
    'апсам',
    'апсат',
    'апсеа',
    'апсев',
    'апсел',
    'апсен',
    'апсиш',
    'апсов',
    'апсон',
    'апсот',
    'араба',
    'араби',
    'арабо',
    'арава',
    'арака',
    'араку',
    'арама',
    'араме',
    'арами',
    'араму',
    'арана',
    'арапа',
    'арапе',
    'арапи',
    'арапу',
    'арара',
    'араре',
    'арари',
    'арару',
    'арата',
    'араци',
    'арача',
    'араче',
    'арачи',
    'арачу',
    'аргат',
    'аргач',
    'аргон',
    'ардал',
    'ареал',
    'арема',
    'ареме',
    'ареми',
    'арему',
    'арена',
    'арени',
    'арено',
    'ареса',
    'ариве',
    'арија',
    'аријо',
    'арине',
    'арите',
    'аркач',
    'арлук',
    'арман',
    'армас',
    'армии',
    'арови',
    'арово',
    'арома',
    'ароми',
    'аромо',
    'ароно',
    'арост',
    'арото',
    'арсаз',
    'арсен',
    'артак',
    'архив',
    'арчам',
    'арчат',
    'арчеа',
    'арчев',
    'арчел',
    'арчен',
    'арчиш',
    'арчов',
    'арчон',
    'арчот',
    'арџов',
    'арџон',
    'арџот',
    'аршин',
    'асево',
    'асено',
    'асето',
    'асија',
    'асијо',
    'асиња',
    'аскер',
    'аскет',
    'асови',
    'асола',
    'асоле',
    'асоли',
    'асолу',
    'аспра',
    'аспри',
    'аспро',
    'астал',
    'астар',
    'астма',
    'астми',
    'астмо',
    'асура',
    'асури',
    'асуро',
    'атака',
    'атаки',
    'атако',
    'атала',
    'атале',
    'аталу',
    'атара',
    'атаре',
    'атари',
    'атару',
    'атаци',
    'аташе',
    'атера',
    'атере',
    'атери',
    'атеру',
    'атест',
    'атица',
    'атице',
    'атици',
    'атицо',
    'атлаз',
    'атлас',
    'атлет',
    'атлии',
    'атови',
    'атома',
    'атоме',
    'атоми',
    'атому',
    'аудио',
    'аутов',
    'аутон',
    'аутот',
    'афект',
    'афера',
    'афери',
    'аферо',
    'афион',
    'афиша',
    'афрот',
    'афтов',
    'афтон',
    'афтот',
    'афуза',
    'афузи',
    'афузу',
    'ахаха',
    'ахчии',
    'аџија',
    'аџијо',
    'аџика',
    'аџике',
    'аџики',
    'аџико',
    'аџица',
    'аџице',
    'аџици',
    'аџицо',
    'ашика',
    'ашику',
    'ашици',
    'ашиче',
    'ашлак',
    'ашчии',
    'бааги',
    'бабар',
    'бабин',
    'бабне',
    'бабот',
    'бабри',
    'бабун',
    'бавам',
    'бават',
    'бавеа',
    'бавев',
    'бавеж',
    'бавел',
    'бавен',
    'бавиш',
    'бавна',
    'бавни',
    'бавно',
    'бавта',
    'бавча',
    'бавче',
    'бавчи',
    'бавчо',
    'багаж',
    'багер',
    'багра',
    'багри',
    'багро',
    'бадем',
    'баеви',
    'баеги',
    'баења',
    'баење',
    'базар',
    'базди',
    'базен',
    'базер',
    'базје',
    'базна',
    'базни',
    'базно',
    'базов',
    'базон',
    'базот',
    'баира',
    'баире',
    'баири',
    'баиру',
    'бајат',
    'бајач',
    'бајка',
    'бајки',
    'бајко',
    'бајов',
    'бајон',
    'бајот',
    'бајта',
    'бајти',
    'бајту',
    'бајче',
    'бакал',
    'бакам',
    'бакар',
    'бакла',
    'бакли',
    'бакло',
    'бакна',
    'бакне',
    'бакни',
    'балет',
    'балка',
    'балки',
    'балко',
    'балов',
    'балон',
    'балот',
    'балта',
    'балти',
    'балто',
    'бамја',
    'бамји',
    'бамјо',
    'бамка',
    'бамња',
    'бамњи',
    'бамњо',
    'банда',
    'банди',
    'бандо',
    'бании',
    'баник',
    'банка',
    'банки',
    'банко',
    'банов',
    'банон',
    'банот',
    'бањаа',
    'бањав',
    'бањај',
    'бањал',
    'бањам',
    'бањан',
    'бањар',
    'бањач',
    'бањаш',
    'бапка',
    'бапки',
    'бапко',
    'бапне',
    'бараа',
    'барав',
    'бараж',
    'барај',
    'барал',
    'барам',
    'баран',
    'барач',
    'бараш',
    'барде',
    'барел',
    'барем',
    'барка',
    'баров',
    'барок',
    'барон',
    'барот',
    'барут',
    'басен',
    'басма',
    'басми',
    'басмо',
    'басна',
    'басни',
    'басно',
    'басов',
    'басон',
    'басот',
    'басра',
    'баста',
    'басти',
    'басто',
    'батак',
    'батал',
    'батев',
    'батка',
    'батко',
    'бауча',
    'бауче',
    'баучи',
    'баучу',
    'бацил',
    'бацко',
    'бацла',
    'бацли',
    'бацло',
    'бачии',
    'бачов',
    'бачон',
    'бачот',
    'баџак',
    'баџин',
    'башка',
    'бевме',
    'бевте',
    'бегаа',
    'бегав',
    'бегај',
    'бегал',
    'бегам',
    'бегач',
    'бегаш',
    'бегов',
    'бегол',
    'бегон',
    'бегот',
    'бегум',
    'бедел',
    'бедем',
    'беден',
    'бедна',
    'бедни',
    'бедно',
    'бедов',
    'бедон',
    'бедот',
    'бедра',
    'бедро',
    'бежов',
    'безел',
    'безир',
    'бејов',
    'бејон',
    'бејот',
    'беќар',
    'бекне',
    'беков',
    'бекон',
    'бекот',
    'бекче',
    'белаи',
    'белај',
    'белам',
    'белат',
    'белач',
    'белеа',
    'белев',
    'белег',
    'белее',
    'белел',
    'белец',
    'белич',
    'белиш',
    'белка',
    'белки',
    'белко',
    'белок',
    'белци',
    'белче',
    'белчо',
    'бељур',
    'бемка',
    'бемки',
    'бемко',
    'бенка',
    'бенки',
    'бенко',
    'бента',
    'бенте',
    'бенту',
    'берам',
    'берат',
    'берач',
    'берба',
    'берби',
    'бербо',
    'береа',
    'берев',
    'берел',
    'берен',
    'береш',
    'берза',
    'берзи',
    'берзо',
    'бесач',
    'бесен',
    'бесна',
    'бесни',
    'бесно',
    'бесов',
    'бесон',
    'бесот',
    'бетер',
    'бетон',
    'бечви',
    'беџов',
    'беџон',
    'беџот',
    'бибер',
    'бивак',
    'бивол',
    'бивши',
    'бигор',
    'бидам',
    'бидат',
    'бидеа',
    'бидев',
    'бидел',
    'бидеш',
    'биеја',
    'биела',
    'биеле',
    'биело',
    'биеме',
    'биена',
    'биени',
    'биено',
    'биења',
    'биење',
    'биете',
    'биеше',
    'бизон',
    'бииве',
    'биине',
    'биите',
    'бијам',
    'бијат',
    'бијач',
    'бијте',
    'биков',
    'бикон',
    'бикот',
    'бикче',
    'билен',
    'билет',
    'билја',
    'билје',
    'билка',
    'билки',
    'билко',
    'билна',
    'билни',
    'билно',
    'бинек',
    'биниш',
    'бинта',
    'бинте',
    'бинту',
    'бирач',
    'бироа',
    'биров',
    'бирон',
    'бирот',
    'бисер',
    'биска',
    'биски',
    'биско',
    'биста',
    'бисти',
    'бисто',
    'битва',
    'битви',
    'битво',
    'битен',
    'битие',
    'битка',
    'битки',
    'битко',
    'битна',
    'битни',
    'битно',
    'битов',
    'битон',
    'битот',
    'бифеа',
    'бичен',
    'бичим',
    'бичов',
    'бичон',
    'бичот',
    'бишка',
    'бишки',
    'бишко',
    'блага',
    'благи',
    'благо',
    'блада',
    'блажа',
    'блажи',
    'блажу',
    'блазе',
    'блака',
    'блаки',
    'блако',
    'бланш',
    'блата',
    'блато',
    'бледа',
    'бледи',
    'бледо',
    'блека',
    'блеки',
    'блеко',
    'блена',
    'блене',
    'блену',
    'блефа',
    'блефе',
    'блефу',
    'ближи',
    'близу',
    'блика',
    'блику',
    'бличе',
    'блока',
    'блоку',
    'блоче',
    'блуда',
    'блуде',
    'блуди',
    'блудо',
    'блуду',
    'блуев',
    'блуел',
    'блуеш',
    'блуза',
    'блузи',
    'блузо',
    'бљуда',
    'бљуде',
    'бљудо',
    'бљуду',
    'боава',
    'боана',
    'боата',
    'бобец',
    'бобов',
    'бобон',
    'бобот',
    'бовча',
    'бовчи',
    'бовчо',
    'богат',
    'богов',
    'богон',
    'богот',
    'бодам',
    'бодар',
    'бодат',
    'бодеа',
    'бодев',
    'бодеж',
    'бодел',
    'боден',
    'бодеш',
    'бодне',
    'бодов',
    'бодон',
    'бодот',
    'бодри',
    'боеви',
    'боеја',
    'боела',
    'боеле',
    'боело',
    'боења',
    'боење',
    'боецу',
    'боеше',
    'божев',
    'божем',
    'божиќ',
    'божји',
    'божур',
    'бозел',
    'бозов',
    'бозон',
    'бозот',
    'боиве',
    'боиме',
    'боине',
    'боите',
    'боица',
    'бојам',
    'бојар',
    'бојат',
    'бојна',
    'бојни',
    'бојно',
    'бојов',
    'бојон',
    'бојот',
    'бојте',
    'бојци',
    'бокал',
    'боков',
    'бокон',
    'бокот',
    'бокса',
    'боксу',
    'болам',
    'болат',
    'болва',
    'болви',
    'болво',
    'болеа',
    'болев',
    'болел',
    'болен',
    'болид',
    'болиш',
    'болка',
    'болки',
    'болко',
    'болна',
    'болни',
    'болно',
    'болов',
    'болон',
    'болот',
    'болук',
    'болче',
    'бољме',
    'бомба',
    'бомбе',
    'бомби',
    'бомбо',
    'бонов',
    'бонон',
    'бонот',
    'бонче',
    'бопци',
    'борам',
    'борат',
    'борач',
    'борба',
    'борби',
    'борбо',
    'бордо',
    'бореа',
    'борев',
    'борел',
    'борец',
    'бории',
    'бориш',
    'боров',
    'борон',
    'борот',
    'борун',
    'борци',
    'борча',
    'борче',
    'борчи',
    'борчу',
    'босак',
    'боска',
    'боски',
    'боско',
    'босов',
    'босон',
    'босот',
    'ботее',
    'ботче',
    'бофча',
    'боцка',
    'боцки',
    'боцко',
    'боцне',
    'бочва',
    'бочве',
    'бочви',
    'бочво',
    'бочен',
    'бочка',
    'бочки',
    'бочко',
    'бочна',
    'бочни',
    'бочно',
    'брава',
    'браве',
    'брави',
    'браво',
    'браву',
    'брада',
    'бради',
    'брадо',
    'браев',
    'брака',
    'браќа',
    'браќо',
    'браку',
    'брала',
    'брале',
    'брало',
    'брана',
    'бране',
    'брани',
    'брано',
    'брану',
    'браон',
    'брата',
    'брате',
    'брату',
    'брача',
    'браче',
    'брачу',
    'брбла',
    'брбли',
    'брбло',
    'брбне',
    'брбор',
    'брдар',
    'брега',
    'брегу',
    'бреже',
    'бреза',
    'брези',
    'брезо',
    'бреме',
    'брест',
    'бреца',
    'брзаа',
    'брзав',
    'брзаи',
    'брзај',
    'брзак',
    'брзал',
    'брзам',
    'брзаш',
    'брзга',
    'брзеи',
    'брзеј',
    'брзне',
    'брига',
    'бриги',
    'бриго',
    'брича',
    'бриче',
    'бричи',
    'бричу',
    'брише',
    'бриши',
    'бркаа',
    'бркав',
    'бркај',
    'бркал',
    'бркам',
    'бркан',
    'бркач',
    'бркаш',
    'бркне',
    'брлив',
    'брлов',
    'брлог',
    'брлон',
    'брлот',
    'брмка',
    'брмне',
    'брмчи',
    'брнѕа',
    'брнѕи',
    'брнѕо',
    'брнка',
    'брнки',
    'брнко',
    'брова',
    'брове',
    'брову',
    'брода',
    'броде',
    'броди',
    'броду',
    'броев',
    'броел',
    'броен',
    'броиш',
    'броја',
    'броју',
    'брома',
    'броме',
    'брому',
    'броша',
    'брошт',
    'брста',
    'брсте',
    'брсти',
    'брсту',
    'бртва',
    'бртви',
    'бртво',
    'брука',
    'бруќе',
    'бруки',
    'бруко',
    'бруса',
    'бруси',
    'брусу',
    'брута',
    'бруте',
    'бруто',
    'бруту',
    'брцка',
    'брцна',
    'брцне',
    'брцни',
    'брчка',
    'брчки',
    'брчко',
    'брчне',
    'бубаќ',
    'бубар',
    'бубин',
    'бувка',
    'бувки',
    'бувко',
    'бувне',
    'бувов',
    'бувон',
    'бувот',
    'бувта',
    'бугла',
    'бугли',
    'бугло',
    'будам',
    'будат',
    'будеа',
    'будев',
    'будел',
    'буден',
    'будиш',
    'будна',
    'будни',
    'будно',
    'буења',
    'буење',
    'буера',
    'буере',
    'буери',
    'буеру',
    'буица',
    'буице',
    'буици',
    'буицо',
    'бујак',
    'бујка',
    'бујки',
    'бујко',
    'бујна',
    'бујни',
    'бујно',
    'букал',
    'букар',
    'буква',
    'букви',
    'букво',
    'букет',
    'букле',
    'букне',
    'буков',
    'букон',
    'букот',
    'булка',
    'булки',
    'булко',
    'булук',
    'бумба',
    'бумби',
    'бумбо',
    'бумка',
    'бумов',
    'бумон',
    'бумот',
    'бунам',
    'бунар',
    'бунат',
    'бунда',
    'бунди',
    'бундо',
    'бунеа',
    'бунев',
    'бунел',
    'буниш',
    'бунта',
    'бунте',
    'бунту',
    'бурек',
    'бурен',
    'бурет',
    'бурии',
    'бурма',
    'бурми',
    'бурмо',
    'бурна',
    'бурни',
    'бурно',
    'бусии',
    'бусов',
    'бусон',
    'бусот',
    'бутан',
    'бутач',
    'бутен',
    'бутик',
    'бутим',
    'бутин',
    'бутка',
    'бутки',
    'бутко',
    'бутне',
    'бутов',
    'бутон',
    'бутот',
    'бутур',
    'бутче',
    'буфка',
    'буцка',
    'буцки',
    'буцко',
    'буцне',
    'бучам',
    'бучат',
    'бучеа',
    'бучев',
    'бучел',
    'бучен',
    'бучиш',
    'бучка',
    'бучки',
    'бучко',
    'бучна',
    'бучни',
    'бучно',
    'бучук',
    'буџак',
    'буџет',
    'бушав',
    'бушам',
    'бушат',
    'бушеа',
    'бушев',
    'бушел',
    'бушен',
    'бушиш',
    'ваган',
    'вагон',
    'вадам',
    'вадар',
    'вадат',
    'вадеа',
    'вадев',
    'вадеж',
    'вадел',
    'ваден',
    'вадиш',
    'важам',
    'важат',
    'важеа',
    'важев',
    'важел',
    'важен',
    'важиш',
    'важна',
    'важни',
    'важно',
    'вазал',
    'вазна',
    'вазни',
    'вазно',
    'ваиве',
    'ваине',
    'ваите',
    'вајар',
    'вакат',
    'вакаф',
    'ваква',
    'вакви',
    'вакво',
    'ваков',
    'вакол',
    'валач',
    'валец',
    'валии',
    'валка',
    'валма',
    'валмо',
    'валог',
    'валса',
    'валсу',
    'валта',
    'валти',
    'валто',
    'валук',
    'валци',
    'валче',
    'валчи',
    'вапса',
    'варај',
    'варак',
    'варам',
    'варат',
    'варва',
    'варда',
    'варди',
    'вареа',
    'варев',
    'варел',
    'варен',
    'вариш',
    'варја',
    'варји',
    'варјо',
    'варна',
    'варов',
    'варон',
    'варот',
    'варта',
    'ватен',
    'ватов',
    'ватон',
    'ватот',
    'вафла',
    'вафли',
    'вафло',
    'вброи',
    'вгази',
    'вдене',
    'вдиша',
    'вдише',
    'вдиши',
    'ведар',
    'ведач',
    'ведне',
    'ведра',
    'ведри',
    'ведро',
    'вееја',
    'веела',
    'вееле',
    'веело',
    'вееме',
    'веења',
    'веење',
    'веете',
    'вееше',
    'вежба',
    'вежби',
    'вежбо',
    'везам',
    'везат',
    'везач',
    'везба',
    'везби',
    'везбо',
    'везеа',
    'везев',
    'везел',
    'везен',
    'везеш',
    'везир',
    'везме',
    'везов',
    'везон',
    'везот',
    'вејач',
    'вејка',
    'вејки',
    'вејко',
    'вејне',
    'вејте',
    'векна',
    'векни',
    'векно',
    'веков',
    'векон',
    'векот',
    'велам',
    'велар',
    'велат',
    'велеа',
    'велев',
    'велел',
    'велик',
    'велиш',
    'велка',
    'велки',
    'велко',
    'велур',
    'венда',
    'венец',
    'венок',
    'венци',
    'венча',
    'венче',
    'вепар',
    'вепир',
    'вепри',
    'верам',
    'верат',
    'верба',
    'верби',
    'вербо',
    'вергл',
    'вереа',
    'верев',
    'верел',
    'верен',
    'вериш',
    'верна',
    'верни',
    'верно',
    'верса',
    'верси',
    'версу',
    'весач',
    'весел',
    'весла',
    'весло',
    'веспа',
    'веста',
    'вести',
    'ветам',
    'ветар',
    'ветат',
    'ветви',
    'ветеа',
    'ветев',
    'ветел',
    'ветен',
    'ветер',
    'ветив',
    'ветил',
    'ветиш',
    'ветка',
    'ветки',
    'ветко',
    'ветов',
    'ветра',
    'ветре',
    'ветру',
    'вечеи',
    'вечен',
    'вечер',
    'вечит',
    'вечна',
    'вечни',
    'вечно',
    'вешал',
    'вешки',
    'вешта',
    'вешти',
    'вешто',
    'вжари',
    'вжеже',
    'вземи',
    'взора',
    'взоре',
    'взори',
    'взору',
    'вивка',
    'вивки',
    'вивко',
    'вивне',
    'вигла',
    'вигли',
    'вигло',
    'вигна',
    'вигни',
    'вигно',
    'видам',
    'видар',
    'видат',
    'видеа',
    'видев',
    'видел',
    'виден',
    'видео',
    'видик',
    'видиш',
    'видна',
    'видни',
    'видно',
    'видња',
    'видњи',
    'видњо',
    'видоа',
    'видов',
    'видон',
    'видот',
    'видра',
    'видри',
    'видро',
    'виеви',
    'виежа',
    'виежи',
    'виежу',
    'виеја',
    'виела',
    'виеле',
    'виело',
    'виеме',
    'виења',
    'виење',
    'виете',
    'виеше',
    'вижди',
    'визба',
    'визби',
    'визбо',
    'визен',
    'визии',
    'визир',
    'визна',
    'визни',
    'визно',
    'визон',
    'вијак',
    'вијам',
    'вијат',
    'вијов',
    'вијон',
    'вијот',
    'вијте',
    'викаа',
    'викав',
    'викај',
    'викал',
    'викам',
    'викан',
    'викач',
    'викаш',
    'викии',
    'викла',
    'викли',
    'викло',
    'викна',
    'викне',
    'викни',
    'виков',
    'викон',
    'викот',
    'викум',
    'вилар',
    'вилин',
    'винар',
    'винен',
    'винов',
    'винта',
    'винте',
    'винту',
    'винца',
    'винце',
    'вињак',
    'виола',
    'виоли',
    'виоло',
    'виора',
    'виоре',
    'виори',
    'виору',
    'вирее',
    'виров',
    'вирон',
    'вирот',
    'вирус',
    'вирче',
    'висам',
    'висат',
    'висеа',
    'висев',
    'висел',
    'висиш',
    'виска',
    'виски',
    'виску',
    'висне',
    'висни',
    'висов',
    'висок',
    'висон',
    'висот',
    'виста',
    'висте',
    'висту',
    'висче',
    'витез',
    'вител',
    'витка',
    'витки',
    'витко',
    'витла',
    'витли',
    'витло',
    'виток',
    'витос',
    'вицка',
    'вицки',
    'вицко',
    'вицов',
    'вицон',
    'вицот',
    'вишен',
    'вишна',
    'вишни',
    'вишно',
    'вишок',
    'вишти',
    'вјаса',
    'вкове',
    'вкопа',
    'вкуќи',
    'вкуса',
    'вкуси',
    'вкусу',
    'влага',
    'влаги',
    'влаго',
    'влада',
    'влади',
    'владо',
    'влажи',
    'влака',
    'влаку',
    'власа',
    'власи',
    'власт',
    'власу',
    'влаче',
    'влачи',
    'влева',
    'влево',
    'влеза',
    'влезе',
    'влези',
    'влезу',
    'влека',
    'влеку',
    'влепи',
    'влета',
    'влече',
    'влечи',
    'влога',
    'влогу',
    'вложе',
    'вложи',
    'влоши',
    'вљуби',
    'вмеша',
    'внела',
    'внеле',
    'внело',
    'внесе',
    'внеси',
    'вноса',
    'вносу',
    'внука',
    'внуки',
    'внуко',
    'внуку',
    'внуци',
    'внуче',
    'внуши',
    'воала',
    'воале',
    'воали',
    'воалу',
    'вовед',
    'вовел',
    'вовре',
    'вовче',
    'водам',
    'водар',
    'водат',
    'водач',
    'водеа',
    'водев',
    'водел',
    'воден',
    'водич',
    'водиш',
    'водје',
    'водна',
    'водни',
    'водно',
    'водов',
    'водон',
    'водот',
    'воеви',
    'воена',
    'воени',
    'воено',
    'возам',
    'возар',
    'возат',
    'возач',
    'возеа',
    'возев',
    'возел',
    'возен',
    'возиш',
    'возли',
    'возна',
    'возни',
    'возно',
    'возов',
    'возон',
    'возот',
    'воина',
    'воине',
    'воини',
    'воину',
    'војна',
    'војни',
    'војно',
    'војов',
    'војон',
    'војот',
    'војца',
    'војци',
    'војцо',
    'вокал',
    'волан',
    'волев',
    'волеи',
    'волеј',
    'волен',
    'волја',
    'волји',
    'волјо',
    'волка',
    'волку',
    'волна',
    'волни',
    'волно',
    'волов',
    'волон',
    'волот',
    'волта',
    'волте',
    'волти',
    'волту',
    'волци',
    'волче',
    'волчи',
    'вонка',
    'воочи',
    'вопер',
    'ворга',
    'ворги',
    'ворго',
    'воска',
    'воски',
    'воско',
    'восок',
    'вотка',
    'вотки',
    'вотко',
    'вотне',
    'вотус',
    'вошка',
    'вошки',
    'вошко',
    'вошла',
    'вошле',
    'вошли',
    'вошло',
    'вошти',
    'впери',
    'впива',
    'впиев',
    'впиел',
    'впиен',
    'впиеш',
    'впија',
    'впика',
    'впила',
    'впиле',
    'впило',
    'впиша',
    'впише',
    'впиши',
    'врава',
    'враве',
    'враву',
    'врага',
    'врагу',
    'вража',
    'враже',
    'врази',
    'враќа',
    'врами',
    'врана',
    'врани',
    'врано',
    'врата',
    'врате',
    'врати',
    'врато',
    'врату',
    'врача',
    'враче',
    'врачи',
    'врачу',
    'врбак',
    'врбов',
    'врвеж',
    'врвен',
    'врвка',
    'врвки',
    'врвко',
    'врвна',
    'врвни',
    'врвно',
    'врвов',
    'врвон',
    'врвот',
    'врвца',
    'врвци',
    'врвцо',
    'врвче',
    'вргав',
    'вргла',
    'вргли',
    'вргло',
    'врева',
    'вреви',
    'врево',
    'вреда',
    'вреде',
    'вреди',
    'вреду',
    'врежа',
    'вреже',
    'врежи',
    'врежу',
    'вреза',
    'врезу',
    'врека',
    'вреќа',
    'вреќи',
    'вреќо',
    'врела',
    'врели',
    'врело',
    'време',
    'врзам',
    'врзан',
    'врзат',
    'врзач',
    'врзеа',
    'врзев',
    'врзел',
    'врзеш',
    'врзма',
    'врзми',
    'врзмо',
    'врзол',
    'врзоп',
    'врзум',
    'вриев',
    'вриеж',
    'вриел',
    'вриеш',
    'врика',
    'врков',
    'вркон',
    'вркот',
    'врлеж',
    'врнам',
    'врнат',
    'врнеа',
    'врнев',
    'врнеж',
    'врнел',
    'врнеш',
    'врпал',
    'врпче',
    'врска',
    'врски',
    'врско',
    'врсне',
    'врста',
    'врсти',
    'врсто',
    'вртам',
    'вртат',
    'вртеа',
    'вртев',
    'вртеж',
    'вртел',
    'вртен',
    'вртиш',
    'вртка',
    'вртки',
    'вртко',
    'вртне',
    'вруќи',
    'вруши',
    'врцка',
    'врцки',
    'врцко',
    'врчва',
    'врчви',
    'врчво',
    'врчка',
    'врчки',
    'врчко',
    'вршам',
    'вршат',
    'вршач',
    'вршеа',
    'вршев',
    'вршел',
    'вршен',
    'вршиш',
    'вршка',
    'вршки',
    'вршко',
    'всади',
    'всели',
    'всоне',
    'втаса',
    'втасу',
    'втера',
    'вткае',
    'втоне',
    'втора',
    'втори',
    'второ',
    'втрча',
    'вудве',
    'вујка',
    'вујко',
    'вујна',
    'вујни',
    'вујно',
    'вујче',
    'вујчо',
    'вулог',
    'вутри',
    'входа',
    'входе',
    'входу',
    'вцица',
    'вцрви',
    'вцрпи',
    'вчади',
    'вчапи',
    'вчепи',
    'вчера',
    'вчуди',
    'вчури',
    'вшиев',
    'вшиел',
    'вшиен',
    'вшиеш',
    'вшија',
    'вшила',
    'вшиле',
    'вшило',
    'габар',
    'габер',
    'габри',
    'гаваз',
    'гавал',
    'гавез',
    'ѓавол',
    'гавра',
    'гаври',
    'гавро',
    'гавче',
    'гаѓаа',
    'гаѓав',
    'гаѓај',
    'гаѓал',
    'гаѓам',
    'гаѓан',
    'гаѓач',
    'гаѓаш',
    'гадам',
    'гадат',
    'гадач',
    'гадеа',
    'гадев',
    'гадел',
    'гаден',
    'гадиш',
    'гадна',
    'гадни',
    'гадно',
    'гадов',
    'гадон',
    'гадот',
    'гажва',
    'гажви',
    'гажво',
    'газам',
    'газар',
    'газат',
    'газач',
    'газда',
    'газди',
    'газдо',
    'газеа',
    'газев',
    'газел',
    'газен',
    'газер',
    'газии',
    'газиш',
    'газла',
    'газли',
    'газло',
    'газне',
    'газов',
    'газон',
    'газот',
    'гајба',
    'гајби',
    'гајбо',
    'гајда',
    'гајди',
    'гајдо',
    'гајле',
    'гаќар',
    'гаќат',
    'гакне',
    'гаков',
    'ѓаков',
    'гакон',
    'ѓакон',
    'гакот',
    'ѓакот',
    'галаб',
    'галам',
    'галат',
    'галба',
    'галби',
    'галбо',
    'галеа',
    'галеб',
    'галев',
    'галеж',
    'галел',
    'гален',
    'галии',
    'галиш',
    'галне',
    'галов',
    'галон',
    'галоп',
    'галот',
    'галош',
    'галун',
    'гамен',
    'гарга',
    'гарги',
    'гарго',
    'гарда',
    'гарди',
    'гардо',
    'гарез',
    'гасам',
    'гасат',
    'гасеа',
    'гасев',
    'гасел',
    'гасен',
    'гасиш',
    'гасна',
    'гасне',
    'гасни',
    'гасно',
    'гасов',
    'гасон',
    'гасот',
    'гатач',
    'гатер',
    'гатка',
    'гатки',
    'гатко',
    'гатне',
    'ѓаура',
    'ѓауре',
    'ѓаури',
    'ѓауру',
    'гафов',
    'гафон',
    'гафот',
    'гачка',
    'гачки',
    'гачко',
    'ѓебре',
    'ѓебри',
    'гегав',
    'ѓезве',
    'гејша',
    'гејши',
    'гејшо',
    'гемии',
    'гемов',
    'ѓемов',
    'гемон',
    'ѓемон',
    'гемот',
    'ѓемот',
    'гении',
    'гениј',
    'генов',
    'генон',
    'генот',
    'ѓеран',
    'ѓериз',
    'гесло',
    'геста',
    'гесте',
    'гесту',
    'гибач',
    'гибел',
    'гибне',
    'гидии',
    'гижва',
    'гижви',
    'гижво',
    'гизда',
    'гизди',
    'гиздо',
    'гинее',
    'гипса',
    'гипсу',
    'гитар',
    'глава',
    'главе',
    'глави',
    'главо',
    'глада',
    'гладе',
    'гладу',
    'гласа',
    'гласе',
    'гласи',
    'гласу',
    'глеѓа',
    'глеѓе',
    'глеѓу',
    'гледа',
    'гледе',
    'гледу',
    'глека',
    'глета',
    'глето',
    'глиба',
    'глибе',
    'глибу',
    'глина',
    'глини',
    'глино',
    'глист',
    'глита',
    'глите',
    'глиту',
    'глоба',
    'глобе',
    'глоби',
    'глобо',
    'глобу',
    'глога',
    'глоѓе',
    'глоги',
    'глого',
    'глогу',
    'глода',
    'гложе',
    'глота',
    'глоте',
    'глоти',
    'глото',
    'глоту',
    'глува',
    'глуви',
    'глуво',
    'глужд',
    'глума',
    'глуми',
    'глуџо',
    'глуше',
    'глуши',
    'гљуга',
    'гмечи',
    'гмуца',
    'гмуци',
    'гмуцо',
    'гнаса',
    'гнаси',
    'гнасо',
    'гнасу',
    'гнева',
    'гневе',
    'гневи',
    'гневу',
    'гнета',
    'гнете',
    'гнету',
    'гнида',
    'гниди',
    'гнидо',
    'гниев',
    'гниеж',
    'гниел',
    'гниен',
    'гниеш',
    'гнила',
    'гнили',
    'гнило',
    'гноеж',
    'гноен',
    'гноја',
    'гноју',
    'гнома',
    'гноми',
    'гномо',
    'гобел',
    'гобли',
    'говор',
    'годам',
    'годат',
    'годеа',
    'годев',
    'годеж',
    'годел',
    'годиш',
    'годов',
    'годон',
    'годот',
    'гоења',
    'гоење',
    'гозба',
    'гозби',
    'гозбо',
    'ѓокат',
    'ѓоков',
    'ѓокон',
    'ѓокот',
    'голаб',
    'голак',
    'голее',
    'голеж',
    'голем',
    'голец',
    'голич',
    'ѓолов',
    'ѓолон',
    'ѓолот',
    'голта',
    'голфа',
    'голфу',
    'голци',
    'голче',
    'голчо',
    'гомна',
    'гомно',
    'гонам',
    'гонат',
    'гонач',
    'гонга',
    'гонгу',
    'гонеа',
    'гонев',
    'гонел',
    'гонет',
    'ѓонии',
    'гониш',
    'ѓонов',
    'ѓонон',
    'ѓонот',
    'горам',
    'горат',
    'горда',
    'горди',
    'гордо',
    'гореа',
    'горев',
    'гореж',
    'гореи',
    'горел',
    'горен',
    'горео',
    'гориш',
    'горко',
    'горна',
    'горни',
    'горно',
    'горок',
    'горун',
    'горчи',
    'госта',
    'госте',
    'гости',
    'госту',
    'готви',
    'готов',
    'граба',
    'граби',
    'грава',
    'граве',
    'граву',
    'граѓа',
    'граѓи',
    'граѓо',
    'града',
    'граде',
    'гради',
    'градо',
    'граду',
    'грака',
    'граку',
    'грама',
    'граме',
    'граму',
    'грана',
    'грани',
    'грано',
    'граор',
    'графа',
    'графе',
    'графи',
    'графо',
    'графу',
    'граче',
    'грачи',
    'граше',
    'грбав',
    'грбач',
    'грбен',
    'грбла',
    'грбли',
    'грбло',
    'грбов',
    'грбон',
    'грбот',
    'гргал',
    'гргач',
    'гргне',
    'гргор',
    'гргот',
    'грдел',
    'грдја',
    'грдји',
    'грдјо',
    'греам',
    'греан',
    'греат',
    'греба',
    'гребе',
    'греби',
    'гребу',
    'грева',
    'греве',
    'греву',
    'греда',
    'греди',
    'гредо',
    'греев',
    'греел',
    'грееш',
    'грејс',
    'греши',
    'гриба',
    'грибе',
    'гриби',
    'грибо',
    'грибу',
    'грива',
    'гриви',
    'гриво',
    'грижа',
    'грижд',
    'грижи',
    'грижо',
    'грижу',
    'гриза',
    'гризе',
    'гризи',
    'гризу',
    'грима',
    'гриме',
    'гриму',
    'грипа',
    'грипе',
    'грипи',
    'грипо',
    'грипу',
    'грлен',
    'грлца',
    'грлце',
    'грмам',
    'грмат',
    'грмеа',
    'грмев',
    'грмеж',
    'грмел',
    'грмиш',
    'грмка',
    'грмки',
    'грмко',
    'грмне',
    'грмот',
    'грнец',
    'грнци',
    'гроба',
    'гробе',
    'гробу',
    'гроза',
    'грозд',
    'грози',
    'грозо',
    'грома',
    'громе',
    'грому',
    'грото',
    'грофа',
    'грофе',
    'грофи',
    'грофу',
    'гроша',
    'гроше',
    'грошу',
    'грпка',
    'грпки',
    'грпко',
    'грпче',
    'грска',
    'грски',
    'грско',
    'грста',
    'грсте',
    'грсти',
    'грсту',
    'груба',
    'груби',
    'грубо',
    'грува',
    'груда',
    'груди',
    'грудо',
    'група',
    'групи',
    'групо',
    'грчав',
    'грчов',
    'грчон',
    'грчот',
    'губам',
    'губар',
    'губат',
    'губач',
    'губеа',
    'губев',
    'губел',
    'губен',
    'губер',
    'губиш',
    'ѓубре',
    'ѓубри',
    'гувее',
    'ѓувез',
    'ѓувеч',
    'гувче',
    'гугла',
    'гугли',
    'гугло',
    'гугне',
    'гугут',
    'гудач',
    'гужба',
    'гужби',
    'гужбо',
    'гужва',
    'гужви',
    'гужво',
    'ѓузел',
    'гукне',
    'гулаб',
    'гулаг',
    'гулаш',
    'ѓулов',
    'ѓулон',
    'ѓулот',
    'гумен',
    'гумна',
    'гумно',
    'ѓумов',
    'ѓумон',
    'ѓумот',
    'ѓумче',
    'гунак',
    'гунче',
    'гуруа',
    'ѓурук',
    'гусак',
    'гусар',
    'гуска',
    'гуски',
    'гуско',
    'гусла',
    'гусли',
    'гусло',
    'гусок',
    'густа',
    'густи',
    'густо',
    'гуцка',
    'гуцки',
    'гуцко',
    'гучка',
    'гучки',
    'гучко',
    'гуџар',
    'гушав',
    'гушам',
    'гушар',
    'гушат',
    'гушеа',
    'гушев',
    'гушел',
    'гушен',
    'гушиш',
    'гушка',
    'гушки',
    'гушко',
    'гушла',
    'гушли',
    'гушло',
    'гушне',
    'дабар',
    'дабје',
    'дабов',
    'дабон',
    'дабот',
    'даваа',
    'давав',
    'давај',
    'давал',
    'давам',
    'даван',
    'дават',
    'давач',
    'даваш',
    'давеа',
    'давев',
    'давеж',
    'давел',
    'давен',
    'давии',
    'давиш',
    'дадам',
    'дадат',
    'дадеа',
    'дадев',
    'дадел',
    'даден',
    'дадеш',
    'дадоа',
    'дадов',
    'дажба',
    'дажби',
    'дажбо',
    'дајак',
    'дајре',
    'дајте',
    'далак',
    'далга',
    'далги',
    'далго',
    'далек',
    'дален',
    'далов',
    'далон',
    'далот',
    'дамар',
    'дамга',
    'дамги',
    'дамго',
    'дамин',
    'дамка',
    'дамки',
    'дамко',
    'дамла',
    'дамли',
    'дамло',
    'дамна',
    'данак',
    'данок',
    'данце',
    'дапче',
    'дарба',
    'дарби',
    'дарбо',
    'даров',
    'дарон',
    'дарот',
    'дарче',
    'даска',
    'даски',
    'даско',
    'датив',
    'датум',
    'дахии',
    'дахов',
    'дахон',
    'дахот',
    'двава',
    'двана',
    'двата',
    'двеве',
    'двене',
    'двери',
    'двете',
    'движи',
    'двоев',
    'двоел',
    'двоен',
    'двоиш',
    'двора',
    'дворе',
    'двору',
    'дебел',
    'дебне',
    'девер',
    'девет',
    'деген',
    'дедин',
    'дедов',
    'деецу',
    'дезен',
    'дејди',
    'дејка',
    'дејки',
    'дејко',
    'дејци',
    'дејче',
    'декан',
    'декар',
    'декор',
    'делам',
    'делат',
    'делач',
    'делба',
    'делби',
    'делбо',
    'делеа',
    'делев',
    'дележ',
    'делел',
    'делен',
    'делив',
    'делии',
    'делиш',
    'делка',
    'делми',
    'делне',
    'делов',
    'делон',
    'делот',
    'делта',
    'делти',
    'делто',
    'делум',
    'делче',
    'демек',
    'демне',
    'демон',
    'денар',
    'денди',
    'денес',
    'денка',
    'денку',
    'денов',
    'денон',
    'денот',
    'дента',
    'денче',
    'депоа',
    'дерам',
    'дерат',
    'дерач',
    'дерби',
    'дерда',
    'дерде',
    'дерду',
    'дереа',
    'дерев',
    'дерел',
    'дерен',
    'дереш',
    'дерле',
    'дерта',
    'дерте',
    'дерту',
    'десен',
    'десет',
    'десна',
    'десни',
    'десно',
    'детал',
    'детаљ',
    'детел',
    'детле',
    'дефов',
    'дефон',
    'дефот',
    'дечар',
    'дечко',
    'дибек',
    'дибов',
    'дибон',
    'дибот',
    'дивак',
    'диван',
    'дивее',
    'дивеч',
    'дивит',
    'дивов',
    'дивон',
    'дивот',
    'дигаа',
    'дигав',
    'дигај',
    'дигал',
    'дигам',
    'диган',
    'дигач',
    'дигаш',
    'дигне',
    'диета',
    'диети',
    'дието',
    'дизга',
    'дизгу',
    'дизел',
    'дизже',
    'дикат',
    'дикел',
    'дикли',
    'дилер',
    'дилми',
    'димен',
    'димии',
    'димов',
    'димон',
    'димот',
    'динар',
    'динге',
    'динго',
    'динка',
    'динки',
    'динко',
    'динов',
    'динон',
    'динот',
    'диода',
    'дипла',
    'дипли',
    'дипло',
    'дирек',
    'диска',
    'диско',
    'диску',
    'дисче',
    'дихов',
    'дихон',
    'дихот',
    'дишам',
    'дишат',
    'дишеа',
    'дишев',
    'дишел',
    'дишен',
    'дишеш',
    'дишне',
    'длаби',
    'длака',
    'длаки',
    'длако',
    'длана',
    'длане',
    'длану',
    'длета',
    'длето',
    'днава',
    'днана',
    'дната',
    'дниве',
    'днине',
    'дните',
    'дново',
    'дноно',
    'дното',
    'доаби',
    'доаѓа',
    'доака',
    'добае',
    'добар',
    'добив',
    'добие',
    'добиј',
    'добил',
    'добов',
    'добон',
    'добот',
    'добра',
    'добре',
    'добри',
    'добро',
    'довде',
    'довее',
    'довек',
    'довел',
    'довие',
    'довик',
    'довод',
    'довоз',
    'доган',
    'догма',
    'догми',
    'догмо',
    'додал',
    'додее',
    'додои',
    'доеја',
    'доела',
    'доеле',
    'доело',
    'доена',
    'доени',
    'доено',
    'доења',
    'доење',
    'доеше',
    'дожда',
    'дожде',
    'дожди',
    'дожду',
    'доима',
    'доиме',
    'доита',
    'доите',
    'дојал',
    'дојам',
    'дојат',
    'дојде',
    'дојди',
    'дојка',
    'дојки',
    'дојко',
    'дојте',
    'доказ',
    'докај',
    'докер',
    'докуп',
    'долае',
    'долак',
    'долап',
    'долар',
    'долга',
    'долги',
    'долго',
    'долгу',
    'долеа',
    'долее',
    'долеј',
    'долен',
    'долет',
    'долже',
    'должи',
    'долии',
    'долма',
    'долми',
    'долмо',
    'долна',
    'долни',
    'долно',
    'долов',
    'долон',
    'долот',
    'долче',
    'дољум',
    'домал',
    'домар',
    'домат',
    'домен',
    'домет',
    'домие',
    'домов',
    'домон',
    'домот',
    'донде',
    'донел',
    'донум',
    'дооди',
    'доора',
    'допее',
    'допив',
    'допие',
    'допиј',
    'допил',
    'допир',
    'допис',
    'допре',
    'дорат',
    'дории',
    'дорче',
    'дорчо',
    'досег',
    'досее',
    'досие',
    'доста',
    'досте',
    'досту',
    'дотек',
    'дотка',
    'доток',
    'дотур',
    'доучи',
    'дофат',
    'дофен',
    'доход',
    'доцен',
    'доцна',
    'доцни',
    'доцно',
    'дочек',
    'дочуе',
    'дошие',
    'дошла',
    'дошле',
    'дошло',
    'дошол',
    'драга',
    'драги',
    'драго',
    'драгу',
    'дража',
    'драже',
    'дражи',
    'дражу',
    'драка',
    'драки',
    'драко',
    'драма',
    'драме',
    'драми',
    'драмо',
    'драму',
    'драпа',
    'дрвар',
    'дрвен',
    'дрвја',
    'дрвје',
    'дрвна',
    'дрвни',
    'дрвно',
    'дрвца',
    'дрвце',
    'дреба',
    'дребе',
    'дребу',
    'древа',
    'древо',
    'дрема',
    'дреме',
    'дреми',
    'дрему',
    'дрена',
    'дрене',
    'дрену',
    'дрење',
    'дречи',
    'држам',
    'држат',
    'држач',
    'држеа',
    'држев',
    'држел',
    'држен',
    'држиш',
    'дрзок',
    'дрипа',
    'дрипи',
    'дрипо',
    'дркол',
    'дркул',
    'дрмка',
    'дрмки',
    'дрмко',
    'дрмон',
    'дрнда',
    'дрнка',
    'дроба',
    'дробе',
    'дроби',
    'дробу',
    'дрога',
    'дроги',
    'дрого',
    'дрозд',
    'дрочи',
    'дрпав',
    'дрпне',
    'дрска',
    'дрски',
    'дрско',
    'дрсли',
    'дрсло',
    'дрсне',
    'дртав',
    'дртее',
    'дртла',
    'дртли',
    'дртло',
    'друга',
    'други',
    'друго',
    'друже',
    'дружи',
    'друма',
    'друме',
    'друму',
    'друса',
    'дрчав',
    'дрчен',
    'дршка',
    'дубла',
    'дубле',
    'дубли',
    'дубло',
    'дуваа',
    'дував',
    'дувај',
    'дувак',
    'дувал',
    'дувам',
    'дуван',
    'дувар',
    'дувач',
    'дуваш',
    'дувек',
    'дувка',
    'дувла',
    'дувло',
    'дувне',
    'дувни',
    'дудов',
    'дудон',
    'дудот',
    'дудук',
    'дуела',
    'дуеле',
    'дуели',
    'дуелу',
    'дуета',
    'дуете',
    'дуети',
    'дуету',
    'дузен',
    'дуќан',
    'дукат',
    'дулак',
    'дулец',
    'дулци',
    'дулче',
    'думан',
    'думка',
    'дунда',
    'дунди',
    'дундо',
    'дунум',
    'дуово',
    'дуоно',
    'дуото',
    'дупам',
    'дупат',
    'дупач',
    'дупеа',
    'дупев',
    'дупел',
    'дупен',
    'дупиш',
    'дупка',
    'дупки',
    'дупко',
    'дупли',
    'дупна',
    'дупне',
    'дупни',
    'дупче',
    'дупчи',
    'дурии',
    'дурла',
    'дурли',
    'дурло',
    'духов',
    'духон',
    'духот',
    'духче',
    'душек',
    'душен',
    'душка',
    'евала',
    'евзон',
    'евлад',
    'евлак',
    'евлов',
    'евнух',
    'евреи',
    'евтин',
    'егава',
    'егана',
    'егата',
    'егево',
    'егено',
    'егето',
    'егзил',
    'егиди',
    'егиња',
    'егово',
    'егоно',
    'егото',
    'едвај',
    'едека',
    'едеку',
    'едеци',
    'едече',
    'едикт',
    'еднаш',
    'едрам',
    'едрат',
    'едреа',
    'едрев',
    'едрел',
    'едриш',
    'ежево',
    'ежела',
    'ежеле',
    'ежело',
    'ежена',
    'ежени',
    'ежено',
    'ежења',
    'ежење',
    'ежете',
    'ежето',
    'ежеше',
    'ежиме',
    'ежиња',
    'ежите',
    'ежови',
    'езера',
    'езеро',
    'езуит',
    'екима',
    'екиме',
    'екими',
    'екиму',
    'екипа',
    'екипи',
    'екипо',
    'еклер',
    'екота',
    'екоте',
    'екоти',
    'екоту',
    'екран',
    'ексик',
    'елана',
    'елане',
    'елани',
    'елану',
    'елате',
    'елека',
    'елеку',
    'елена',
    'елене',
    'елени',
    'елену',
    'елеци',
    'елече',
    'елима',
    'елиме',
    'елими',
    'елиму',
    'елиса',
    'елиси',
    'елисо',
    'елита',
    'елити',
    'елито',
    'елмаз',
    'елчии',
    'елџии',
    'емајл',
    'емира',
    'емире',
    'емири',
    'емиру',
    'емиша',
    'емиши',
    'емишу',
    'емфие',
    'ендек',
    'ендем',
    'ензим',
    'еолит',
    'епарх',
    'епика',
    'епики',
    'епико',
    'епови',
    'епоса',
    'епосе',
    'епоси',
    'епосу',
    'епоха',
    'епохи',
    'епохо',
    'ептен',
    'ерава',
    'ерана',
    'ерата',
    'ербап',
    'ерген',
    'ереса',
    'ересе',
    'ереси',
    'ересу',
    'ериве',
    'ерине',
    'ерите',
    'ермик',
    'ерови',
    'есапа',
    'есапе',
    'есапи',
    'есапу',
    'есеја',
    'есеју',
    'есени',
    'есира',
    'есире',
    'есири',
    'есиру',
    'еским',
    'еснаф',
    'еспап',
    'естет',
    'етажа',
    'етажи',
    'етажу',
    'етапа',
    'етапи',
    'етапо',
    'етера',
    'етере',
    'етери',
    'етеру',
    'етида',
    'етика',
    'етики',
    'етико',
    'етник',
    'етнос',
    'ефект',
    'ехава',
    'ехана',
    'ехата',
    'ехово',
    'ехоно',
    'ехото',
    'ечела',
    'ечеле',
    'ечело',
    'ечења',
    'ечење',
    'ечете',
    'ечеше',
    'ечиме',
    'ечите',
    'ешека',
    'ешеку',
    'ешеци',
    'ешече',
    'ешкии',
    'жабар',
    'жабец',
    'жабји',
    'жабок',
    'жабор',
    'жабра',
    'жабри',
    'жабро',
    'жавка',
    'жагор',
    'жаден',
    'жадов',
    'жадон',
    'жадот',
    'жадта',
    'жакет',
    'жалам',
    'жалат',
    'жалба',
    'жалби',
    'жалбо',
    'жалеа',
    'жалев',
    'жалел',
    'жален',
    'жалит',
    'жалиш',
    'жална',
    'жални',
    'жално',
    'жалов',
    'жалон',
    'жалот',
    'жалта',
    'жанра',
    'жанре',
    'жанру',
    'жапка',
    'жапки',
    'жапко',
    'жапци',
    'жапче',
    'жарен',
    'жарка',
    'жаров',
    'жарон',
    'жарот',
    'жарта',
    'жарче',
    'жбара',
    'жбуна',
    'жбуне',
    'жбуну',
    'жбура',
    'жвака',
    'жгана',
    'жгане',
    'жгану',
    'жгура',
    'жгури',
    'жгуро',
    'ждреб',
    'ждриг',
    'жегла',
    'жегли',
    'жегло',
    'жегне',
    'жегов',
    'жегол',
    'жегон',
    'жегот',
    'жегра',
    'жегри',
    'жегро',
    'жедва',
    'жеден',
    'жедна',
    'жедни',
    'жедно',
    'жедта',
    'жежне',
    'жежок',
    'жезли',
    'жезол',
    'желад',
    'желба',
    'желби',
    'желбо',
    'желеа',
    'желен',
    'желка',
    'желки',
    'желко',
    'желна',
    'желни',
    'желно',
    'желуд',
    'желче',
    'женам',
    'женат',
    'женеа',
    'женев',
    'женел',
    'женет',
    'женин',
    'жениш',
    'женка',
    'женки',
    'женко',
    'женче',
    'жерав',
    'жерсе',
    'жетва',
    'жетви',
    'жетво',
    'жетон',
    'жешка',
    'жешки',
    'жешко',
    'живее',
    'живеј',
    'живец',
    'живин',
    'живка',
    'живко',
    'живне',
    'живот',
    'живци',
    'живче',
    'жигов',
    'жигон',
    'жигот',
    'жижне',
    'жилав',
    'жилет',
    'жилка',
    'жилки',
    'жилко',
    'жирит',
    'жиров',
    'жирон',
    'жирот',
    'житар',
    'жител',
    'житен',
    'житие',
    'житја',
    'житје',
    'житна',
    'житни',
    'житно',
    'житца',
    'житце',
    'жичен',
    'жичка',
    'жички',
    'жичко',
    'жишка',
    'жишне',
    'жлеба',
    'жлебе',
    'жлебу',
    'жмера',
    'жмере',
    'жмеру',
    'жмрка',
    'жнеам',
    'жнеан',
    'жнеар',
    'жнеат',
    'жнеев',
    'жнеел',
    'жнееш',
    'жожор',
    'жолна',
    'жолни',
    'жолно',
    'жолта',
    'жолти',
    'жолто',
    'жребе',
    'жреца',
    'жреци',
    'жрецу',
    'жртва',
    'жртви',
    'жртво',
    'жубор',
    'жугне',
    'жужел',
    'жужли',
    'жулав',
    'жулне',
    'жупан',
    'журка',
    'журки',
    'журко',
    'журне',
    'журов',
    'журон',
    'журот',
    'зааби',
    'заака',
    'заапе',
    'забае',
    'забан',
    'забар',
    'забат',
    'забел',
    'забен',
    'забер',
    'забец',
    'забив',
    'забие',
    'забиј',
    'забил',
    'забит',
    'забла',
    'забли',
    'забло',
    'забна',
    'забни',
    'забно',
    'забов',
    'забон',
    'забот',
    'забун',
    'завал',
    'завеа',
    'завее',
    'завеј',
    'завек',
    'завел',
    'завет',
    'завие',
    'завод',
    'завои',
    'завој',
    'завор',
    'завре',
    'заврз',
    'загар',
    'загои',
    'загон',
    'задал',
    'заден',
    'задна',
    'задни',
    'задно',
    'задои',
    'задре',
    'задув',
    'заека',
    'заеку',
    'заема',
    'заеме',
    'заеми',
    'заему',
    'заеци',
    'заече',
    'заечи',
    'зазор',
    'зазре',
    'зазуи',
    'заиде',
    'заика',
    'заима',
    'заиме',
    'заими',
    'заиму',
    'заита',
    'зајак',
    'зајде',
    'зајка',
    'зајко',
    'зајми',
    'зајре',
    'зајци',
    'зајче',
    'зајчи',
    'закла',
    'закол',
    'закон',
    'закоп',
    'закуп',
    'залае',
    'залај',
    'залак',
    'залее',
    'залез',
    'залет',
    'залив',
    'залие',
    'залис',
    'залов',
    'залог',
    'залпа',
    'залпе',
    'залпу',
    'залуд',
    'залче',
    'замав',
    'замае',
    'заман',
    'замба',
    'замби',
    'замбо',
    'замес',
    'замет',
    'замив',
    'замие',
    'замиј',
    'замил',
    'замин',
    'замка',
    'замки',
    'замко',
    'замку',
    'замок',
    'замор',
    'замре',
    'замче',
    'занел',
    'занес',
    'заода',
    'заоде',
    'заоди',
    'заоду',
    'заора',
    'запад',
    'запал',
    'запат',
    'запеа',
    'запев',
    'запее',
    'запеј',
    'запек',
    'запие',
    'запир',
    'запис',
    'запне',
    'запра',
    'запре',
    'запри',
    'запта',
    'запте',
    'запту',
    'запци',
    'запче',
    'запчи',
    'зарад',
    'зарар',
    'зарез',
    'зарек',
    'зарем',
    'заржи',
    'зарие',
    'заров',
    'зарон',
    'зарот',
    'зарфа',
    'зарфе',
    'зарфу',
    'зарче',
    'засак',
    'засеа',
    'засее',
    'засеј',
    'засек',
    'заспа',
    'затаи',
    'затир',
    'затка',
    'затки',
    'затко',
    'затне',
    'затоа',
    'затор',
    'затре',
    'затри',
    'затру',
    'заука',
    'зауми',
    'заути',
    'заучи',
    'зауши',
    'зафат',
    'зацеп',
    'зачас',
    'зачин',
    'зачне',
    'зачуе',
    'зашив',
    'зашие',
    'зашиј',
    'зашил',
    'зашто',
    'збега',
    'збере',
    'збива',
    'збиев',
    'збиел',
    'збиен',
    'збиеш',
    'збија',
    'збила',
    'збиле',
    'збило',
    'збира',
    'збире',
    'збиру',
    'зблуе',
    'зболи',
    'збора',
    'зборе',
    'збори',
    'збору',
    'збрка',
    'збрца',
    'збрчи',
    'збува',
    'збуди',
    'збуни',
    'збута',
    'збуца',
    'збучи',
    'звања',
    'звање',
    'звука',
    'звуку',
    'звуци',
    'звучи',
    'згага',
    'згаги',
    'згаго',
    'згази',
    'згака',
    'згине',
    'зглоб',
    'згние',
    'згоѓа',
    'згоѓи',
    'згоѓо',
    'згода',
    'згоди',
    'згодо',
    'зголи',
    'згона',
    'згоне',
    'згони',
    'згону',
    'згора',
    'згори',
    'згрди',
    'згрее',
    'згрли',
    'згрми',
    'згрне',
    'згрчи',
    'згура',
    'згури',
    'згуро',
    'згуши',
    'здави',
    'здене',
    'здива',
    'здиве',
    'здиви',
    'здиву',
    'здими',
    'здише',
    'здиши',
    'здоби',
    'здола',
    'здрав',
    'здрач',
    'здрви',
    'здржи',
    'здрув',
    'здува',
    'здупи',
    'здуши',
    'зебла',
    'зебло',
    'зебра',
    'зебри',
    'зебро',
    'зевка',
    'зевку',
    'зевов',
    'зевон',
    'зевот',
    'зевче',
    'зеера',
    'зеере',
    'зеери',
    'зееру',
    'зеира',
    'зеире',
    'зеири',
    'зеиру',
    'зејка',
    'зејки',
    'зејко',
    'зелен',
    'зелја',
    'зелје',
    'зелка',
    'зелки',
    'зелко',
    'земаа',
    'земав',
    'земај',
    'земал',
    'земам',
    'земан',
    'земат',
    'земаш',
    'земеа',
    'земев',
    'земел',
    'земен',
    'земеш',
    'земја',
    'земји',
    'земјо',
    'зенит',
    'зерде',
    'зетов',
    'зетон',
    'зетот',
    'зефир',
    'зијан',
    'зилја',
    'зилје',
    'зилји',
    'зилјо',
    'зилов',
    'зилон',
    'зилот',
    'зимен',
    'зифта',
    'зифте',
    'зифту',
    'зјапа',
    'злава',
    'злана',
    'злата',
    'злати',
    'злато',
    'зливе',
    'злине',
    'злиов',
    'злион',
    'злиот',
    'злите',
    'злоба',
    'злоби',
    'злобо',
    'злово',
    'злоно',
    'злост',
    'злота',
    'злоти',
    'злото',
    'змева',
    'змеве',
    'змеву',
    'змеја',
    'змеју',
    'змиин',
    'змија',
    'змијо',
    'знаат',
    'знаев',
    'знаел',
    'знаен',
    'знаеш',
    'знака',
    'знаку',
    'знаме',
    'знаци',
    'значи',
    'зноја',
    'зноју',
    'зобан',
    'зобне',
    'зобов',
    'зобон',
    'зобот',
    'зобта',
    'зовач',
    'зовов',
    'зовон',
    'зовот',
    'зовре',
    'зодов',
    'зодон',
    'зодот',
    'зокум',
    'золва',
    'золви',
    'золво',
    'зомба',
    'зомби',
    'зомбо',
    'зопца',
    'зопци',
    'зопцо',
    'зорен',
    'зорле',
    'зоров',
    'зорон',
    'зорот',
    'зорта',
    'зорте',
    'зорту',
    'зошто',
    'зрака',
    'зраку',
    'зраци',
    'зрачи',
    'зрела',
    'зрели',
    'зрело',
    'зрнен',
    'зрнца',
    'зрнце',
    'зуеја',
    'зуела',
    'зуеле',
    'зуело',
    'зуења',
    'зуење',
    'зуеше',
    'зуиме',
    'зуите',
    'зујам',
    'зујат',
    'зујте',
    'зулум',
    'зулуф',
    'зурла',
    'зурли',
    'зурло',
    'ѕвека',
    'ѕвеку',
    'ѕвера',
    'ѕвере',
    'ѕверу',
    'ѕвече',
    'ѕвизе',
    'ѕвона',
    'ѕвоне',
    'ѕвони',
    'ѕвоно',
    'ѕвону',
    'ѕиври',
    'ѕидаа',
    'ѕидав',
    'ѕидај',
    'ѕидал',
    'ѕидам',
    'ѕидан',
    'ѕидар',
    'ѕидаш',
    'ѕиден',
    'ѕидец',
    'ѕидје',
    'ѕидна',
    'ѕидни',
    'ѕидно',
    'ѕидов',
    'ѕидон',
    'ѕидот',
    'ѕидци',
    'ѕитче',
    'ѕрцки',
    'ѕумба',
    'ѕумби',
    'ѕумбо',
    'ѕунам',
    'ѕунат',
    'ѕунеа',
    'ѕунев',
    'ѕунел',
    'ѕуниш',
    'ѕунов',
    'ѕунон',
    'ѕунот',
    'ибиса',
    'ибисе',
    'ибиси',
    'ибису',
    'ибрик',
    'ивава',
    'ивана',
    'ивата',
    'ививе',
    'ивине',
    'ивите',
    'игава',
    'игана',
    'игата',
    'иглар',
    'иглен',
    'игово',
    'игоно',
    'игото',
    'играа',
    'играв',
    'играј',
    'играл',
    'играм',
    'игран',
    'играч',
    'играш',
    'игрив',
    'идеал',
    'идеен',
    'идеја',
    'идејо',
    'идела',
    'иделе',
    'идело',
    'идеме',
    'идења',
    'идење',
    'идете',
    'идеше',
    'идила',
    'идили',
    'идило',
    'идиом',
    'идиот',
    'идола',
    'идоле',
    'идоли',
    'идолу',
    'изаби',
    'изана',
    'изане',
    'изани',
    'изану',
    'избав',
    'избив',
    'избие',
    'избиј',
    'избил',
    'избор',
    'избра',
    'извар',
    'извее',
    'извел',
    'извив',
    'извие',
    'извиј',
    'извик',
    'извил',
    'извир',
    'извод',
    'извоз',
    'извор',
    'изгни',
    'изгон',
    'изгор',
    'издал',
    'издив',
    'изеде',
    'изеди',
    'изела',
    'изеле',
    'изело',
    'изиет',
    'изима',
    'изиме',
    'изими',
    'изиму',
    'изина',
    'изине',
    'изини',
    'изину',
    'излае',
    'излак',
    'излеа',
    'излее',
    'излез',
    'излеј',
    'излет',
    'излив',
    'излог',
    'измет',
    'измив',
    'измие',
    'измиј',
    'измил',
    'изнел',
    'износ',
    'изоди',
    'изора',
    'израз',
    'изрез',
    'изрие',
    'изрка',
    'изрод',
    'изрти',
    'изума',
    'изуме',
    'изуми',
    'изуму',
    'изучи',
    'икања',
    'икање',
    'икона',
    'икони',
    'иконо',
    'икрам',
    'илача',
    'илаче',
    'илачи',
    'илачу',
    'илево',
    'илено',
    'илест',
    'илето',
    'илика',
    'илики',
    'илико',
    'илику',
    'илиња',
    'илица',
    'илици',
    'иличе',
    'иљача',
    'иљаче',
    'иљачи',
    'иљачу',
    'имаат',
    'имаго',
    'имала',
    'имале',
    'имало',
    'имама',
    'имаме',
    'имами',
    'имаму',
    'имања',
    'имање',
    'имате',
    'имаше',
    'имево',
    'имела',
    'имели',
    'имело',
    'имено',
    'името',
    'имиња',
    'имота',
    'имоте',
    'имоти',
    'имоту',
    'имрен',
    'инает',
    'инаку',
    'ината',
    'инате',
    'инати',
    'инату',
    'индат',
    'инќар',
    'инсав',
    'инсан',
    'интов',
    'интон',
    'интот',
    'ираде',
    'ирама',
    'ираме',
    'ирами',
    'ираму',
    'ирвас',
    'ирита',
    'ирите',
    'ирити',
    'ириту',
    'исава',
    'исаве',
    'исави',
    'исаву',
    'исева',
    'исево',
    'иседи',
    'исели',
    'исено',
    'исето',
    'исече',
    'исечи',
    'исиња',
    'исипе',
    'исказ',
    'искап',
    'искон',
    'ископ',
    'искра',
    'искри',
    'искро',
    'искуп',
    'ислам',
    'исмеа',
    'исмее',
    'исмеј',
    'испад',
    'испат',
    'испеа',
    'испее',
    'испеј',
    'испив',
    'испие',
    'испиј',
    'испил',
    'испис',
    'испит',
    'испол',
    'испра',
    'исрка',
    'истап',
    'истек',
    'истои',
    'исток',
    'истом',
    'истум',
    'исука',
    'исуче',
    'исуши',
    'исход',
    'исшие',
    'итаат',
    'итака',
    'итала',
    'итале',
    'итало',
    'итаме',
    'итања',
    'итање',
    'итате',
    'иташе',
    'итрец',
    'ифрит',
    'ишала',
    'ишари',
    'иштав',
    'ишчии',
    'јаваа',
    'јавав',
    'јавај',
    'јавал',
    'јавам',
    'јаван',
    'јават',
    'јавач',
    'јаваш',
    'јавеа',
    'јавев',
    'јавел',
    'јавен',
    'јавиш',
    'јавка',
    'јавки',
    'јавко',
    'јавна',
    'јавне',
    'јавни',
    'јавно',
    'јавор',
    'јагма',
    'јагми',
    'јагмо',
    'јагне',
    'јагни',
    'јадам',
    'јадар',
    'јадат',
    'јадач',
    'јадва',
    'јадеа',
    'јадев',
    'јадеж',
    'јадел',
    'јаден',
    'јадец',
    'јадеш',
    'јадна',
    'јадни',
    'јадно',
    'јадоа',
    'јадов',
    'јадон',
    'јадот',
    'јадра',
    'јадро',
    'јадта',
    'јадци',
    'јажар',
    'јазак',
    'јазач',
    'јазди',
    'јазел',
    'јазии',
    'јазик',
    'јазли',
    'јазов',
    'јазол',
    'јазон',
    'јазот',
    'јајов',
    'јајон',
    'јајот',
    'јајца',
    'јајце',
    'јакии',
    'јакна',
    'јакне',
    'јалак',
    'јалан',
    'јалов',
    'јалук',
    'јамак',
    'јамба',
    'јамбе',
    'јамбу',
    'јамка',
    'јамки',
    'јамко',
    'јанѕа',
    'јанѕи',
    'јанѕо',
    'јании',
    'јанов',
    'јанон',
    'јанот',
    'јанта',
    'јанти',
    'јанто',
    'јапии',
    'јаран',
    'јарда',
    'јарде',
    'јарди',
    'јарду',
    'јарем',
    'јарец',
    'јарка',
    'јарма',
    'јарми',
    'јармо',
    'јарци',
    'јарче',
    'јасен',
    'јасја',
    'јасје',
    'јаска',
    'јасли',
    'јасна',
    'јасни',
    'јасно',
    'јатак',
    'јатка',
    'јатки',
    'јатко',
    'јаток',
    'јатор',
    'јахта',
    'јации',
    'јачка',
    'јереј',
    'јодов',
    'јодон',
    'јодот',
    'јонов',
    'јонон',
    'јонот',
    'јонџа',
    'југов',
    'југон',
    'југот',
    'јужен',
    'јужна',
    'јужни',
    'јужно',
    'јумак',
    'јунак',
    'јунец',
    'јунци',
    'јунче',
    'јуриш',
    'јурне',
    'јурта',
    'јурук',
    'јутен',
    'јутии',
    'јуфка',
    'јуфки',
    'јуфко',
    'кааза',
    'каази',
    'каазо',
    'кабак',
    'кабел',
    'кабил',
    'кабли',
    'кабул',
    'кавад',
    'каваз',
    'кавал',
    'кавга',
    'кавги',
    'кавго',
    'кавти',
    'кадар',
    'кадеж',
    'кадет',
    'кадии',
    'кадов',
    'кадон',
    'кадот',
    'кадра',
    'кадри',
    'кадро',
    'каеја',
    'каела',
    'каеле',
    'каело',
    'каеме',
    'каења',
    'каење',
    'каете',
    'каеше',
    'кажаа',
    'кажав',
    'кажал',
    'кажам',
    'кажан',
    'кажат',
    'кажеа',
    'кажев',
    'кажел',
    'кажеш',
    'казан',
    'казма',
    'казми',
    'казмо',
    'казна',
    'казни',
    'казно',
    'ќаиве',
    'каика',
    'каику',
    'ќаине',
    'каита',
    'ќаите',
    'каити',
    'каито',
    'каици',
    'каиче',
    'каиша',
    'каиши',
    'каишу',
    'кајак',
    'ќајов',
    'ќајон',
    'ќајот',
    'кајте',
    'кајца',
    'кајци',
    'кајцо',
    'кајче',
    'какаа',
    'какао',
    'каква',
    'какви',
    'какво',
    'каков',
    'какол',
    'калаи',
    'калај',
    'калам',
    'калап',
    'калаф',
    'калач',
    'калва',
    'калем',
    'кален',
    'калец',
    'калеш',
    'калиф',
    'калка',
    'калки',
    'калко',
    'калку',
    'кална',
    'калов',
    'калон',
    'калот',
    'калта',
    'калфа',
    'калфи',
    'калфо',
    'калца',
    'калци',
    'калцо',
    'калче',
    'камен',
    'камин',
    'камни',
    'кампа',
    'кампи',
    'кампу',
    'камче',
    'канал',
    'канам',
    'канап',
    'канат',
    'канач',
    'канеа',
    'канев',
    'канел',
    'канет',
    'кании',
    'каниш',
    'канон',
    'канта',
    'канти',
    'канто',
    'канче',
    'канџа',
    'канџи',
    'канџо',
    'кањон',
    'капак',
    'капам',
    'капан',
    'капар',
    'капат',
    'капач',
    'капеа',
    'капев',
    'капел',
    'капен',
    'капец',
    'капеш',
    'капии',
    'капка',
    'капки',
    'капко',
    'капне',
    'капса',
    'капсу',
    'капут',
    'капуш',
    'капци',
    'капче',
    'караа',
    'карав',
    'карај',
    'карал',
    'карам',
    'каран',
    'карар',
    'карат',
    'караш',
    'карба',
    'карби',
    'карбо',
    'карго',
    'кареа',
    'карма',
    'кароа',
    'ќаров',
    'ќарон',
    'ќарот',
    'карпа',
    'карпи',
    'карпо',
    'карст',
    'карта',
    'карти',
    'карто',
    'карчо',
    'карши',
    'касаи',
    'касај',
    'касап',
    'касач',
    'касен',
    'каска',
    'каски',
    'каско',
    'касне',
    'касни',
    'касно',
    'касов',
    'каста',
    'касти',
    'касто',
    'катар',
    'катен',
    'катиљ',
    'ќатип',
    'катов',
    'катон',
    'катот',
    'катун',
    'катче',
    'кауза',
    'каузи',
    'каузо',
    'каури',
    'кауча',
    'кауче',
    'каучи',
    'каучу',
    'кауша',
    'кауши',
    'каушу',
    'кафез',
    'кафен',
    'ќафир',
    'кацар',
    'кации',
    'качак',
    'качам',
    'качар',
    'качат',
    'качеа',
    'качев',
    'качел',
    'качен',
    'качив',
    'качил',
    'качиш',
    'качор',
    'кашав',
    'кашар',
    'кашен',
    'кашка',
    'кашла',
    'кашле',
    'кашло',
    'квази',
    'квака',
    'кваки',
    'квако',
    'квант',
    'кварт',
    'кварц',
    'кваса',
    'кваси',
    'квасу',
    'квачи',
    'квичи',
    'квота',
    'квоти',
    'квото',
    'ќебап',
    'кегла',
    'кегли',
    'кегло',
    'кедар',
    'ќедер',
    'кедри',
    'ќезап',
    'ќеифа',
    'ќеифе',
    'ќеифи',
    'ќеифу',
    'кејов',
    'кејон',
    'кејот',
    'кекса',
    'кекси',
    'кексу',
    'келав',
    'ќелав',
    'келар',
    'келеш',
    'ќелеш',
    'ќелии',
    'кељов',
    'кељон',
    'кељот',
    'ќемер',
    'кенар',
    'ќенар',
    'ќенев',
    'кенеф',
    'ќенеф',
    'кенка',
    'кепер',
    'кепец',
    'кепци',
    'ќепче',
    'керал',
    'ќерал',
    'ќерка',
    'ќерки',
    'ќерко',
    'ќесат',
    'ќесии',
    'ќесим',
    'кесон',
    'ќетен',
    'ќефил',
    'кефир',
    'ќефов',
    'ќефон',
    'ќефот',
    'кечап',
    'кечар',
    'ќешки',
    'кешов',
    'кешон',
    'кешот',
    'кибар',
    'киваа',
    'кивав',
    'кивај',
    'кивал',
    'кивам',
    'киваш',
    'кивка',
    'кивна',
    'кивне',
    'кивни',
    'кивор',
    'кивот',
    'кивур',
    'кивче',
    'кикир',
    'кикот',
    'кикса',
    'киксу',
    'килав',
    'килер',
    'ќилер',
    'килим',
    'килца',
    'килце',
    'кимка',
    'кимки',
    'кимко',
    'кимне',
    'кимов',
    'кимон',
    'кимот',
    'кинаа',
    'кинав',
    'кинал',
    'кинам',
    'кинат',
    'кинеа',
    'кинев',
    'кинел',
    'кинеш',
    'кинин',
    'кинис',
    'киоск',
    'кипар',
    'кипеж',
    'кипер',
    'кипов',
    'кипон',
    'кипот',
    'кипра',
    'кипри',
    'кипро',
    'кипче',
    'кирии',
    'кирка',
    'кирки',
    'кирко',
    'киров',
    'кирон',
    'кирот',
    'кисел',
    'киска',
    'киски',
    'киско',
    'кисне',
    'китаб',
    'китам',
    'ќитап',
    'китат',
    'китеа',
    'китев',
    'кител',
    'китен',
    'китиш',
    'китка',
    'китки',
    'китко',
    'китов',
    'китон',
    'китот',
    'китче',
    'кифла',
    'кифли',
    'кифло',
    'кичер',
    'кичур',
    'кишка',
    'кишки',
    'кишко',
    'клава',
    'клада',
    'кладе',
    'клади',
    'кладо',
    'клања',
    'клање',
    'клапа',
    'клапи',
    'клапо',
    'класа',
    'класи',
    'класо',
    'класу',
    'клати',
    'кледа',
    'кледе',
    'кледу',
    'клека',
    'клеку',
    'клема',
    'клена',
    'клене',
    'клену',
    'клепа',
    'клера',
    'клере',
    'клеру',
    'клета',
    'клете',
    'клету',
    'клече',
    'клечи',
    'клечо',
    'клика',
    'клики',
    'клико',
    'клику',
    'клима',
    'клими',
    'климо',
    'клина',
    'клине',
    'клину',
    'клинч',
    'клипа',
    'клиса',
    'клиси',
    'клисо',
    'кличе',
    'клише',
    'клови',
    'кловн',
    'клона',
    'клоне',
    'клони',
    'клону',
    'клопа',
    'клоца',
    'клоци',
    'клоцо',
    'клуба',
    'клубе',
    'клубу',
    'клука',
    'клуна',
    'клуне',
    'клуну',
    'клупа',
    'клупи',
    'клупо',
    'клуча',
    'клуче',
    'клучу',
    'кљака',
    'кмека',
    'кмета',
    'кмете',
    'кмету',
    'кмиши',
    'кназа',
    'кназе',
    'кназу',
    'кнеза',
    'кнезе',
    'кнезу',
    'книга',
    'книги',
    'книго',
    'книже',
    'книжи',
    'коала',
    'кобеж',
    'кобел',
    'кобен',
    'кобец',
    'кобле',
    'кобли',
    'кобна',
    'кобни',
    'кобно',
    'кобра',
    'кобта',
    'коваа',
    'ковав',
    'ковај',
    'ковал',
    'ковам',
    'кован',
    'ковач',
    'коваш',
    'ковил',
    'ковне',
    'ковов',
    'ковон',
    'ковот',
    'ковче',
    'когаш',
    'коден',
    'кодик',
    'кодна',
    'кодни',
    'кодно',
    'кодов',
    'кодон',
    'кодот',
    'кодош',
    'кожар',
    'кожен',
    'кожле',
    'кожна',
    'кожни',
    'кожно',
    'кожув',
    'козав',
    'козак',
    'козар',
    'козер',
    'козји',
    'козле',
    'кокал',
    'кокар',
    'коков',
    'кокон',
    'кокос',
    'кокот',
    'кокса',
    'коксу',
    'колаж',
    'колаи',
    'колај',
    'колак',
    'колам',
    'колан',
    'колар',
    'колат',
    'колач',
    'колба',
    'колби',
    'колбо',
    'колва',
    'колве',
    'колеа',
    'колев',
    'колеж',
    'колел',
    'колет',
    'колец',
    'колеџ',
    'колеш',
    'колје',
    'колка',
    'колку',
    'колне',
    'колни',
    'колов',
    'колон',
    'колор',
    'колос',
    'колот',
    'колца',
    'колце',
    'колци',
    'колче',
    'комад',
    'комај',
    'комар',
    'комат',
    'комба',
    'комбе',
    'комби',
    'комбо',
    'комик',
    'комка',
    'комки',
    'комко',
    'комов',
    'конак',
    'конец',
    'коник',
    'коноп',
    'конта',
    'конте',
    'конти',
    'конто',
    'конус',
    'конци',
    'конче',
    'коњак',
    'коњар',
    'коњик',
    'коњов',
    'коњон',
    'коњот',
    'коњче',
    'копаа',
    'копав',
    'ќопав',
    'копај',
    'копал',
    'копам',
    'копан',
    'копар',
    'копач',
    'копаш',
    'ќопек',
    'копец',
    'копие',
    'копии',
    'копил',
    'копја',
    'копје',
    'копка',
    'копна',
    'копне',
    'копни',
    'копно',
    'копов',
    'копон',
    'копот',
    'копра',
    'копри',
    'копро',
    'копук',
    'копци',
    'копче',
    'кораб',
    'корав',
    'ќорав',
    'корал',
    'коран',
    'корач',
    'корен',
    'корза',
    'корзо',
    'кории',
    'корил',
    'корка',
    'корки',
    'корко',
    'ќорле',
    'корна',
    'корне',
    'корни',
    'корно',
    'коров',
    'корон',
    'корот',
    'корпа',
    'коруз',
    'корче',
    'ќорчо',
    'ќосав',
    'косам',
    'косар',
    'косат',
    'косач',
    'косеа',
    'косев',
    'косеж',
    'косел',
    'косен',
    'косир',
    'косиш',
    'коска',
    'коски',
    'коско',
    'косма',
    'косми',
    'космо',
    'косне',
    'косов',
    'ќосов',
    'косон',
    'ќосон',
    'косор',
    'косот',
    'ќосот',
    'котар',
    'котва',
    'котви',
    'котво',
    'ќотек',
    'котел',
    'котец',
    'котка',
    'котле',
    'котли',
    'котов',
    'котон',
    'котот',
    'котрр',
    'котур',
    'котци',
    'ќофте',
    'коцел',
    'коцка',
    'коцки',
    'коцко',
    'коцле',
    'коцли',
    'кочам',
    'кочан',
    'кочат',
    'кочеа',
    'кочев',
    'кочел',
    'кочен',
    'кочии',
    'кочиш',
    'кочов',
    'кочон',
    'кочот',
    'кошар',
    'кошер',
    'кошии',
    'кошка',
    'кошне',
    'кошов',
    'ќошов',
    'кошон',
    'ќошон',
    'кошот',
    'ќошот',
    'кошта',
    'краба',
    'крава',
    'крави',
    'краво',
    'крага',
    'крагу',
    'краде',
    'кради',
    'краен',
    'краже',
    'краја',
    'крају',
    'крака',
    'краку',
    'крала',
    'крале',
    'крало',
    'кралу',
    'крамп',
    'крана',
    'кране',
    'крану',
    'крапа',
    'крапе',
    'крапу',
    'краса',
    'краси',
    'красо',
    'красу',
    'крати',
    'краха',
    'краху',
    'краци',
    'краче',
    'крбла',
    'крбли',
    'крбло',
    'крвав',
    'крвен',
    'крвје',
    'крвна',
    'крвни',
    'крвно',
    'крвта',
    'крвца',
    'крвци',
    'крвцо',
    'крвче',
    'крдак',
    'крдар',
    'крева',
    'креда',
    'креди',
    'кредо',
    'крека',
    'креку',
    'крема',
    'креме',
    'креми',
    'кремо',
    'крему',
    'крене',
    'креол',
    'крепа',
    'крепе',
    'крепи',
    'крепо',
    'крепу',
    'креча',
    'крече',
    'кречу',
    'крзна',
    'крзно',
    'крива',
    'криви',
    'криво',
    'криев',
    'криел',
    'криен',
    'криеш',
    'криза',
    'кризи',
    'кризо',
    'крика',
    'крику',
    'крила',
    'крили',
    'крило',
    'крина',
    'крине',
    'крину',
    'криче',
    'кркач',
    'кркма',
    'кркми',
    'кркмо',
    'кркне',
    'кркор',
    'кркот',
    'кркуш',
    'кркче',
    'крлеж',
    'крлик',
    'крлук',
    'крмак',
    'крмар',
    'крмен',
    'крмуз',
    'крова',
    'крове',
    'крову',
    'кроев',
    'кроеж',
    'кроел',
    'кроен',
    'кроиш',
    'кроја',
    'кроју',
    'кроки',
    'кроса',
    'кросу',
    'кроти',
    'кроце',
    'крпам',
    'крпат',
    'крпач',
    'крпеа',
    'крпев',
    'крпеж',
    'крпел',
    'крпен',
    'крпиш',
    'крпче',
    'крста',
    'крсте',
    'крсти',
    'крсту',
    'кртов',
    'кртон',
    'кртот',
    'кртул',
    'круга',
    'кругу',
    'круже',
    'кружи',
    'круна',
    'круни',
    'круно',
    'крупа',
    'крупе',
    'крупу',
    'крута',
    'крути',
    'круто',
    'круша',
    'круши',
    'крушо',
    'крцка',
    'крцне',
    'крцул',
    'крчма',
    'крчми',
    'крчмо',
    'кршам',
    'кршат',
    'кршеа',
    'кршев',
    'кршел',
    'кршен',
    'кршиш',
    'кршка',
    'кршла',
    'кршли',
    'кршло',
    'кршне',
    'кршов',
    'кршон',
    'кршот',
    'кубен',
    'кубик',
    'кубна',
    'кубне',
    'кубни',
    'кубно',
    'кубов',
    'кубон',
    'кубот',
    'кубур',
    'кувет',
    'кувче',
    'кугла',
    'кугли',
    'кугло',
    'кудач',
    'ќудов',
    'ќудон',
    'ќудот',
    'ќудта',
    'кужељ',
    'кужла',
    'кузња',
    'кузњи',
    'кузњо',
    'кузум',
    'кујна',
    'кујни',
    'кујно',
    'куќен',
    'кукла',
    'кукли',
    'кукло',
    'куќна',
    'кукне',
    'куќни',
    'куќно',
    'куков',
    'кукул',
    'ќуќур',
    'ќулав',
    'кулак',
    'ќулан',
    'кулаш',
    'култа',
    'култе',
    'култу',
    'кулук',
    'ќулче',
    'кумаш',
    'ќумбе',
    'ќумез',
    'кумин',
    'кумир',
    'кумов',
    'кумон',
    'кумот',
    'ќумур',
    'кумче',
    'ќунец',
    'кунин',
    'ќунка',
    'ќунку',
    'кунов',
    'ќунци',
    'ќунче',
    'купам',
    'купар',
    'купат',
    'купеа',
    'купев',
    'купел',
    'купен',
    'купец',
    'купив',
    'купил',
    'купиш',
    'купли',
    'купов',
    'ќупов',
    'купон',
    'ќупон',
    'купот',
    'ќупот',
    'купри',
    'купци',
    'купче',
    'ќупче',
    'кураж',
    'курва',
    'курви',
    'курво',
    'курии',
    'курир',
    'курле',
    'ќурка',
    'ќурку',
    'курна',
    'курни',
    'курно',
    'курса',
    'курсу',
    'ќурче',
    'кусак',
    'кусам',
    'кусат',
    'кусеа',
    'кусев',
    'кусел',
    'кусен',
    'кусиш',
    'куско',
    'кусок',
    'ќуспе',
    'кусур',
    'кутар',
    'кутел',
    'кутер',
    'кутии',
    'кутка',
    'кутли',
    'кутне',
    'кутол',
    'кутре',
    'кутри',
    'ќутук',
    'куфер',
    'куцаа',
    'куцав',
    'куцај',
    'куцал',
    'куцам',
    'куцаш',
    'куцка',
    'куцне',
    'кучка',
    'кучки',
    'кучко',
    'кушач',
    'кушет',
    'ќушка',
    'ќушки',
    'ќушко',
    'ќушне',
    'лаана',
    'лаани',
    'лаано',
    'лабав',
    'лабед',
    'лавка',
    'лавне',
    'лавов',
    'лавон',
    'лавор',
    'лавот',
    'лавра',
    'лаври',
    'лавро',
    'лавче',
    'лагам',
    'лаѓар',
    'лагер',
    'лагов',
    'лагон',
    'лагот',
    'лагум',
    'ладам',
    'ладат',
    'ладеа',
    'ладев',
    'ладел',
    'ладен',
    'ладиш',
    'ладна',
    'ладни',
    'ладно',
    'ладов',
    'ладон',
    'ладот',
    'лаеви',
    'лаежа',
    'лаежи',
    'лаежу',
    'лаеја',
    'лаела',
    'лаеле',
    'лаело',
    'лаеме',
    'лаења',
    'лаење',
    'лаете',
    'лаеше',
    'лажам',
    'лажат',
    'лажач',
    'лажга',
    'лажги',
    'лажго',
    'лажеа',
    'лажев',
    'лажел',
    'лажен',
    'лажец',
    'лажеш',
    'лажла',
    'лажли',
    'лажло',
    'лажна',
    'лажни',
    'лажно',
    'лажов',
    'лажон',
    'лажот',
    'лазам',
    'лазар',
    'лазат',
    'лазач',
    'лазеа',
    'лазев',
    'лазел',
    'лазиш',
    'лазје',
    'лазне',
    'лазов',
    'лазон',
    'лазот',
    'лазур',
    'лаика',
    'лаику',
    'лаици',
    'лаиче',
    'лајач',
    'лајна',
    'лајно',
    'лајов',
    'лајон',
    'лајот',
    'лајте',
    'лајца',
    'лајци',
    'лајцо',
    'лајче',
    'лакањ',
    'лакеи',
    'лакеј',
    'лаков',
    'лаком',
    'лакон',
    'лакот',
    'лакта',
    'лакто',
    'ламба',
    'ламби',
    'ламбо',
    'ламии',
    'ламја',
    'ламји',
    'ламјо',
    'ламка',
    'ламња',
    'ламњи',
    'ламњо',
    'лампа',
    'ламти',
    'ланец',
    'ланче',
    'лапач',
    'лапис',
    'лапка',
    'лапки',
    'лапко',
    'лапне',
    'лапор',
    'ларва',
    'ларви',
    'ларво',
    'ларии',
    'лариј',
    'ласер',
    'ласка',
    'ласки',
    'ласко',
    'ласне',
    'латка',
    'латки',
    'латко',
    'лаура',
    'лауре',
    'лаури',
    'лауру',
    'лаута',
    'лафет',
    'лафов',
    'лафон',
    'лафот',
    'лацне',
    'лачам',
    'лачат',
    'лачеа',
    'лачев',
    'лачел',
    'лачен',
    'лачиш',
    'лачка',
    'лачки',
    'лачко',
    'лачов',
    'лачон',
    'лачот',
    'лашка',
    'лашки',
    'лашко',
    'леава',
    'леана',
    'леани',
    'леано',
    'леара',
    'леаре',
    'леари',
    'леару',
    'леата',
    'лебар',
    'лебди',
    'лебед',
    'лебен',
    'лебец',
    'лебов',
    'лебон',
    'лебот',
    'левак',
    'левен',
    'левов',
    'левон',
    'левот',
    'легат',
    'легач',
    'леген',
    'леѓен',
    'легии',
    'легла',
    'легло',
    'легна',
    'легне',
    'легни',
    'легуш',
    'леден',
    'ледна',
    'ледни',
    'ледно',
    'ледов',
    'ледон',
    'ледот',
    'лееја',
    'леела',
    'лееле',
    'леело',
    'лееме',
    'леења',
    'леење',
    'леете',
    'лееше',
    'лежаи',
    'лежај',
    'лежам',
    'лежат',
    'лежач',
    'лежеа',
    'лежев',
    'лежел',
    'лежиш',
    'лезет',
    'леиве',
    'леине',
    'леите',
    'лејка',
    'лејки',
    'лејко',
    'лејте',
    'лекар',
    'лекне',
    'леков',
    'лекон',
    'лекот',
    'лекум',
    'лелее',
    'лелек',
    'лемеш',
    'лемов',
    'лемон',
    'лемот',
    'ленен',
    'ленов',
    'ленон',
    'ленот',
    'лента',
    'ленти',
    'ленто',
    'лепам',
    'лепат',
    'лепач',
    'лепеа',
    'лепев',
    'лепеж',
    'лепел',
    'лепен',
    'лепиш',
    'лепка',
    'лепне',
    'лепра',
    'лепри',
    'лепро',
    'лепци',
    'лепче',
    'лесен',
    'леска',
    'лески',
    'леско',
    'лесна',
    'лесни',
    'лесно',
    'летаа',
    'летав',
    'летај',
    'летал',
    'летам',
    'летан',
    'летач',
    'леташ',
    'летва',
    'летви',
    'летво',
    'летеж',
    'летен',
    'летец',
    'летка',
    'летки',
    'летко',
    'летна',
    'летне',
    'летни',
    'летно',
    'летов',
    'леток',
    'летон',
    'летот',
    'летци',
    'леуса',
    'леуси',
    'леусо',
    'лечам',
    'лечат',
    'лечеа',
    'лечев',
    'лечел',
    'лечен',
    'лечиш',
    'лешка',
    'лешки',
    'лешко',
    'лешов',
    'лешон',
    'лешот',
    'ливан',
    'ливка',
    'ливне',
    'ливов',
    'ливон',
    'ливот',
    'ливра',
    'ливри',
    'ливро',
    'ливче',
    'лигав',
    'лигда',
    'лигди',
    'лигдо',
    'лигла',
    'лигли',
    'лигло',
    'лигна',
    'лигуш',
    'лидер',
    'лиења',
    'лиење',
    'лижам',
    'лижат',
    'лижач',
    'лижеа',
    'лижев',
    'лижел',
    'лижеш',
    'лизга',
    'лизна',
    'лизне',
    'лизни',
    'лијар',
    'ликер',
    'ликов',
    'ликон',
    'ликот',
    'ликум',
    'лилав',
    'лилии',
    'лимар',
    'лимба',
    'лимби',
    'лимбо',
    'лимен',
    'лимит',
    'лимја',
    'лимји',
    'лимјо',
    'лимов',
    'лимон',
    'лимот',
    'лимфа',
    'лимфи',
    'лимфо',
    'лимче',
    'линар',
    'линее',
    'линии',
    'линча',
    'линче',
    'линчу',
    'лиота',
    'лиоте',
    'лиоти',
    'лиоту',
    'липаа',
    'липав',
    'липај',
    'липак',
    'липал',
    'липам',
    'липач',
    'липаш',
    'липне',
    'липов',
    'липот',
    'липса',
    'липта',
    'лирик',
    'лисец',
    'лисја',
    'лисје',
    'лиска',
    'лиски',
    'лиско',
    'лисне',
    'листа',
    'листе',
    'листи',
    'листо',
    'листу',
    'лисци',
    'литак',
    'литар',
    'литии',
    'литра',
    'литри',
    'литро',
    'лифта',
    'лифте',
    'лифту',
    'лихва',
    'лихви',
    'лихво',
    'лицеи',
    'лицеј',
    'лицка',
    'лицна',
    'личам',
    'личат',
    'личеа',
    'личев',
    'личел',
    'личен',
    'личиш',
    'лична',
    'лични',
    'лично',
    'лиџба',
    'лиџби',
    'лиџбо',
    'лишав',
    'лишаи',
    'лишај',
    'лишам',
    'лишат',
    'лишеа',
    'лишев',
    'лишел',
    'лишен',
    'лишив',
    'лишил',
    'лишиш',
    'лишка',
    'лишки',
    'лишко',
    'ловам',
    'ловат',
    'ловач',
    'ловеа',
    'ловев',
    'ловел',
    'ловен',
    'ловец',
    'ловиш',
    'ловов',
    'ловон',
    'ловор',
    'ловот',
    'ловци',
    'ловче',
    'логав',
    'логор',
    'лоеви',
    'лоења',
    'лоење',
    'лоест',
    'лоецу',
    'ложач',
    'лозар',
    'лозја',
    'лозје',
    'лозов',
    'лозон',
    'лозот',
    'лојов',
    'лојон',
    'лојот',
    'лојци',
    'локал',
    'локан',
    'локач',
    'локва',
    'локви',
    'локво',
    'локма',
    'локми',
    'локмо',
    'локна',
    'локне',
    'локни',
    'локно',
    'локум',
    'ломен',
    'ломов',
    'ломон',
    'ломот',
    'лонец',
    'лонче',
    'лонџа',
    'лонџи',
    'лонџо',
    'лопка',
    'лопки',
    'лопко',
    'лопув',
    'лопур',
    'лорда',
    'лорде',
    'лорду',
    'лосов',
    'лосон',
    'лосос',
    'лосот',
    'лоста',
    'лосте',
    'лосту',
    'лотка',
    'лотки',
    'лотко',
    'лотос',
    'лочка',
    'лочки',
    'лочко',
    'лубов',
    'лубон',
    'лубот',
    'лудак',
    'лузна',
    'лузни',
    'лузно',
    'лукав',
    'луков',
    'лукон',
    'лукот',
    'лулаа',
    'лулав',
    'лулај',
    'лулал',
    'лулам',
    'лулан',
    'лулаш',
    'лулее',
    'лулка',
    'лулки',
    'лулко',
    'лулне',
    'лунав',
    'лунен',
    'луњав',
    'луњен',
    'лупам',
    'лупат',
    'лупеа',
    'лупев',
    'лупел',
    'лупен',
    'лупиш',
    'лупка',
    'лупне',
    'лусне',
    'лутам',
    'лутат',
    'лутеа',
    'лутев',
    'лутел',
    'лутен',
    'лутиш',
    'лушпа',
    'лушпи',
    'лушпо',
    'љубам',
    'љубат',
    'љубеа',
    'љубев',
    'љубел',
    'љубен',
    'љубим',
    'љубиш',
    'љубна',
    'љубни',
    'љубно',
    'љубов',
    'људба',
    'људби',
    'људбо',
    'мааза',
    'маази',
    'маазо',
    'маала',
    'маали',
    'маало',
    'маана',
    'маани',
    'маано',
    'мавка',
    'мавне',
    'мавов',
    'мавон',
    'мавот',
    'мавта',
    'мавца',
    'мавци',
    'мавцо',
    'мавче',
    'маѓер',
    'магии',
    'маѓии',
    'магла',
    'магли',
    'магло',
    'магма',
    'магов',
    'магон',
    'магот',
    'мадам',
    'мадем',
    'мадро',
    'маења',
    'маење',
    'мажен',
    'мажле',
    'мажна',
    'мажни',
    'мажно',
    'мажов',
    'мажон',
    'мажот',
    'мазга',
    'мазги',
    'мазго',
    'мазда',
    'мазде',
    'мазду',
    'мазен',
    'мазии',
    'мазна',
    'мазни',
    'мазно',
    'мазут',
    'маиве',
    'маине',
    'маите',
    'маица',
    'мајка',
    'мајки',
    'мајко',
    'мајор',
    'мајче',
    'макар',
    'маќеа',
    'маќеи',
    'маќео',
    'макла',
    'макне',
    'маков',
    'макон',
    'макот',
    'макро',
    'макси',
    'малее',
    'малер',
    'малку',
    'малов',
    'малон',
    'малот',
    'мамам',
    'мамат',
    'мамеа',
    'мамев',
    'мамеж',
    'мамел',
    'мамен',
    'мамин',
    'мамиш',
    'мамка',
    'мамки',
    'мамко',
    'мамуз',
    'мамут',
    'манаф',
    'манга',
    'манги',
    'манго',
    'манда',
    'манди',
    'мандо',
    'манеж',
    'мании',
    'манир',
    'манук',
    'манџа',
    'манџи',
    'манџо',
    'мараз',
    'марда',
    'марди',
    'мардо',
    'маржа',
    'маржи',
    'маржо',
    'марка',
    'марки',
    'марко',
    'марок',
    'марта',
    'марти',
    'марто',
    'марша',
    'маршу',
    'масал',
    'масат',
    'масен',
    'масер',
    'масив',
    'маска',
    'маски',
    'маско',
    'масла',
    'масло',
    'масна',
    'масни',
    'масно',
    'масон',
    'маста',
    'масти',
    'матач',
    'матеж',
    'матен',
    'матка',
    'матки',
    'матко',
    'матна',
    'матне',
    'матни',
    'матно',
    'матов',
    'маток',
    'матон',
    'матор',
    'матот',
    'матув',
    'мафии',
    'махер',
    'мацне',
    'мачам',
    'мачат',
    'мачеа',
    'мачев',
    'мачел',
    'мачен',
    'мачиш',
    'мачка',
    'мачки',
    'мачко',
    'мачна',
    'мачни',
    'мачно',
    'мачор',
    'маџун',
    'машка',
    'машки',
    'машко',
    'меана',
    'меани',
    'меано',
    'мебел',
    'мевов',
    'мевон',
    'мевот',
    'мевца',
    'мевце',
    'медал',
    'медар',
    'меден',
    'медна',
    'медни',
    'медно',
    'медов',
    'медон',
    'медот',
    'мекам',
    'меќан',
    'мекне',
    'мекуш',
    'мелам',
    'мелат',
    'мелеа',
    'мелев',
    'мелез',
    'мелел',
    'мелем',
    'мелен',
    'мелеш',
    'мелос',
    'мемла',
    'мемли',
    'мемло',
    'менде',
    'менза',
    'мензи',
    'мензо',
    'мента',
    'менти',
    'менто',
    'мерак',
    'мерам',
    'мерат',
    'мерач',
    'мереа',
    'мерев',
    'мерел',
    'мерен',
    'мериш',
    'мерка',
    'мерки',
    'мерко',
    'мерна',
    'мерни',
    'мерно',
    'месам',
    'месар',
    'месат',
    'месач',
    'месеа',
    'месев',
    'месел',
    'месен',
    'месец',
    'месии',
    'месиш',
    'месна',
    'месни',
    'месно',
    'места',
    'мести',
    'место',
    'месца',
    'месце',
    'месци',
    'метал',
    'метам',
    'метан',
    'метар',
    'метат',
    'метач',
    'метеа',
    'метев',
    'метеж',
    'метел',
    'метеш',
    'метил',
    'метка',
    'метки',
    'метко',
    'метла',
    'метле',
    'метли',
    'метло',
    'метне',
    'метод',
    'метох',
    'метра',
    'метри',
    'метро',
    'метув',
    'меура',
    'меуре',
    'меури',
    'меуру',
    'мечар',
    'мечка',
    'мечки',
    'мечко',
    'мечов',
    'мечок',
    'мечон',
    'мечот',
    'мечта',
    'мечти',
    'мечто',
    'мешаа',
    'мешав',
    'мешај',
    'мешал',
    'мешам',
    'мешан',
    'мешач',
    'мешаш',
    'мешин',
    'мешка',
    'мешко',
    'мешли',
    'мешло',
    'мешне',
    'мивка',
    'мивки',
    'мивко',
    'мивне',
    'мигач',
    'мигла',
    'мигли',
    'мигло',
    'мигне',
    'мигов',
    'мигон',
    'мигот',
    'мигум',
    'мидер',
    'миеја',
    'миела',
    'миеле',
    'миело',
    'миеме',
    'миена',
    'миени',
    'миено',
    'миења',
    'миење',
    'миете',
    'миеше',
    'мижав',
    'мижла',
    'мижли',
    'мижло',
    'мијам',
    'мијат',
    'мијач',
    'мијте',
    'микро',
    'милее',
    'милен',
    'милет',
    'милја',
    'милје',
    'милји',
    'милјо',
    'милка',
    'милку',
    'милне',
    'милув',
    'милум',
    'милче',
    'минаа',
    'минав',
    'минај',
    'минал',
    'минам',
    'минат',
    'минаш',
    'минеи',
    'минеј',
    'минер',
    'минус',
    'минца',
    'минце',
    'минцу',
    'мираз',
    'мирба',
    'мирби',
    'мирбо',
    'мирен',
    'мирии',
    'мирис',
    'мирна',
    'мирни',
    'мирно',
    'миров',
    'мирон',
    'мирот',
    'мирта',
    'мирти',
    'мирто',
    'мисии',
    'мисир',
    'миска',
    'миски',
    'миско',
    'миску',
    'мисла',
    'мисли',
    'мисло',
    'мисур',
    'мисче',
    'митов',
    'митон',
    'митот',
    'митра',
    'митри',
    'митро',
    'мишка',
    'мишки',
    'мишко',
    'млада',
    'млади',
    'младо',
    'млаза',
    'млазу',
    'млака',
    'млаки',
    'млако',
    'млачи',
    'млека',
    'млеко',
    'млеча',
    'млече',
    'млечу',
    'млива',
    'мливо',
    'многу',
    'множи',
    'мовов',
    'мовон',
    'мовот',
    'мовта',
    'мовче',
    'модар',
    'модел',
    'модем',
    'моден',
    'модла',
    'модри',
    'модул',
    'модус',
    'моево',
    'моено',
    'моето',
    'можам',
    'можат',
    'можеа',
    'можев',
    'можел',
    'можен',
    'можеш',
    'можна',
    'можни',
    'можно',
    'мозок',
    'мозол',
    'моиве',
    'моине',
    'моите',
    'мојов',
    'мојон',
    'мојот',
    'мокар',
    'моќва',
    'моќен',
    'моќна',
    'моќни',
    'моќно',
    'мокра',
    'мокри',
    'мокро',
    'моќта',
    'молам',
    'молат',
    'молба',
    'молби',
    'молбо',
    'молеа',
    'молев',
    'молел',
    'молен',
    'молер',
    'молец',
    'молзе',
    'молив',
    'молиш',
    'молка',
    'молку',
    'молна',
    'молни',
    'молно',
    'молња',
    'молњи',
    'молњо',
    'молци',
    'молче',
    'молчи',
    'момее',
    'момин',
    'момок',
    'момче',
    'монах',
    'моном',
    'мопед',
    'мопса',
    'мопсу',
    'морав',
    'морал',
    'морач',
    'морга',
    'морен',
    'мории',
    'моров',
    'морон',
    'морот',
    'моста',
    'мосте',
    'мости',
    'мосту',
    'мотач',
    'мотел',
    'мотив',
    'мотор',
    'мочаа',
    'мочав',
    'мочај',
    'мочал',
    'мочам',
    'мочан',
    'мочаш',
    'мочен',
    'мочка',
    'мочки',
    'мочко',
    'мочла',
    'мочли',
    'мочло',
    'мочне',
    'мочта',
    'мочур',
    'мошне',
    'мошта',
    'мошти',
    'мошус',
    'мрава',
    'мраве',
    'мрави',
    'мраво',
    'мраву',
    'мраза',
    'мрази',
    'мразу',
    'мрака',
    'мраки',
    'мрако',
    'мраку',
    'мраче',
    'мрвка',
    'мрвки',
    'мрвко',
    'мрдаа',
    'мрдав',
    'мрдај',
    'мрдал',
    'мрдам',
    'мрдан',
    'мрдач',
    'мрдаш',
    'мрдна',
    'мрдне',
    'мрдни',
    'мрежа',
    'мрежи',
    'мрежо',
    'мрена',
    'мрени',
    'мрено',
    'мрест',
    'мрзел',
    'мрзла',
    'мрзли',
    'мрзло',
    'мрзне',
    'мркач',
    'мрмка',
    'мрмор',
    'мрмот',
    'мрсен',
    'мрсна',
    'мрсни',
    'мрсно',
    'мрсот',
    'мрсул',
    'мртва',
    'мртви',
    'мртво',
    'мртка',
    'мртов',
    'мрцка',
    'мрцне',
    'мрчка',
    'мршав',
    'мувла',
    'мувли',
    'мувло',
    'мугра',
    'мугри',
    'мугро',
    'мудар',
    'мудир',
    'мудур',
    'мужде',
    'музга',
    'музеи',
    'музеј',
    'мукач',
    'мукне',
    'муков',
    'мукон',
    'мукот',
    'мулак',
    'мулат',
    'мулка',
    'мулку',
    'мулти',
    'мулче',
    'мумии',
    'мумла',
    'мунѕа',
    'мунѕи',
    'мунѕо',
    'мурал',
    'мурго',
    'муров',
    'мурон',
    'мурот',
    'мурџе',
    'мурџо',
    'мутав',
    'мутли',
    'муфте',
    'мухур',
    'муцка',
    'муцки',
    'муцко',
    'муцне',
    'муцун',
    'мушка',
    'мушне',
    'мушта',
    'мушти',
    'мушто',
    'наака',
    'наала',
    'набае',
    'набив',
    'набие',
    'набиј',
    'набил',
    'набои',
    'набој',
    'набор',
    'набра',
    'набуи',
    'навал',
    'навев',
    'навее',
    'навек',
    'навел',
    'навив',
    'навие',
    'навиј',
    'навик',
    'навил',
    'навод',
    'навој',
    'навре',
    'наган',
    'наѓез',
    'нагол',
    'нагон',
    'надве',
    'надви',
    'надеж',
    'надеи',
    'надир',
    'надои',
    'надуе',
    'наежи',
    'наема',
    'наеме',
    'наеми',
    'наему',
    'наеро',
    'назад',
    'назал',
    'назив',
    'наива',
    'наиде',
    'наија',
    'наијо',
    'најал',
    'најде',
    'најди',
    'најми',
    'наказ',
    'накај',
    'накит',
    'накот',
    'накуп',
    'налан',
    'налеа',
    'налее',
    'налеј',
    'налеп',
    'налет',
    'налик',
    'налка',
    'налки',
    'налко',
    'налог',
    'налои',
    'налом',
    'налон',
    'налче',
    'намаз',
    'намек',
    'намет',
    'намиг',
    'намин',
    'намуз',
    'нанел',
    'наниз',
    'нанка',
    'нанос',
    'наоѓа',
    'наода',
    'наоде',
    'наоди',
    'наоду',
    'наора',
    'напад',
    'напев',
    'напив',
    'напие',
    'напиј',
    'напил',
    'напир',
    'напис',
    'напне',
    'напои',
    'напон',
    'напор',
    'напре',
    'нарав',
    'нарач',
    'наред',
    'нарка',
    'нарку',
    'наров',
    'народ',
    'нарон',
    'нарот',
    'нарче',
    'насад',
    'насее',
    'насип',
    'насит',
    'насон',
    'наспа',
    'насто',
    'наука',
    'науки',
    'науко',
    'науми',
    'наута',
    'науте',
    'наути',
    'науту',
    'научи',
    'нафта',
    'нафти',
    'нафто',
    'нахии',
    'нации',
    'нацрт',
    'начас',
    'начек',
    'начин',
    'начне',
    'начуе',
    'нашие',
    'нашир',
    'нашла',
    'нашле',
    'нашло',
    'нашне',
    'нашол',
    'наште',
    'небен',
    'невен',
    'невин',
    'негар',
    'негде',
    'негов',
    'негри',
    'недуг',
    'нежен',
    'нежна',
    'нежни',
    'нежно',
    'незин',
    'нејак',
    'нејзе',
    'нејќе',
    'нејсе',
    'некни',
    'некое',
    'некои',
    'некој',
    'немаа',
    'немав',
    'немај',
    'немак',
    'немал',
    'немам',
    'немаш',
    'немее',
    'немец',
    'немил',
    'немир',
    'немој',
    'немоќ',
    'немош',
    'немци',
    'немче',
    'неола',
    'неоли',
    'неоло',
    'неона',
    'неоне',
    'неони',
    'неону',
    'неоти',
    'непца',
    'непце',
    'нерва',
    'нерве',
    'нерви',
    'нерву',
    'неред',
    'нерез',
    'несам',
    'несат',
    'несеа',
    'несев',
    'несел',
    'несен',
    'несеш',
    'нетоп',
    'неука',
    'неуки',
    'неуко',
    'нефер',
    'нефта',
    'нефте',
    'нефту',
    'нечие',
    'нечии',
    'нечиј',
    'нешто',
    'нивен',
    'нивна',
    'нивни',
    'нивно',
    'нивоа',
    'нивче',
    'нигде',
    'ниета',
    'ниете',
    'ниети',
    'ниету',
    'нижач',
    'низок',
    'никел',
    'никне',
    'никое',
    'никои',
    'никој',
    'нимфа',
    'нимфи',
    'нимфо',
    'нинџа',
    'нируи',
    'нисии',
    'ниска',
    'ниски',
    'ниско',
    'нитка',
    'нитки',
    'нитко',
    'нитна',
    'ничие',
    'ничии',
    'ничиј',
    'нишаа',
    'нишав',
    'нишај',
    'нишал',
    'нишам',
    'нишан',
    'нишаш',
    'нишка',
    'нишки',
    'нишко',
    'нишне',
    'ништи',
    'ништо',
    'новак',
    'новка',
    'новко',
    'ноеви',
    'ножар',
    'ножен',
    'ножна',
    'ножни',
    'ножно',
    'ножов',
    'ножон',
    'ножот',
    'нозба',
    'нозби',
    'нозбо',
    'нозле',
    'нојов',
    'нојон',
    'нојот',
    'ноќва',
    'ноќви',
    'ноќев',
    'ноќем',
    'ноќен',
    'ноќна',
    'ноќни',
    'ноќно',
    'нокот',
    'нокта',
    'ноќта',
    'нокте',
    'нокти',
    'ноќум',
    'номад',
    'номер',
    'номта',
    'норка',
    'норма',
    'норми',
    'нормо',
    'носам',
    'носат',
    'носач',
    'носеа',
    'носев',
    'носел',
    'носен',
    'носии',
    'носиш',
    'носле',
    'носло',
    'носна',
    'носни',
    'носно',
    'носов',
    'носон',
    'носот',
    'нотар',
    'нотен',
    'нотес',
    'нотка',
    'нотки',
    'нотко',
    'нотна',
    'нотни',
    'нотно',
    'ношви',
    'ношки',
    'нудам',
    'нудат',
    'нудеа',
    'нудев',
    'нудел',
    'нуден',
    'нудиш',
    'нужба',
    'нужби',
    'нужбо',
    'нужда',
    'нужди',
    'нуждо',
    'нужен',
    'нужна',
    'нужни',
    'нужно',
    'нулов',
    'нулта',
    'нулти',
    'нулто',
    'нумка',
    'нумки',
    'нумко',
    'нунка',
    'нунки',
    'нунко',
    'нурец',
    'нурна',
    'нурне',
    'нурни',
    'нурци',
    'нурче',
    'обара',
    'обари',
    'обата',
    'обаца',
    'обвие',
    'обгор',
    'обдуе',
    'обеда',
    'обеде',
    'обеди',
    'обеду',
    'обели',
    'обема',
    'обеме',
    'обеми',
    'обему',
    'обере',
    'обеси',
    'обете',
    'обѕир',
    'обива',
    'обида',
    'обиде',
    'обиди',
    'обиду',
    'обиев',
    'обиел',
    'обиен',
    'обиеш',
    'обија',
    'обила',
    'обиле',
    'обило',
    'обира',
    'обире',
    'обири',
    'обиру',
    'облаг',
    'облак',
    'облее',
    'облие',
    'облик',
    'облог',
    'обоев',
    'обоел',
    'обоен',
    'обоив',
    'обоил',
    'обоиш',
    'обоја',
    'обоју',
    'оболи',
    'обора',
    'оборе',
    'обори',
    'обору',
    'образ',
    'обрат',
    'обрач',
    'обред',
    'обрез',
    'обрне',
    'оброк',
    'обррр',
    'обрта',
    'обрте',
    'обрти',
    'обрту',
    'обрус',
    'обува',
    'обуен',
    'обука',
    'обуки',
    'обуко',
    'обучи',
    'обуша',
    'обуши',
    'обушу',
    'овала',
    'овале',
    'овали',
    'овалу',
    'овега',
    'овена',
    'овене',
    'овени',
    'овену',
    'овери',
    'овеса',
    'овесе',
    'овеси',
    'овесу',
    'оврши',
    'овчар',
    'овчки',
    'огина',
    'огине',
    'огини',
    'огину',
    'оглав',
    'оглас',
    'оглед',
    'огнар',
    'огнен',
    'огнов',
    'огнон',
    'огнот',
    'оголи',
    'огоне',
    'огону',
    'огори',
    'огрди',
    'огреа',
    'огрев',
    'огрее',
    'огреј',
    'одава',
    'одаде',
    'одаја',
    'одајо',
    'одана',
    'одара',
    'одаре',
    'одару',
    'одата',
    'одбив',
    'одбие',
    'одбиј',
    'одбил',
    'одбир',
    'одбои',
    'одбој',
    'одбор',
    'одбра',
    'одвај',
    'одвар',
    'одвеа',
    'одвее',
    'одвеј',
    'одвел',
    'одвет',
    'одвие',
    'одвик',
    'одвод',
    'одвоз',
    'одвои',
    'оддал',
    'оддел',
    'оддив',
    'одева',
    'одека',
    'одеку',
    'одела',
    'оделе',
    'одело',
    'одена',
    'одени',
    'одено',
    'одења',
    'одење',
    'одера',
    'одере',
    'одери',
    'одеру',
    'одете',
    'одеци',
    'одече',
    'одеше',
    'одзад',
    'одзив',
    'одѕив',
    'одиве',
    'одила',
    'одило',
    'одиме',
    'одине',
    'одите',
    'одлее',
    'одлив',
    'одлие',
    'одмор',
    'однел',
    'однос',
    'одови',
    'одоко',
    'одоми',
    'одора',
    'одоре',
    'одору',
    'одраа',
    'одрав',
    'одраз',
    'одрал',
    'одред',
    'одржа',
    'одржи',
    'одрон',
    'одрпа',
    'одука',
    'одуки',
    'одуко',
    'одучи',
    'одуша',
    'одуши',
    'одушо',
    'ожали',
    'ожега',
    'ожегу',
    'ожеже',
    'ожези',
    'ожени',
    'оживи',
    'ожили',
    'ожнеа',
    'ожнее',
    'ожнеј',
    'ожние',
    'ожули',
    'ожури',
    'озари',
    'озоба',
    'озона',
    'озоне',
    'озони',
    'озону',
    'ојари',
    'ојнак',
    'ојнов',
    'ојнон',
    'ојнот',
    'окади',
    'окаен',
    'окаже',
    'окали',
    'окања',
    'окање',
    'окапе',
    'окара',
    'океан',
    'оклоп',
    'окнар',
    'окова',
    'окове',
    'окови',
    'оково',
    'окову',
    'окози',
    'околу',
    'оконо',
    'окопа',
    'окопе',
    'окопи',
    'окопу',
    'оќори',
    'окоси',
    'окоти',
    'окото',
    'окраг',
    'окрек',
    'окрка',
    'окрни',
    'окрои',
    'округ',
    'окрши',
    'оксид',
    'октав',
    'октет',
    'окубе',
    'окука',
    'окучи',
    'олади',
    'олапа',
    'олеин',
    'олеле',
    'олиже',
    'олкав',
    'олова',
    'олово',
    'олока',
    'олтар',
    'олука',
    'олуку',
    'олупи',
    'олути',
    'олуци',
    'олуче',
    'олцне',
    'омаен',
    'омажи',
    'омаја',
    'омају',
    'омака',
    'омали',
    'омаца',
    'омачи',
    'омега',
    'омеги',
    'омеѓи',
    'омего',
    'омете',
    'омечи',
    'омили',
    'омјаз',
    'омлет',
    'омови',
    'омота',
    'омоте',
    'омоти',
    'омоту',
    'омраз',
    'омрси',
    'онади',
    'онака',
    'онаму',
    'онеми',
    'оникс',
    'опаѓа',
    'опако',
    'опаку',
    'опала',
    'опале',
    'опали',
    'опалу',
    'опари',
    'опасе',
    'опата',
    'опате',
    'опати',
    'опату',
    'опаша',
    'опаше',
    'опаши',
    'опашу',
    'опеав',
    'опеал',
    'опеам',
    'опеан',
    'опеат',
    'опеев',
    'опеел',
    'опееш',
    'опела',
    'опело',
    'опера',
    'опере',
    'опери',
    'оперо',
    'опето',
    'опече',
    'опива',
    'опиев',
    'опиел',
    'опиен',
    'опиеш',
    'опија',
    'опила',
    'опиле',
    'опили',
    'опило',
    'опина',
    'опипа',
    'опира',
    'описа',
    'описе',
    'описи',
    'опису',
    'опита',
    'опите',
    'опити',
    'опиту',
    'опиум',
    'опиша',
    'опише',
    'опиши',
    'опков',
    'опкоп',
    'опкрш',
    'оплак',
    'оплен',
    'опнат',
    'опоен',
    'опоја',
    'опоју',
    'опора',
    'опори',
    'опоро',
    'опржи',
    'опрли',
    'опрчи',
    'опсег',
    'опсен',
    'оптек',
    'опток',
    'опула',
    'опуле',
    'опули',
    'опулу',
    'опуса',
    'опусе',
    'опуси',
    'опусу',
    'опута',
    'опути',
    'опуто',
    'опфат',
    'опцуе',
    'опшив',
    'опшие',
    'опшиј',
    'опшил',
    'општа',
    'општи',
    'општо',
    'ораат',
    'орава',
    'ораву',
    'орала',
    'орале',
    'орало',
    'ораме',
    'орана',
    'оране',
    'оранж',
    'орани',
    'орано',
    'орану',
    'орања',
    'орање',
    'ораси',
    'ората',
    'орате',
    'орача',
    'ораче',
    'орачи',
    'орачу',
    'ораше',
    'орган',
    'оргии',
    'орден',
    'ордии',
    'орева',
    'ореве',
    'ореви',
    'ореву',
    'орела',
    'ореле',
    'орелу',
    'орења',
    'орење',
    'ореол',
    'ориза',
    'оризи',
    'оризу',
    'орјат',
    'оркан',
    'орлов',
    'орман',
    'орово',
    'ороза',
    'орози',
    'орозу',
    'ороно',
    'ороси',
    'орото',
    'орочи',
    'ортак',
    'оружа',
    'осава',
    'осами',
    'осана',
    'осата',
    'освем',
    'освен',
    'освои',
    'осврт',
    'осета',
    'осете',
    'осети',
    'осету',
    'осиве',
    'осиви',
    'осила',
    'осиле',
    'осили',
    'осило',
    'осилу',
    'осине',
    'осите',
    'осица',
    'осице',
    'осици',
    'осицо',
    'оскар',
    'ослич',
    'ослон',
    'осмак',
    'основ',
    'осоен',
    'осоја',
    'осоју',
    'осоли',
    'остар',
    'остен',
    'остра',
    'остри',
    'остро',
    'осуда',
    'осуди',
    'осудо',
    'осука',
    'осуче',
    'отаде',
    'отапи',
    'отвор',
    'отели',
    'отепа',
    'отера',
    'отецу',
    'отече',
    'отечи',
    'отиде',
    'отима',
    'отказ',
    'откај',
    'откоп',
    'откос',
    'откри',
    'откуп',
    'отмен',
    'отока',
    'отоку',
    'отоци',
    'оточе',
    'отпад',
    'отпат',
    'отпеа',
    'отпее',
    'отпеј',
    'отпив',
    'отпие',
    'отпиј',
    'отпил',
    'отпис',
    'отпор',
    'отров',
    'отрув',
    'отруе',
    'отруј',
    'отрул',
    'отрча',
    'отсее',
    'отсек',
    'отспа',
    'отсто',
    'отуѓи',
    'отчет',
    'отшие',
    'офука',
    'оцапа',
    'оцеди',
    'оцена',
    'оцени',
    'оцено',
    'оцета',
    'оцете',
    'оцети',
    'оцету',
    'оцрни',
    'оцрта',
    'оцрте',
    'оцрти',
    'оцрту',
    'очаен',
    'очаја',
    'очају',
    'очапи',
    'очево',
    'очено',
    'очето',
    'очеша',
    'очива',
    'очиве',
    'очила',
    'очина',
    'очине',
    'очиња',
    'очита',
    'очите',
    'очник',
    'очува',
    'очуве',
    'очуви',
    'очуву',
    'очуди',
    'очука',
    'очуми',
    'оџава',
    'оџака',
    'оџаку',
    'оџана',
    'оџата',
    'оџаци',
    'оџаче',
    'оџиве',
    'оџине',
    'оџите',
    'ошава',
    'ошаве',
    'ошави',
    'ошаву',
    'оштур',
    'ошути',
    'паала',
    'паале',
    'паали',
    'паалу',
    'пабур',
    'павит',
    'павка',
    'павне',
    'павои',
    'павој',
    'павта',
    'павур',
    'паѓаа',
    'паѓав',
    'паѓај',
    'паѓал',
    'паѓам',
    'паѓаш',
    'пагур',
    'падар',
    'падеж',
    'падна',
    'падне',
    'падни',
    'падов',
    'падон',
    'падот',
    'паеви',
    'паека',
    'паеку',
    'паења',
    'паење',
    'паеци',
    'паече',
    'пажов',
    'пажон',
    'пажот',
    'пазар',
    'пазач',
    'пајак',
    'пајва',
    'пајви',
    'пајво',
    'пајда',
    'пајди',
    'пајдо',
    'пајка',
    'пајки',
    'пајко',
    'пајов',
    'пајон',
    'пајот',
    'пајче',
    'пакет',
    'пакла',
    'пакло',
    'пакта',
    'пакте',
    'пакту',
    'палав',
    'палам',
    'палат',
    'палач',
    'палеа',
    'палев',
    'палеж',
    'палел',
    'пален',
    'палео',
    'палец',
    'палир',
    'палиш',
    'палка',
    'палки',
    'палко',
    'палма',
    'палми',
    'палмо',
    'палне',
    'палта',
    'палто',
    'палци',
    'палче',
    'памет',
    'памти',
    'памук',
    'панаг',
    'панга',
    'панго',
    'панда',
    'панел',
    'паноа',
    'панов',
    'панои',
    'панон',
    'панот',
    'панта',
    'панти',
    'панто',
    'паога',
    'паогу',
    'паоже',
    'паози',
    'папии',
    'папка',
    'папки',
    'папко',
    'папок',
    'папра',
    'папса',
    'папук',
    'папче',
    'параа',
    'парав',
    'парај',
    'парал',
    'парам',
    'парат',
    'параф',
    'параш',
    'пареа',
    'парев',
    'пареи',
    'парел',
    'парен',
    'парео',
    'парии',
    'парип',
    'париш',
    'парка',
    'парку',
    'парна',
    'парне',
    'парни',
    'парно',
    'паров',
    'парог',
    'парон',
    'парот',
    'парох',
    'парса',
    'парси',
    'парсо',
    'парта',
    'парти',
    'парто',
    'парфе',
    'парче',
    'пасаж',
    'пасив',
    'пасии',
    'пасма',
    'пасмо',
    'пасне',
    'пасов',
    'пасон',
    'пасот',
    'пасош',
    'паста',
    'пасти',
    'пасто',
    'пасус',
    'пасха',
    'пасхи',
    'пасхо',
    'патам',
    'патар',
    'патат',
    'патеа',
    'патев',
    'пател',
    'патем',
    'патен',
    'патец',
    'патиш',
    'патје',
    'патка',
    'патки',
    'патко',
    'патла',
    'патли',
    'патло',
    'патна',
    'патни',
    'патно',
    'патов',
    'паток',
    'патон',
    'патор',
    'патос',
    'патот',
    'патци',
    'патче',
    'пауза',
    'паузи',
    'паузо',
    'пауна',
    'пауне',
    'пауни',
    'пауну',
    'паура',
    'пауре',
    'паури',
    'пауру',
    'пафта',
    'пафти',
    'пафто',
    'пачем',
    'пачка',
    'пачки',
    'пачко',
    'пашин',
    'пашит',
    'пашка',
    'пашки',
    'пашко',
    'пашов',
    'пеана',
    'пеани',
    'пеано',
    'певец',
    'певци',
    'певче',
    'пегла',
    'пегли',
    'пегло',
    'педал',
    'педер',
    'пееја',
    'пеела',
    'пееле',
    'пеело',
    'пееме',
    'пеења',
    'пеење',
    'пеете',
    'пееше',
    'пејач',
    'пејов',
    'пејон',
    'пејот',
    'пејте',
    'пекар',
    'пекла',
    'пекле',
    'пекло',
    'пекоа',
    'пеков',
    'пекол',
    'пекон',
    'пекот',
    'пелин',
    'пелир',
    'пелте',
    'пелца',
    'пелце',
    'пелцу',
    'пељте',
    'пељуш',
    'пембе',
    'пенал',
    'пенис',
    'пенов',
    'пенон',
    'пенот',
    'пента',
    'пенче',
    'пенџе',
    'пепел',
    'пепон',
    'пепун',
    'перам',
    'перат',
    'перач',
    'перде',
    'переа',
    'перев',
    'перел',
    'перен',
    'переш',
    'периз',
    'перја',
    'перје',
    'перка',
    'перки',
    'перко',
    'перла',
    'перли',
    'перло',
    'перне',
    'перов',
    'перон',
    'перот',
    'перца',
    'перце',
    'перче',
    'перша',
    'перши',
    'першо',
    'песји',
    'песма',
    'песми',
    'песмо',
    'песна',
    'песни',
    'песно',
    'песов',
    'песок',
    'песон',
    'песот',
    'песта',
    'пести',
    'песто',
    'петак',
    'петар',
    'петве',
    'петел',
    'петен',
    'петик',
    'петит',
    'петка',
    'петки',
    'петко',
    'петле',
    'петли',
    'петна',
    'петне',
    'петно',
    'петок',
    'петта',
    'петте',
    'петти',
    'петто',
    'пехар',
    'пехер',
    'пецка',
    'пецне',
    'печал',
    'печам',
    'печат',
    'печеа',
    'печев',
    'печел',
    'печен',
    'печеш',
    'печка',
    'печки',
    'печко',
    'печок',
    'пешак',
    'пешки',
    'пешов',
    'пешон',
    'пешот',
    'пешта',
    'пеште',
    'пешти',
    'пешту',
    'пивар',
    'пивка',
    'пивко',
    'пивне',
    'пивов',
    'пивон',
    'пивот',
    'пивца',
    'пивце',
    'пиеја',
    'пиела',
    'пиеле',
    'пиело',
    'пиеме',
    'пиена',
    'пиени',
    'пиено',
    'пиења',
    'пиење',
    'пиеса',
    'пиеси',
    'пиесо',
    'пиете',
    'пиеше',
    'пизма',
    'пизми',
    'пизмо',
    'пијам',
    'пијан',
    'пијат',
    'пијач',
    'пијор',
    'пијте',
    'пикап',
    'пикет',
    'пикне',
    'пиков',
    'пикон',
    'пикот',
    'пилав',
    'пилаф',
    'пилот',
    'пилци',
    'пиљав',
    'пинии',
    'пинче',
    'пиона',
    'пионе',
    'пиони',
    'пиону',
    'пипаа',
    'пипав',
    'пипај',
    'пипал',
    'пипам',
    'пипан',
    'пипаш',
    'пипер',
    'пипка',
    'пипки',
    'пипко',
    'пипне',
    'пипов',
    'пипон',
    'пипот',
    'пират',
    'пирга',
    'пиргу',
    'пиреи',
    'пиреј',
    'пирже',
    'пирит',
    'пирла',
    'пирле',
    'пирли',
    'пирло',
    'пиров',
    'пирон',
    'пирот',
    'писан',
    'писар',
    'писец',
    'писка',
    'писки',
    'писко',
    'писма',
    'писмо',
    'писне',
    'писок',
    'писта',
    'писти',
    'писто',
    'писци',
    'питар',
    'питач',
    'питие',
    'питка',
    'питки',
    'питко',
    'питок',
    'питом',
    'питон',
    'пичка',
    'пишал',
    'пишан',
    'пишии',
    'пишка',
    'пишти',
    'пјата',
    'пјате',
    'пјато',
    'пјату',
    'плава',
    'плави',
    'плаво',
    'плажа',
    'плажи',
    'плажо',
    'плаза',
    'плази',
    'плазу',
    'плака',
    'плаќа',
    'плаки',
    'плако',
    'плаку',
    'плама',
    'пламе',
    'пламу',
    'плана',
    'плане',
    'плану',
    'пласт',
    'плата',
    'плате',
    'плати',
    'плато',
    'плату',
    'плаца',
    'плаце',
    'плацу',
    'плача',
    'плаче',
    'плачи',
    'плачу',
    'плаши',
    'плашт',
    'плева',
    'плеви',
    'плево',
    'плеќи',
    'племе',
    'плена',
    'плене',
    'плени',
    'плену',
    'плета',
    'плете',
    'плети',
    'плету',
    'плеха',
    'плеху',
    'плива',
    'плика',
    'плико',
    'плику',
    'плима',
    'плими',
    'плимо',
    'плисе',
    'плита',
    'плите',
    'плиту',
    'пличе',
    'плиша',
    'плишу',
    'плови',
    'плода',
    'плоде',
    'плоди',
    'плоду',
    'плота',
    'плоте',
    'плоту',
    'плоча',
    'плоче',
    'плочи',
    'плочо',
    'плошт',
    'плуга',
    'плугу',
    'плуже',
    'плука',
    'плуса',
    'плусу',
    'плута',
    'плути',
    'плуто',
    'пљага',
    'пљаги',
    'пљаго',
    'пљака',
    'пљаки',
    'пљако',
    'поаѓа',
    'побел',
    'побив',
    'побие',
    'побиј',
    'побил',
    'побрз',
    'повев',
    'повее',
    'повез',
    'повеи',
    'повеј',
    'повел',
    'повие',
    'повиж',
    'повик',
    'повит',
    'повод',
    'повои',
    'повој',
    'повта',
    'повте',
    'повти',
    'повто',
    'поган',
    'погне',
    'погон',
    'погрд',
    'подал',
    'подби',
    'подем',
    'поден',
    'подза',
    'подив',
    'подиз',
    'подис',
    'подла',
    'подли',
    'подло',
    'подов',
    'подои',
    'подол',
    'подон',
    'подот',
    'подуе',
    'поеви',
    'поема',
    'поеми',
    'поемо',
    'поена',
    'поене',
    'поени',
    'поену',
    'поења',
    'поење',
    'поета',
    'поете',
    'поети',
    'поету',
    'поечи',
    'пожар',
    'пожив',
    'позен',
    'позер',
    'позив',
    'позла',
    'позли',
    'позло',
    'позно',
    'позол',
    'поика',
    'поила',
    'поило',
    'поима',
    'поиме',
    'поими',
    'поиму',
    'поита',
    'појак',
    'појас',
    'појач',
    'појде',
    'појди',
    'појми',
    'појов',
    'појон',
    'појот',
    'покае',
    'показ',
    'покај',
    'покер',
    'покои',
    'покој',
    'покор',
    'покос',
    'покри',
    'покус',
    'полае',
    'полва',
    'полве',
    'полву',
    'полее',
    'полен',
    'полет',
    'полза',
    'ползи',
    'ползо',
    'полие',
    'полип',
    'полиц',
    'полја',
    'полка',
    'полки',
    'полко',
    'полку',
    'полна',
    'полни',
    'полно',
    'полов',
    'полог',
    'полон',
    'полот',
    'полош',
    'полти',
    'полуд',
    'полус',
    'полут',
    'полче',
    'пољка',
    'пољки',
    'пољко',
    'помак',
    'помал',
    'помек',
    'помен',
    'помии',
    'помил',
    'помин',
    'помни',
    'помои',
    'помоќ',
    'помор',
    'помош',
    'помпа',
    'помпи',
    'помпо',
    'понел',
    'понов',
    'понор',
    'пооди',
    'поора',
    'попаи',
    'попее',
    'попец',
    'попие',
    'попис',
    'попов',
    'попок',
    'попон',
    'попот',
    'попрв',
    'попци',
    'попче',
    'пораб',
    'пораз',
    'поран',
    'порач',
    'поред',
    'порез',
    'порек',
    'порив',
    'поров',
    'пород',
    'порои',
    'порој',
    'порок',
    'порон',
    'порот',
    'порта',
    'порте',
    'порти',
    'порто',
    'посак',
    'посев',
    'посег',
    'посед',
    'посее',
    'посен',
    'посер',
    'посин',
    'после',
    'посоп',
    'поспа',
    'поста',
    'посте',
    'пости',
    'посто',
    'посту',
    'посув',
    'потаи',
    'потај',
    'потам',
    'потап',
    'потат',
    'потва',
    'потеа',
    'потев',
    'потег',
    'потез',
    'потел',
    'потем',
    'потен',
    'потир',
    'потих',
    'потиш',
    'потка',
    'потки',
    'потко',
    'потна',
    'потни',
    'потно',
    'потоа',
    'поток',
    'потон',
    'потоп',
    'потта',
    'потур',
    'поука',
    'поуки',
    'поуко',
    'поуку',
    'поули',
    'поуци',
    'поуче',
    'поучи',
    'пофат',
    'пофин',
    'поход',
    'поцрн',
    'почар',
    'почва',
    'почви',
    'почво',
    'почек',
    'почин',
    'почит',
    'почна',
    'почне',
    'почни',
    'почуе',
    'пошие',
    'пошла',
    'пошле',
    'пошло',
    'пошол',
    'пошта',
    'пошти',
    'пошто',
    'права',
    'праве',
    'прави',
    'право',
    'праву',
    'прага',
    'прагу',
    'праже',
    'праза',
    'празе',
    'празу',
    'праќа',
    'праќе',
    'прања',
    'прање',
    'прасе',
    'праси',
    'прата',
    'прате',
    'прати',
    'прату',
    'праун',
    'праху',
    'праче',
    'праша',
    'праши',
    'првак',
    'првен',
    'првин',
    'првка',
    'првне',
    'првут',
    'пргав',
    'прдеж',
    'прдла',
    'прдли',
    'прдло',
    'прдне',
    'прега',
    'преѓа',
    'преѓе',
    'преѓи',
    'преѓо',
    'преде',
    'преку',
    'прела',
    'прело',
    'преод',
    'препи',
    'преса',
    'преси',
    'пресо',
    'прета',
    'пречи',
    'пржам',
    'пржат',
    'пржеа',
    'пржев',
    'пржел',
    'пржен',
    'пржиш',
    'пржол',
    'прида',
    'приде',
    'приду',
    'прием',
    'приет',
    'приќа',
    'приќе',
    'приќи',
    'приќо',
    'прима',
    'приме',
    'прими',
    'приму',
    'принц',
    'приод',
    'припи',
    'прита',
    'прите',
    'приту',
    'приши',
    'пришт',
    'пркне',
    'пркос',
    'прлок',
    'прнар',
    'прњар',
    'проба',
    'проби',
    'пробо',
    'проза',
    'прози',
    'прозо',
    'проод',
    'пропи',
    'проса',
    'проси',
    'просо',
    'прост',
    'прота',
    'проти',
    'прото',
    'прпма',
    'прпми',
    'прпмо',
    'прпне',
    'прпот',
    'прпул',
    'прсен',
    'прска',
    'прсне',
    'прста',
    'прсте',
    'прсти',
    'прсту',
    'пртен',
    'пртов',
    'пртон',
    'пртот',
    'пруга',
    'пруги',
    'пруго',
    'пружи',
    'пруса',
    'прцел',
    'прцле',
    'прцли',
    'прчка',
    'прчки',
    'прчко',
    'прчла',
    'прчов',
    'прчон',
    'прчот',
    'пршаи',
    'пршај',
    'пршка',
    'пршки',
    'пршко',
    'псала',
    'псалм',
    'псалт',
    'псета',
    'психа',
    'психи',
    'психо',
    'псост',
    'птица',
    'птици',
    'птицо',
    'птиче',
    'пувеж',
    'пувка',
    'пувки',
    'пувко',
    'пувла',
    'пувли',
    'пувло',
    'пувне',
    'пувче',
    'пудер',
    'пудла',
    'пудле',
    'пудли',
    'пудло',
    'пудлу',
    'пудра',
    'пудри',
    'пудро',
    'пујка',
    'пујки',
    'пујко',
    'пујче',
    'пукаа',
    'пукав',
    'пукај',
    'пукал',
    'пукам',
    'пукан',
    'пукаш',
    'пукне',
    'пукот',
    'пулен',
    'пулка',
    'пулки',
    'пулко',
    'пулов',
    'пулон',
    'пулот',
    'пулпа',
    'пулса',
    'пулсу',
    'пулта',
    'пулте',
    'пулту',
    'пулче',
    'пумпа',
    'пумпи',
    'пумпо',
    'пункт',
    'пунча',
    'пунче',
    'пунчу',
    'пунџа',
    'пунша',
    'пуншу',
    'пупам',
    'пупат',
    'пупеа',
    'пупев',
    'пупел',
    'пупен',
    'пупиш',
    'пупка',
    'пупки',
    'пупко',
    'пупун',
    'пупче',
    'пупчи',
    'пурде',
    'пусат',
    'пусии',
    'пуста',
    'пусти',
    'пусто',
    'путер',
    'путир',
    'путка',
    'пуфка',
    'пуфла',
    'пучов',
    'пучон',
    'пучот',
    'пушам',
    'пушат',
    'пушач',
    'пушеа',
    'пушев',
    'пушел',
    'пушен',
    'пушиш',
    'пушка',
    'пушки',
    'пушко',
    'пушта',
    'пуште',
    'пушти',
    'пушту',
    'пцала',
    'пцалт',
    'пцета',
    'пциве',
    'пцине',
    'пците',
    'пцост',
    'пцуев',
    'пцуел',
    'пцуен',
    'пцуеш',
    'пчела',
    'пчели',
    'пчело',
    'раата',
    'раате',
    'раати',
    'раату',
    'рабат',
    'рабен',
    'рабин',
    'рабов',
    'рабон',
    'работ',
    'рабул',
    'рабуш',
    'раван',
    'равен',
    'раѓаа',
    'раѓав',
    'раѓај',
    'раѓал',
    'раѓам',
    'раѓаш',
    'рагби',
    'рагуа',
    'радар',
    'радио',
    'раеви',
    'ражен',
    'ражне',
    'разби',
    'разви',
    'разен',
    'разна',
    'разни',
    'разно',
    'разор',
    'разум',
    'раиве',
    'раине',
    'раите',
    'рајов',
    'рајон',
    'рајот',
    'ракав',
    'ракии',
    'ракла',
    'ракли',
    'ракло',
    'раков',
    'ракол',
    'ракон',
    'ракот',
    'ракун',
    'ракче',
    'ралца',
    'рамен',
    'рамка',
    'рамки',
    'рамко',
    'рамна',
    'рамни',
    'рамно',
    'рамов',
    'рамон',
    'рамот',
    'рампа',
    'рампи',
    'рампо',
    'ранак',
    'ранга',
    'рангу',
    'ранет',
    'ранец',
    'ранка',
    'ранко',
    'ранци',
    'ранче',
    'рапав',
    'рапер',
    'рапир',
    'рапче',
    'расад',
    'расед',
    'расее',
    'расек',
    'расен',
    'расип',
    'раска',
    'раски',
    'раско',
    'расна',
    'расне',
    'расни',
    'расно',
    'расов',
    'расол',
    'расте',
    'расти',
    'расту',
    'ратаи',
    'ратај',
    'ратеи',
    'ратеј',
    'ратов',
    'ратон',
    'ратот',
    'рауна',
    'рауне',
    'рауни',
    'рауну',
    'рафал',
    'рафии',
    'рафта',
    'рафте',
    'рафту',
    'рахат',
    'рации',
    'рацио',
    'рачен',
    'рачец',
    'рачка',
    'рачки',
    'рачко',
    'рачна',
    'рачни',
    'рачно',
    'рачци',
    'рашие',
    'рашка',
    'рашки',
    'рашко',
    'рѓава',
    'рѓана',
    'рѓата',
    'реава',
    'реала',
    'реале',
    'реали',
    'реалу',
    'реана',
    'реата',
    'ребра',
    'ребро',
    'ребус',
    'ревен',
    'ревер',
    'ревии',
    'ревир',
    'ревма',
    'ревне',
    'ревов',
    'ревон',
    'ревот',
    'регал',
    'регии',
    'редам',
    'редар',
    'редат',
    'редба',
    'редби',
    'редбо',
    'редеа',
    'редев',
    'редеж',
    'редел',
    'реден',
    'редиф',
    'редиш',
    'редна',
    'редни',
    'редно',
    'редов',
    'редок',
    'редон',
    'редот',
    'редум',
    'реења',
    'реење',
    'режам',
    'режан',
    'режат',
    'режач',
    'режеа',
    'режев',
    'режел',
    'режен',
    'режеш',
    'режии',
    'режим',
    'резач',
    'резба',
    'резби',
    'резбо',
    'резен',
    'резец',
    'резил',
    'резне',
    'резов',
    'резок',
    'резон',
    'резот',
    'реиве',
    'реима',
    'реиме',
    'реими',
    'реиму',
    'реине',
    'реиса',
    'реисе',
    'реиси',
    'реису',
    'реите',
    'рејса',
    'рејсу',
    'рекет',
    'рекла',
    'рекле',
    'рекло',
    'рекоа',
    'реков',
    'рекол',
    'релеа',
    'релеи',
    'релеј',
    'релса',
    'релси',
    'релсо',
    'ремен',
    'ремче',
    'ренда',
    'ренде',
    'ренка',
    'ренку',
    'рента',
    'ренти',
    'ренто',
    'ренче',
    'реона',
    'реоне',
    'реони',
    'реону',
    'репај',
    'репер',
    'репка',
    'репки',
    'репко',
    'репне',
    'рерна',
    'рерни',
    'рерно',
    'ресав',
    'ресен',
    'реска',
    'рески',
    'реско',
    'ресне',
    'ресор',
    'реста',
    'ресто',
    'ресци',
    'ретка',
    'ретки',
    'ретко',
    'ретор',
    'ретро',
    'ретур',
    'ретуш',
    'ретче',
    'ретчи',
    'реума',
    'реуми',
    'реумо',
    'рефус',
    'рецес',
    'рецка',
    'рецки',
    'рецко',
    'рецне',
    'речам',
    'речат',
    'речеа',
    'речев',
    'речел',
    'речен',
    'речеш',
    'речит',
    'речна',
    'речни',
    'речно',
    'речов',
    'речта',
    'реџба',
    'реџби',
    'реџбо',
    'решам',
    'решат',
    'решеа',
    'решев',
    'решел',
    'решен',
    'решиш',
    'решка',
    'решки',
    'решко',
    'решме',
    'решоа',
    'ржела',
    'ржеле',
    'ржело',
    'ржења',
    'ржење',
    'ржете',
    'ржеше',
    'ржиме',
    'ржите',
    'рибар',
    'рибен',
    'рибин',
    'рибји',
    'рибна',
    'рибни',
    'рибно',
    'рибон',
    'ривал',
    'риден',
    'ридје',
    'ридов',
    'ридон',
    'ридот',
    'риеја',
    'риела',
    'риеле',
    'риело',
    'риеме',
    'риења',
    'риење',
    'риете',
    'риеше',
    'ризик',
    'ризом',
    'ријам',
    'ријат',
    'ријте',
    'рикне',
    'риков',
    'рикон',
    'рикот',
    'рикша',
    'рикши',
    'рикшо',
    'рилач',
    'рилка',
    'рилки',
    'рилко',
    'рилца',
    'рилце',
    'ринга',
    'рингу',
    'ринка',
    'ринта',
    'рипаа',
    'рипав',
    'рипај',
    'рипал',
    'рипам',
    'рипач',
    'рипаш',
    'рипка',
    'рипки',
    'рипко',
    'рипне',
    'рипса',
    'рипсу',
    'рипче',
    'риска',
    'риску',
    'рисов',
    'рисон',
    'рисот',
    'рисче',
    'ритам',
    'ритер',
    'ритми',
    'ритче',
    'рицар',
    'ричка',
    'рички',
    'ричко',
    'робии',
    'робја',
    'робје',
    'робов',
    'робон',
    'робот',
    'ровит',
    'ровја',
    'ровји',
    'ровјо',
    'ровов',
    'ровок',
    'ровон',
    'ровот',
    'ровче',
    'роган',
    'рогат',
    'рогач',
    'рогле',
    'рогне',
    'рогов',
    'рогоз',
    'рогон',
    'рогот',
    'родам',
    'родан',
    'родат',
    'родеа',
    'родев',
    'родел',
    'роден',
    'родео',
    'родив',
    'родил',
    'родиш',
    'родна',
    'родни',
    'родно',
    'родов',
    'родон',
    'родот',
    'родум',
    'роеви',
    'роења',
    'роење',
    'рожба',
    'рожби',
    'рожбо',
    'рожен',
    'розга',
    'розев',
    'розов',
    'ројак',
    'ројов',
    'ројон',
    'ројот',
    'ројче',
    'рокер',
    'роков',
    'рокон',
    'рокот',
    'рокче',
    'ролат',
    'ролја',
    'ролји',
    'ролјо',
    'ролка',
    'ролна',
    'ролни',
    'ролно',
    'ромак',
    'роман',
    'ромба',
    'ромбе',
    'ромби',
    'ромбу',
    'ромов',
    'ромол',
    'ромон',
    'ромор',
    'ромот',
    'ронам',
    'ронат',
    'ронда',
    'рондо',
    'ронеа',
    'ронев',
    'ронел',
    'рониш',
    'ронка',
    'ронки',
    'ронко',
    'ропот',
    'ропти',
    'ропче',
    'росам',
    'росат',
    'росеа',
    'росев',
    'росел',
    'росен',
    'росиш',
    'роска',
    'росна',
    'росни',
    'росно',
    'ротор',
    'рофја',
    'рофји',
    'рофјо',
    'рошав',
    'рошка',
    'рошне',
    'рубии',
    'рубин',
    'рубља',
    'рубљи',
    'рубљо',
    'рувет',
    'рудар',
    'руден',
    'рудна',
    'рудни',
    'рудно',
    'руеви',
    'руења',
    'руење',
    'ружин',
    'ружје',
    'ружов',
    'ружон',
    'ружот',
    'руиве',
    'руина',
    'руине',
    'руини',
    'руино',
    'руите',
    'рујов',
    'рујон',
    'рујот',
    'рулек',
    'рулет',
    'рулец',
    'рулци',
    'рулче',
    'румба',
    'румби',
    'румбо',
    'румен',
    'румов',
    'румон',
    'румот',
    'рунда',
    'рунди',
    'рундо',
    'рунца',
    'рунце',
    'рупии',
    'рупка',
    'рупче',
    'русав',
    'русан',
    'русее',
    'русин',
    'руска',
    'руфет',
    'ручаа',
    'ручав',
    'ручај',
    'ручал',
    'ручам',
    'ручан',
    'ручаш',
    'ручек',
    'ручка',
    'ручок',
    'рушам',
    'рушат',
    'рушеа',
    'рушев',
    'рушел',
    'рушен',
    'рушиш',
    'рчела',
    'рчеле',
    'рчело',
    'рчена',
    'рчени',
    'рчено',
    'рчења',
    'рчење',
    'рчете',
    'рчеше',
    'рчиме',
    'рчите',
    'саава',
    'саана',
    'саане',
    'саани',
    'саану',
    'саата',
    'саате',
    'саати',
    'саату',
    'сабар',
    'сабја',
    'сабји',
    'сабјо',
    'савак',
    'саван',
    'сават',
    'саѓав',
    'сагии',
    'садам',
    'садат',
    'садач',
    'садеа',
    'садев',
    'садел',
    'саден',
    'садиш',
    'садна',
    'садно',
    'садов',
    'садон',
    'садот',
    'саема',
    'саеме',
    'саеми',
    'саему',
    'сажен',
    'сажни',
    'сазов',
    'сазон',
    'сазот',
    'саиве',
    'саине',
    'саите',
    'саиче',
    'сајла',
    'сајли',
    'сајло',
    'сакаа',
    'сакав',
    'саказ',
    'сакај',
    'сакал',
    'сакам',
    'сакан',
    'сакат',
    'сакаш',
    'сакма',
    'сакми',
    'сакмо',
    'сакоа',
    'саков',
    'сакон',
    'сакот',
    'салам',
    'салаш',
    'салва',
    'салви',
    'салво',
    'салда',
    'салдо',
    'салеп',
    'салма',
    'салми',
    'салмо',
    'салон',
    'салта',
    'салте',
    'салто',
    'салут',
    'салца',
    'салце',
    'салци',
    'салцо',
    'самак',
    'самар',
    'самба',
    'самби',
    'самбо',
    'самее',
    'самец',
    'самка',
    'самки',
    'самко',
    'самне',
    'самти',
    'самум',
    'самур',
    'самци',
    'самче',
    'санка',
    'санки',
    'санко',
    'санов',
    'саноќ',
    'санон',
    'санот',
    'санса',
    'сансу',
    'санта',
    'санти',
    'сапов',
    'сапон',
    'сапот',
    'сапун',
    'сараи',
    'сарај',
    'сарак',
    'сараф',
    'сарач',
    'сарма',
    'сарми',
    'сармо',
    'сарош',
    'саска',
    'сатар',
    'сатен',
    'сатир',
    'сатор',
    'сауна',
    'сауни',
    'сауно',
    'сафир',
    'сафра',
    'сафри',
    'сафро',
    'сафта',
    'сафте',
    'сафту',
    'сафче',
    'сачка',
    'сачки',
    'сачко',
    'сачма',
    'сачми',
    'сачмо',
    'сачов',
    'сачон',
    'сачот',
    'саџак',
    'сваќа',
    'сваќе',
    'сваќи',
    'сваќо',
    'свари',
    'свата',
    'свате',
    'свату',
    'сведе',
    'сведи',
    'свежа',
    'свежи',
    'свежо',
    'свеќа',
    'свеќи',
    'свеќо',
    'свела',
    'свеле',
    'свело',
    'свене',
    'свеси',
    'свест',
    'света',
    'свете',
    'свети',
    'свето',
    'свету',
    'свива',
    'свиди',
    'свиев',
    'свиел',
    'свиеш',
    'свија',
    'свика',
    'свила',
    'свиле',
    'свили',
    'свило',
    'свинг',
    'свиња',
    'свињи',
    'свињо',
    'свиок',
    'свири',
    'свита',
    'свити',
    'свито',
    'свлак',
    'свлек',
    'свода',
    'своде',
    'своду',
    'своја',
    'сврат',
    'свржи',
    'сврза',
    'сврзе',
    'сврзи',
    'сврти',
    'сврши',
    'сеана',
    'сеани',
    'сеано',
    'сеача',
    'сеаче',
    'сеачи',
    'сеачу',
    'себап',
    'себеп',
    'севап',
    'севда',
    'севди',
    'севдо',
    'север',
    'севте',
    'сегде',
    'сегне',
    'седав',
    'седам',
    'седат',
    'седба',
    'седби',
    'седбо',
    'седеа',
    'седев',
    'седел',
    'седеф',
    'седиш',
    'седла',
    'седло',
    'седма',
    'седми',
    'седмо',
    'седна',
    'седне',
    'седни',
    'седум',
    'сееја',
    'сеела',
    'сееле',
    'сеело',
    'сееме',
    'сеења',
    'сеење',
    'сеете',
    'сееше',
    'сеиза',
    'сеизи',
    'сеизу',
    'сеира',
    'сеире',
    'сеири',
    'сеиру',
    'сејад',
    'сејач',
    'сејте',
    'секал',
    'секач',
    'секна',
    'секне',
    'секни',
    'секов',
    'секое',
    'секои',
    'секој',
    'секон',
    'секот',
    'секса',
    'секси',
    'сексу',
    'секта',
    'секти',
    'секто',
    'селак',
    'селам',
    'селат',
    'селеа',
    'селев',
    'селел',
    'селен',
    'селиш',
    'селца',
    'селце',
    'семен',
    'семир',
    'семка',
    'семки',
    'семко',
    'семоќ',
    'семче',
    'сенам',
    'сенар',
    'сенат',
    'сенеа',
    'сенев',
    'сенел',
    'сенет',
    'сениш',
    'сенка',
    'сенки',
    'сенко',
    'сента',
    'сенфа',
    'сенфе',
    'сенфу',
    'сенца',
    'сенце',
    'сенче',
    'сенчи',
    'сепак',
    'сепет',
    'сепии',
    'сепне',
    'сепса',
    'сепси',
    'сепсо',
    'серај',
    'серва',
    'сереи',
    'сереј',
    'серии',
    'серко',
    'серов',
    'серон',
    'серот',
    'серум',
    'сесии',
    'сетне',
    'сетов',
    'сетон',
    'сетот',
    'сетре',
    'сефов',
    'сефон',
    'сефот',
    'сефте',
    'сечам',
    'сечат',
    'сечач',
    'сечеа',
    'сечев',
    'сечел',
    'сечен',
    'сечеш',
    'сечие',
    'сечии',
    'сечиј',
    'сечка',
    'сечко',
    'сечта',
    'сешто',
    'сивак',
    'сивее',
    'сивец',
    'сивка',
    'сивки',
    'сивко',
    'сивчо',
    'сигла',
    'сигли',
    'сигло',
    'сигма',
    'сигур',
    'сидра',
    'сидри',
    'сидро',
    'сижеа',
    'сижет',
    'силав',
    'силач',
    'силен',
    'силна',
    'силни',
    'силно',
    'силос',
    'силум',
    'симид',
    'симит',
    'симна',
    'симне',
    'симни',
    'синап',
    'сингл',
    'синее',
    'синии',
    'синко',
    'синов',
    'синод',
    'синон',
    'синор',
    'синот',
    'синус',
    'синци',
    'синче',
    'сињак',
    'сињор',
    'сипак',
    'сипка',
    'сипки',
    'сипко',
    'сипов',
    'сипон',
    'сипот',
    'сипче',
    'сирак',
    'сирен',
    'сиреч',
    'сиров',
    'сирон',
    'сирот',
    'сируп',
    'сирче',
    'ситар',
    'ситен',
    'ситна',
    'ситни',
    'ситно',
    'ситца',
    'ситце',
    'сифон',
    'сиџим',
    'сјаам',
    'сјаат',
    'сјаев',
    'сјаел',
    'сјаен',
    'сјаеш',
    'сјаја',
    'сјају',
    'сјури',
    'сказа',
    'сказе',
    'сказу',
    'скака',
    'скала',
    'скали',
    'скало',
    'скалп',
    'скама',
    'сками',
    'скамо',
    'скапа',
    'скапи',
    'скапо',
    'скара',
    'скари',
    'скаро',
    'скаут',
    'сквер',
    'скела',
    'скеле',
    'скели',
    'скело',
    'скеча',
    'скече',
    'скечу',
    'скија',
    'скијо',
    'скина',
    'скине',
    'скини',
    'скипи',
    'скита',
    'скифа',
    'скифе',
    'скифу',
    'скица',
    'скици',
    'скицо',
    'склав',
    'склад',
    'склап',
    'склон',
    'склоп',
    'скова',
    'скове',
    'скока',
    'скоку',
    'скопа',
    'скопи',
    'скорб',
    'скоро',
    'скоси',
    'скота',
    'скоте',
    'скоту',
    'скоче',
    'скрби',
    'скрди',
    'скреж',
    'скрже',
    'скрие',
    'скрит',
    'скрка',
    'скрки',
    'скрко',
    'скрна',
    'скрни',
    'скрно',
    'скроб',
    'скрои',
    'скрпи',
    'скрум',
    'скрца',
    'скрши',
    'скубе',
    'скуси',
    'скута',
    'скуте',
    'скути',
    'скуто',
    'скуту',
    'скуша',
    'скуши',
    'скушо',
    'слаба',
    'слаби',
    'слабо',
    'слава',
    'слави',
    'славо',
    'слага',
    'слада',
    'сладе',
    'слади',
    'сладу',
    'слајд',
    'слама',
    'слами',
    'сламо',
    'слана',
    'слани',
    'слано',
    'слапа',
    'слапе',
    'слапу',
    'сласт',
    'слача',
    'слаче',
    'слачу',
    'слеан',
    'слева',
    'следа',
    'следи',
    'следо',
    'слеза',
    'слезе',
    'слезу',
    'слеме',
    'сленг',
    'слепа',
    'слепи',
    'слепо',
    'слета',
    'слете',
    'слету',
    'слече',
    'слива',
    'сливе',
    'сливу',
    'слика',
    'слики',
    'слико',
    'слику',
    'слина',
    'сличе',
    'слова',
    'слово',
    'слога',
    'слоги',
    'слого',
    'слогу',
    'сложе',
    'сложи',
    'слоја',
    'слоју',
    'слома',
    'сломе',
    'слому',
    'слона',
    'слоне',
    'слону',
    'слота',
    'слоча',
    'слоче',
    'слочу',
    'слуга',
    'слуги',
    'слуго',
    'служи',
    'слупи',
    'слути',
    'слуха',
    'слуху',
    'случи',
    'слуша',
    'смада',
    'смаде',
    'смади',
    'смаду',
    'смали',
    'смати',
    'смеам',
    'смеат',
    'смева',
    'смеве',
    'смеву',
    'смеев',
    'смеел',
    'смееш',
    'смеќа',
    'смеќи',
    'смеќо',
    'смела',
    'смели',
    'смело',
    'смена',
    'смени',
    'смено',
    'смеса',
    'смеси',
    'смесо',
    'смета',
    'смете',
    'смету',
    'смеша',
    'смила',
    'смиле',
    'смили',
    'смилу',
    'смири',
    'смога',
    'смогу',
    'сможе',
    'смока',
    'смоку',
    'смола',
    'смоли',
    'смоло',
    'смота',
    'смоци',
    'смрѓа',
    'смрѓи',
    'смрѓо',
    'смрда',
    'смрди',
    'смрка',
    'смрси',
    'смрти',
    'смука',
    'смуку',
    'смута',
    'смуте',
    'смути',
    'смуту',
    'смуче',
    'снаби',
    'снага',
    'снаги',
    'снаго',
    'снаин',
    'снега',
    'снегу',
    'снеже',
    'снежи',
    'снела',
    'снеле',
    'снело',
    'снема',
    'снесе',
    'снеси',
    'снижи',
    'снима',
    'сними',
    'сноба',
    'снобе',
    'снобу',
    'снове',
    'сноќи',
    'снопа',
    'снопе',
    'снопи',
    'снопу',
    'сноси',
    'соава',
    'соана',
    'соаре',
    'соата',
    'собар',
    'собен',
    'собер',
    'собир',
    'собна',
    'собни',
    'собно',
    'собор',
    'собра',
    'собуе',
    'совет',
    'соеви',
    'созба',
    'созби',
    'созбо',
    'соиве',
    'соине',
    'соите',
    'сојка',
    'сојки',
    'сојко',
    'сојов',
    'сојон',
    'сојот',
    'сојуз',
    'сокаи',
    'сокај',
    'сокак',
    'сокле',
    'сокли',
    'соков',
    'сокол',
    'сокон',
    'сокот',
    'сокри',
    'сокта',
    'сокче',
    'солар',
    'солба',
    'солби',
    'солбо',
    'солва',
    'солен',
    'солза',
    'солзи',
    'солзо',
    'солна',
    'солни',
    'солно',
    'солта',
    'солук',
    'солца',
    'сомле',
    'сомов',
    'сомон',
    'сомот',
    'сомун',
    'сомче',
    'сонда',
    'сонди',
    'сондо',
    'сонет',
    'сонов',
    'сонок',
    'сонон',
    'сонот',
    'сонца',
    'сонце',
    'сонча',
    'сонче',
    'соочи',
    'сопка',
    'сопки',
    'сопко',
    'сопна',
    'сопне',
    'сопни',
    'сопот',
    'сопре',
    'сопри',
    'сопче',
    'соров',
    'сорон',
    'сорот',
    'сорта',
    'сорти',
    'сорто',
    'сорче',
    'сосед',
    'сосем',
    'сосов',
    'сосон',
    'сосот',
    'соспа',
    'соспи',
    'соспо',
    'сотов',
    'сотон',
    'сотот',
    'сотре',
    'софра',
    'софри',
    'софро',
    'софта',
    'софти',
    'софто',
    'сочен',
    'сошив',
    'сошие',
    'сошиј',
    'сошил',
    'спаѓа',
    'спази',
    'спаии',
    'спала',
    'спале',
    'спали',
    'спало',
    'спања',
    'спање',
    'спари',
    'спаса',
    'спаси',
    'спасу',
    'спева',
    'спеве',
    'спеву',
    'спеца',
    'спеце',
    'спецу',
    'спида',
    'спиди',
    'спидо',
    'спиев',
    'спиел',
    'спиеш',
    'спија',
    'спијо',
    'спика',
    'спила',
    'спили',
    'спило',
    'спира',
    'спире',
    'спирт',
    'спиру',
    'списа',
    'списи',
    'списо',
    'спису',
    'спица',
    'спици',
    'спицо',
    'сплав',
    'сплет',
    'сплит',
    'споев',
    'споел',
    'споен',
    'споив',
    'споил',
    'споиш',
    'споја',
    'споју',
    'спора',
    'споре',
    'спори',
    'спорт',
    'спору',
    'спреј',
    'спржи',
    'спрли',
    'спрпа',
    'спуза',
    'спука',
    'спуки',
    'спуко',
    'спури',
    'спуст',
    'срази',
    'срака',
    'сраку',
    'срама',
    'сраме',
    'срами',
    'сраму',
    'сраче',
    'србне',
    'срдба',
    'срдби',
    'срдбо',
    'срдеж',
    'срдит',
    'среда',
    'среде',
    'среди',
    'средо',
    'среже',
    'среќа',
    'среќи',
    'среќо',
    'срета',
    'срете',
    'срети',
    'срето',
    'сржва',
    'сржна',
    'сржов',
    'сржон',
    'сржот',
    'сржта',
    'срика',
    'срине',
    'срипа',
    'срита',
    'срича',
    'сркне',
    'срмен',
    'срнче',
    'сроди',
    'срока',
    'сроку',
    'срони',
    'сроче',
    'српов',
    'српон',
    'српот',
    'српче',
    'срска',
    'сртов',
    'сртон',
    'сртот',
    'срупа',
    'срути',
    'сруши',
    'срцев',
    'срчен',
    'срчка',
    'срчки',
    'срчко',
    'срџба',
    'срџби',
    'срџбо',
    'става',
    'ставе',
    'стави',
    'ставо',
    'ставу',
    'стада',
    'стадо',
    'стажу',
    'стаја',
    'стајо',
    'стана',
    'стане',
    'стани',
    'стану',
    'стаор',
    'стапа',
    'стапе',
    'стапи',
    'стапу',
    'стара',
    'старе',
    'стари',
    'старо',
    'старт',
    'стару',
    'стаса',
    'ствар',
    'створ',
    'стега',
    'стеги',
    'стего',
    'стегу',
    'стеже',
    'стека',
    'стеку',
    'стела',
    'стели',
    'стело',
    'стена',
    'стене',
    'стени',
    'стено',
    'стену',
    'степа',
    'степе',
    'степи',
    'степо',
    'степу',
    'стече',
    'стига',
    'стија',
    'стијо',
    'стила',
    'стиле',
    'стило',
    'стилу',
    'стине',
    'стиха',
    'стиху',
    'стиши',
    'стове',
    'стога',
    'стогу',
    'стоев',
    'стоел',
    'стоен',
    'стоже',
    'стоик',
    'стоиш',
    'стока',
    'стоки',
    'стоко',
    'стола',
    'столб',
    'столе',
    'столп',
    'столу',
    'стоне',
    'стопи',
    'стори',
    'стота',
    'стоте',
    'стоти',
    'стото',
    'страв',
    'страд',
    'страж',
    'страк',
    'страч',
    'стрви',
    'стреа',
    'стреи',
    'стрем',
    'стрео',
    'стрес',
    'стреч',
    'стреш',
    'стриг',
    'стрип',
    'стрма',
    'стрми',
    'стрмо',
    'строг',
    'строи',
    'строј',
    'струг',
    'струи',
    'струк',
    'струп',
    'стрча',
    'стрчи',
    'студа',
    'студе',
    'студи',
    'студу',
    'сувар',
    'суват',
    'сувее',
    'сувчо',
    'судам',
    'судат',
    'судач',
    'судба',
    'судби',
    'судбо',
    'судеа',
    'судев',
    'судел',
    'суден',
    'судии',
    'судир',
    'судиш',
    'судна',
    'судни',
    'судно',
    'судов',
    'судон',
    'судот',
    'судри',
    'суета',
    'суети',
    'суето',
    'сужен',
    'суинг',
    'суита',
    'суити',
    'суито',
    'сукаа',
    'сукав',
    'сукај',
    'сукал',
    'сукам',
    'сукан',
    'сукар',
    'сукаш',
    'сукна',
    'сукне',
    'сукно',
    'сукња',
    'суков',
    'сукон',
    'сукот',
    'сулин',
    'сумал',
    'сумти',
    'сунет',
    'сунит',
    'супен',
    'супер',
    'супов',
    'супон',
    'супот',
    'сурат',
    'сурии',
    'сурка',
    'сурки',
    'сурко',
    'сурла',
    'сурли',
    'сурло',
    'сурма',
    'сурме',
    'сурне',
    'суров',
    'сусам',
    'суска',
    'суски',
    'суско',
    'сутне',
    'сутра',
    'суфле',
    'сучен',
    'сучка',
    'сучки',
    'сучко',
    'суџук',
    'сушен',
    'сушец',
    'сушна',
    'сушни',
    'сушно',
    'сушци',
    'сфаќа',
    'сфати',
    'сфера',
    'сфери',
    'сферо',
    'схема',
    'схеми',
    'схемо',
    'сцена',
    'сцени',
    'сцено',
    'сцрви',
    'сцрка',
    'счука',
    'табак',
    'табан',
    'табии',
    'табла',
    'табле',
    'табли',
    'табло',
    'табор',
    'табуа',
    'табур',
    'табут',
    'таван',
    'тавла',
    'тавли',
    'тавло',
    'тавра',
    'таври',
    'тавро',
    'тавче',
    'тагар',
    'таења',
    'таење',
    'тажам',
    'тажат',
    'тажба',
    'тажеа',
    'тажев',
    'тажел',
    'тажен',
    'тажиш',
    'тажна',
    'тажни',
    'тажно',
    'тазии',
    'таима',
    'таиме',
    'таими',
    'таиму',
    'таина',
    'таине',
    'таини',
    'таину',
    'тајга',
    'тајги',
    'тајго',
    'тајна',
    'тајни',
    'тајно',
    'тајум',
    'тајфа',
    'тајфи',
    'тајфо',
    'такам',
    'такан',
    'таква',
    'такви',
    'такво',
    'таков',
    'такон',
    'такот',
    'такса',
    'такси',
    'таксо',
    'таксу',
    'такта',
    'такте',
    'такту',
    'талаз',
    'талан',
    'талас',
    'талер',
    'талим',
    'талир',
    'талка',
    'талку',
    'талне',
    'талог',
    'талон',
    'талпа',
    'талпи',
    'талпо',
    'талче',
    'таљур',
    'тамам',
    'таман',
    'танга',
    'танго',
    'танец',
    'танин',
    'танко',
    'танов',
    'танок',
    'танон',
    'танот',
    'танур',
    'танца',
    'танце',
    'танци',
    'танцу',
    'танче',
    'танчи',
    'тапан',
    'тапет',
    'тапии',
    'тапир',
    'тапка',
    'тапки',
    'тапко',
    'тарак',
    'тараф',
    'тарга',
    'тарги',
    'тарго',
    'тарих',
    'тарун',
    'тасма',
    'тасов',
    'тасон',
    'тасот',
    'татар',
    'татев',
    'татин',
    'татка',
    'татко',
    'татли',
    'татни',
    'татов',
    'татон',
    'татче',
    'тафра',
    'тафри',
    'тафро',
    'тафта',
    'тафте',
    'тафту',
    'тацна',
    'ташак',
    'ташна',
    'твари',
    'твист',
    'твоја',
    'твори',
    'тврда',
    'тврди',
    'тврдо',
    'тевна',
    'тевни',
    'тевно',
    'тегав',
    'тегел',
    'тегет',
    'тегла',
    'тегли',
    'тегло',
    'тегне',
    'тегни',
    'тегов',
    'тегон',
    'тегот',
    'тежам',
    'тежат',
    'тежеа',
    'тежев',
    'тежел',
    'тежиш',
    'тежок',
    'тезга',
    'тезге',
    'теира',
    'теире',
    'теири',
    'теиру',
    'теист',
    'тејко',
    'текар',
    'текии',
    'текне',
    'теков',
    'текон',
    'текот',
    'текст',
    'телал',
    'телар',
    'телен',
    'телоп',
    'телца',
    'телце',
    'телци',
    'тељал',
    'тељов',
    'тељон',
    'тељот',
    'тембр',
    'темел',
    'темен',
    'темна',
    'темне',
    'темни',
    'темно',
    'темпа',
    'темпо',
    'тенда',
    'тении',
    'тенис',
    'тенка',
    'тенки',
    'тенко',
    'тенку',
    'тенов',
    'тенок',
    'тенон',
    'тенор',
    'тенот',
    'тенчи',
    'тепаа',
    'тепав',
    'тепај',
    'тепал',
    'тепам',
    'тепан',
    'тепач',
    'тепаш',
    'тепих',
    'тепка',
    'тераа',
    'терав',
    'терај',
    'терал',
    'терам',
    'теран',
    'терач',
    'тераш',
    'терен',
    'терка',
    'терку',
    'терма',
    'термо',
    'терор',
    'терца',
    'терци',
    'терцо',
    'терче',
    'тесен',
    'тесла',
    'тесле',
    'тесли',
    'тесло',
    'тесна',
    'тесни',
    'тесно',
    'теста',
    'тесте',
    'тесто',
    'тесту',
    'тетин',
    'тетка',
    'тетки',
    'тетко',
    'течам',
    'течат',
    'течеа',
    'течев',
    'течел',
    'течен',
    'течеш',
    'течна',
    'течни',
    'течно',
    'тешам',
    'тешат',
    'тешеа',
    'тешев',
    'тешел',
    'тешен',
    'тешиш',
    'тешка',
    'тешки',
    'тешко',
    'тешта',
    'тешти',
    'тешто',
    'тивка',
    'тивки',
    'тивко',
    'тивне',
    'тивок',
    'тиган',
    'тигар',
    'тигри',
    'тизап',
    'тиква',
    'тикве',
    'тикви',
    'тикво',
    'тикет',
    'тилда',
    'тилен',
    'тилје',
    'тилов',
    'тилон',
    'тилот',
    'тимар',
    'тимба',
    'тимии',
    'тимов',
    'тимон',
    'тимот',
    'тимус',
    'типик',
    'типка',
    'типки',
    'типов',
    'типон',
    'типот',
    'тираж',
    'тиран',
    'тиреа',
    'тиска',
    'тисов',
    'титан',
    'титиз',
    'титка',
    'титла',
    'титли',
    'титло',
    'тифус',
    'тишка',
    'тишки',
    'тишко',
    'тишти',
    'ткаам',
    'ткаат',
    'ткаач',
    'ткаев',
    'ткаел',
    'ткаен',
    'ткаеш',
    'ткива',
    'ткиво',
    'тлава',
    'тлака',
    'тлаки',
    'тлако',
    'тлана',
    'тлата',
    'тлово',
    'тлоно',
    'тлото',
    'товар',
    'тогај',
    'тогаш',
    'токми',
    'токмо',
    'токму',
    'токов',
    'токон',
    'токот',
    'толку',
    'толпа',
    'толпи',
    'толпо',
    'толчи',
    'томов',
    'томон',
    'томот',
    'тонам',
    'тонат',
    'тонеа',
    'тонев',
    'тонел',
    'тонеш',
    'тоник',
    'тонов',
    'тонон',
    'тонот',
    'тонус',
    'топаз',
    'топал',
    'топам',
    'топат',
    'топеа',
    'топев',
    'топел',
    'топен',
    'топиш',
    'топка',
    'топки',
    'топко',
    'топла',
    'топли',
    'топло',
    'топов',
    'топол',
    'топон',
    'топот',
    'топуз',
    'топук',
    'топче',
    'торба',
    'торбе',
    'торби',
    'торбо',
    'торза',
    'торзо',
    'торта',
    'торти',
    'торто',
    'тоска',
    'тоски',
    'тоско',
    'тоста',
    'тосте',
    'тосту',
    'тотал',
    'тотем',
    'точак',
    'точам',
    'точат',
    'точач',
    'точеа',
    'точев',
    'точел',
    'точен',
    'точиш',
    'точка',
    'точки',
    'точко',
    'точна',
    'точни',
    'точно',
    'траам',
    'траат',
    'траба',
    'траби',
    'трабо',
    'трава',
    'трави',
    'траво',
    'трага',
    'траги',
    'траго',
    'трагу',
    'траев',
    'траел',
    'траен',
    'траеш',
    'траже',
    'тражи',
    'трака',
    'траки',
    'трако',
    'тракт',
    'транс',
    'траор',
    'трапа',
    'трапе',
    'трапу',
    'траса',
    'траси',
    'трасо',
    'трата',
    'трате',
    'трати',
    'трату',
    'траур',
    'трбув',
    'тргаа',
    'тргав',
    'тргај',
    'тргал',
    'тргам',
    'тргаш',
    'тргна',
    'тргне',
    'тргни',
    'треба',
    'треби',
    'трева',
    'треви',
    'трево',
    'треќи',
    'трема',
    'треме',
    'треми',
    'тремо',
    'трему',
    'тренд',
    'тресе',
    'треси',
    'трета',
    'трети',
    'трето',
    'триве',
    'триев',
    'триел',
    'триен',
    'триер',
    'триеш',
    'трижа',
    'трижи',
    'трижу',
    'трија',
    'трика',
    'трико',
    'трику',
    'трине',
    'триод',
    'трите',
    'трици',
    'триче',
    'тркач',
    'тркне',
    'трков',
    'тркон',
    'тркот',
    'тркум',
    'трлав',
    'трлив',
    'трмка',
    'трмки',
    'трмко',
    'трнка',
    'трнки',
    'трнко',
    'трнов',
    'трнон',
    'трнот',
    'трнче',
    'трњак',
    'трова',
    'трови',
    'трово',
    'трога',
    'троен',
    'трома',
    'тромб',
    'троми',
    'тромо',
    'трона',
    'троне',
    'трону',
    'троња',
    'троњу',
    'тропа',
    'тропи',
    'троши',
    'трпам',
    'трпат',
    'трпеа',
    'трпев',
    'трпеж',
    'трпел',
    'трпиш',
    'трпки',
    'трпне',
    'трпок',
    'трска',
    'трски',
    'трско',
    'тртка',
    'тртки',
    'тртко',
    'труба',
    'труби',
    'трубо',
    'труда',
    'труде',
    'труду',
    'труев',
    'труел',
    'труеш',
    'трупа',
    'трупи',
    'трупо',
    'трупу',
    'труст',
    'трута',
    'труте',
    'труту',
    'трчаа',
    'трчав',
    'трчај',
    'трчал',
    'трчам',
    'трчан',
    'трчаш',
    'трчка',
    'трчне',
    'туѓин',
    'тужам',
    'тужат',
    'тужба',
    'тужби',
    'тужбо',
    'тужеа',
    'тужев',
    'тужел',
    'тужен',
    'тужиш',
    'туков',
    'тукон',
    'тукот',
    'тукче',
    'тулар',
    'тулец',
    'тулов',
    'тулон',
    'тулот',
    'тулум',
    'тулуп',
    'тумба',
    'тумби',
    'тумбо',
    'тумор',
    'тунел',
    'тупка',
    'тупне',
    'тупот',
    'тураа',
    'турав',
    'турај',
    'турал',
    'турам',
    'турат',
    'турач',
    'тураш',
    'турбе',
    'турбо',
    'туреа',
    'турев',
    'турел',
    'туриш',
    'турка',
    'турма',
    'турми',
    'турмо',
    'турне',
    'турчи',
    'тутка',
    'тутки',
    'тутко',
    'тутне',
    'тутор',
    'тутун',
    'тутур',
    'туфек',
    'туфка',
    'туфки',
    'туфко',
    'туфче',
    'тучен',
    'тучов',
    'тучон',
    'тучот',
    'туџар',
    'тушов',
    'тушон',
    'тушот',
    'уапси',
    'убава',
    'убави',
    'убаво',
    'убеди',
    'убива',
    'убиев',
    'убиел',
    'убиен',
    'убиец',
    'убиеш',
    'убија',
    'убила',
    'убиле',
    'убило',
    'убода',
    'убоде',
    'убоди',
    'убоду',
    'убоен',
    'убост',
    'убрус',
    'уважи',
    'увеза',
    'увезе',
    'увези',
    'увери',
    'увида',
    'увиде',
    'увиди',
    'увиду',
    'увово',
    'увода',
    'уводе',
    'уводи',
    'уводу',
    'увоза',
    'увози',
    'увозу',
    'увоно',
    'увото',
    'угара',
    'угаре',
    'угари',
    'угару',
    'угаси',
    'угича',
    'угиче',
    'угичи',
    'угичу',
    'углав',
    'углед',
    'угоди',
    'угоен',
    'угоре',
    'угори',
    'угрев',
    'угрее',
    'угриз',
    'удави',
    'удара',
    'ударе',
    'удари',
    'удару',
    'удвои',
    'удела',
    'уделе',
    'удели',
    'уделу',
    'удина',
    'удини',
    'удино',
    'удира',
    'удире',
    'удири',
    'удиру',
    'удолу',
    'удоми',
    'удрам',
    'удрат',
    'удреа',
    'удрев',
    'удрел',
    'удрен',
    'удриш',
    'удута',
    'удуте',
    'удути',
    'удуту',
    'уемов',
    'уемон',
    'уемот',
    'ужали',
    'ужаса',
    'ужасе',
    'ужаси',
    'ужасу',
    'ужива',
    'ужина',
    'ужини',
    'ужино',
    'узнае',
    'узрее',
    'узура',
    'узуре',
    'узури',
    'узуру',
    'узуса',
    'узусе',
    'узуси',
    'узусу',
    'уѕура',
    'уѕуре',
    'уѕури',
    'уѕуру',
    'ујади',
    'ујгун',
    'укава',
    'укажа',
    'укаже',
    'укажи',
    'указа',
    'укази',
    'указу',
    'укана',
    'укања',
    'укање',
    'укаса',
    'уката',
    'укача',
    'укаче',
    'укачи',
    'укачу',
    'укиве',
    'укина',
    'укине',
    'укини',
    'укита',
    'уките',
    'укити',
    'укиту',
    'укопа',
    'укора',
    'укоре',
    'укори',
    'укору',
    'украл',
    'украс',
    'укруп',
    'улава',
    'улана',
    'улане',
    'улани',
    'улану',
    'улата',
    'улема',
    'улења',
    'улење',
    'улера',
    'улери',
    'улеро',
    'улефе',
    'уливе',
    'улине',
    'улите',
    'улица',
    'улице',
    'улици',
    'улицо',
    'улиши',
    'улови',
    'улога',
    'улоги',
    'улого',
    'улогу',
    'уложе',
    'улози',
    'ултра',
    'умава',
    'умана',
    'умата',
    'умеам',
    'умеат',
    'умеев',
    'умеел',
    'умееш',
    'умења',
    'умење',
    'умери',
    'умиве',
    'умине',
    'умира',
    'умире',
    'умири',
    'умиру',
    'умите',
    'умник',
    'умови',
    'умоли',
    'умора',
    'уморе',
    'умори',
    'уморо',
    'умору',
    'умрам',
    'умрат',
    'умреа',
    'умрев',
    'умрел',
    'умрен',
    'умреш',
    'умрна',
    'умрни',
    'умрно',
    'умува',
    'умута',
    'умуте',
    'умути',
    'умуту',
    'унера',
    'унере',
    'унери',
    'унеру',
    'унеса',
    'унесе',
    'унеси',
    'унесу',
    'унија',
    'унијо',
    'унции',
    'упаѓа',
    'упада',
    'упаде',
    'упади',
    'упаду',
    'упати',
    'уписа',
    'уписе',
    'уписи',
    'упису',
    'уплав',
    'уплив',
    'упоја',
    'упоју',
    'упрек',
    'урами',
    'урана',
    'уране',
    'урани',
    'урану',
    'урбан',
    'урвен',
    'уреда',
    'уреде',
    'уреди',
    'уреду',
    'урива',
    'урина',
    'урини',
    'урино',
    'урмин',
    'урнат',
    'урнек',
    'урова',
    'урове',
    'урови',
    'урову',
    'урока',
    'уроку',
    'уроци',
    'уроче',
    'урочи',
    'усвет',
    'усвои',
    'усета',
    'усете',
    'усети',
    'усето',
    'усету',
    'услов',
    'услуп',
    'усмев',
    'уснен',
    'усник',
    'усоен',
    'усоја',
    'усоју',
    'успеа',
    'успее',
    'успеј',
    'успех',
    'успие',
    'устав',
    'устат',
    'усташ',
    'устен',
    'устие',
    'устои',
    'усула',
    'усуле',
    'усули',
    'усулу',
    'усуче',
    'утаен',
    'утања',
    'утање',
    'утаре',
    'утека',
    'утеки',
    'утеко',
    'утепа',
    'утеха',
    'утехи',
    'утехо',
    'утеши',
    'утија',
    'утијо',
    'утина',
    'утица',
    'утице',
    'утици',
    'утицо',
    'уткае',
    'уткин',
    'утман',
    'утови',
    'утока',
    'утоки',
    'утоко',
    'утоне',
    'утопа',
    'утопе',
    'утопи',
    'утопу',
    'утреж',
    'утрен',
    'утрои',
    'утски',
    'ухања',
    'ухање',
    'уцена',
    'уцени',
    'уцено',
    'учела',
    'учеле',
    'учело',
    'учена',
    'учени',
    'учено',
    'учења',
    'учење',
    'учете',
    'учеше',
    'учила',
    'учило',
    'учиме',
    'учини',
    'учите',
    'учкур',
    'учтив',
    'уџере',
    'ушака',
    'ушаку',
    'ушаци',
    'ушаче',
    'ушево',
    'ушено',
    'ушест',
    'ушето',
    'ушиве',
    'ушине',
    'ушиња',
    'ушите',
    'ушица',
    'ушник',
    'уштип',
    'фагот',
    'фазан',
    'фазен',
    'фазла',
    'фазна',
    'фазни',
    'фазно',
    'фазон',
    'фајда',
    'фајде',
    'фајди',
    'фајдо',
    'фаќаа',
    'фаќав',
    'фаќај',
    'фаќал',
    'фаќам',
    'фаќан',
    'фаќач',
    'фаќаш',
    'факел',
    'факир',
    'факла',
    'факли',
    'факло',
    'факов',
    'факон',
    'факот',
    'факта',
    'факте',
    'факти',
    'факту',
    'фалам',
    'фалат',
    'фалба',
    'фалби',
    'фалбо',
    'фалеа',
    'фалев',
    'фалел',
    'фален',
    'фалиш',
    'фалта',
    'фалти',
    'фалто',
    'фалус',
    'фалца',
    'фалце',
    'фалцу',
    'фалша',
    'фалшу',
    'фанта',
    'фарак',
    'фараш',
    'фарба',
    'фарби',
    'фарбо',
    'фарма',
    'фарми',
    'фармо',
    'фаров',
    'фарон',
    'фарот',
    'фарса',
    'фарси',
    'фарсо',
    'фатам',
    'фатат',
    'фатеа',
    'фатев',
    'фател',
    'фатен',
    'фатив',
    'фатил',
    'фатиш',
    'фатка',
    'фатов',
    'фатон',
    'фатот',
    'фаула',
    'фауле',
    'фаули',
    'фаулу',
    'фауна',
    'фауни',
    'фауно',
    'фахов',
    'фахон',
    'фахот',
    'февче',
    'федер',
    'феиве',
    'феине',
    'феите',
    'фелер',
    'фелии',
    'фелка',
    'фелки',
    'фелко',
    'фенер',
    'фенов',
    'фенол',
    'фенон',
    'фенот',
    'феода',
    'феоде',
    'феоди',
    'феоду',
    'ферии',
    'ферма',
    'фесов',
    'фесон',
    'фесот',
    'фетиш',
    'фетус',
    'феуда',
    'феуде',
    'феуди',
    'феуду',
    'фидан',
    'физик',
    'физио',
    'фикии',
    'фикус',
    'филан',
    'филиз',
    'филии',
    'филма',
    'филме',
    'филму',
    'филов',
    'филон',
    'филот',
    'филца',
    'филце',
    'филцу',
    'фиљан',
    'финал',
    'финик',
    'финиш',
    'финта',
    'финти',
    'финто',
    'фиока',
    'фиоки',
    'фиоко',
    'фиран',
    'фирка',
    'фирма',
    'фирми',
    'фирмо',
    'фирца',
    'фиска',
    'фиски',
    'фиско',
    'фиску',
    'фисче',
    'фитил',
    'фитиљ',
    'фиука',
    'фишек',
    'фјорд',
    'фланг',
    'флека',
    'флеки',
    'флеко',
    'флерт',
    'флеша',
    'флешу',
    'флирт',
    'флора',
    'флоре',
    'флори',
    'флоро',
    'флору',
    'флота',
    'флоти',
    'флото',
    'флуид',
    'флуор',
    'фоаје',
    'фобии',
    'фодул',
    'фокса',
    'фоксу',
    'фокус',
    'фолио',
    'фолов',
    'фолон',
    'фолот',
    'фонда',
    'фонде',
    'фонди',
    'фонду',
    'фонем',
    'фонов',
    'фонон',
    'фонот',
    'форма',
    'форми',
    'формо',
    'форов',
    'форон',
    'форот',
    'форте',
    'форум',
    'форца',
    'форци',
    'форцо',
    'фосил',
    'фотка',
    'фотон',
    'фотос',
    'фраер',
    'фраза',
    'фрази',
    'фразо',
    'фрака',
    'фраку',
    'франк',
    'фраса',
    'фрасу',
    'фраче',
    'фреза',
    'фрези',
    'фрезо',
    'френг',
    'френк',
    'фреон',
    'фркат',
    'фркне',
    'фрлаа',
    'фрлав',
    'фрлај',
    'фрлал',
    'фрлам',
    'фрлан',
    'фрлач',
    'фрлаш',
    'фрнка',
    'фрнку',
    'фрнци',
    'фрнче',
    'фронт',
    'фрска',
    'фрсне',
    'фрфул',
    'фрцне',
    'фрчов',
    'фрчон',
    'фрчот',
    'фудул',
    'фузии',
    'фукне',
    'фукса',
    'фунда',
    'фунде',
    'фунду',
    'фунта',
    'фунти',
    'фунто',
    'фураж',
    'фурда',
    'фурди',
    'фурдо',
    'фурии',
    'фурка',
    'фурки',
    'фурко',
    'фурма',
    'фурми',
    'фурмо',
    'фурна',
    'фурни',
    'фурно',
    'фурор',
    'фуста',
    'фусти',
    'фусто',
    'футур',
    'фуфка',
    'фуфул',
    'фучии',
    'фушер',
    'хајка',
    'хајки',
    'хајко',
    'хакер',
    'халиф',
    'халфа',
    'халфе',
    'халфу',
    'хамер',
    'хаоса',
    'хаосе',
    'хаоси',
    'хаосу',
    'харем',
    'харфа',
    'харфи',
    'харфо',
    'хауба',
    'хаџии',
    'хашиш',
    'хекто',
    'хелда',
    'хелио',
    'хемии',
    'хендс',
    'херои',
    'херој',
    'херца',
    'херце',
    'херци',
    'херцу',
    'хефта',
    'хидра',
    'хидри',
    'хидро',
    'хиена',
    'хиени',
    'хиено',
    'хијат',
    'хилус',
    'химен',
    'химна',
    'химни',
    'химно',
    'хинди',
    'хинин',
    'хипер',
    'хипик',
    'хиров',
    'хирон',
    'хирот',
    'хисар',
    'хитов',
    'хитон',
    'хитот',
    'хлора',
    'хлоре',
    'хлору',
    'хмеља',
    'хмеље',
    'хмељу',
    'хокеи',
    'хокеј',
    'холов',
    'холон',
    'холот',
    'хорал',
    'хореи',
    'хореј',
    'хорна',
    'хорни',
    'хорно',
    'хоров',
    'хорон',
    'хорор',
    'хорот',
    'хотел',
    'храма',
    'храме',
    'храму',
    'храна',
    'храни',
    'храно',
    'хрема',
    'хреми',
    'хремо',
    'хрома',
    'хроме',
    'хрому',
    'хуман',
    'хумка',
    'хумор',
    'хумус',
    'хунта',
    'хунти',
    'хунто',
    'хупка',
    'хупки',
    'хупко',
    'хурии',
    'хусар',
    'цавка',
    'цавки',
    'цавко',
    'цавти',
    'цакне',
    'цапка',
    'цапне',
    'цапов',
    'цапон',
    'цапот',
    'царев',
    'царее',
    'царов',
    'царон',
    'царот',
    'царче',
    'цацар',
    'цацка',
    'цацко',
    'цвеќе',
    'цвета',
    'цвете',
    'цвети',
    'цвету',
    'цвика',
    'цвику',
    'цвили',
    'цвиче',
    'цвичи',
    'цволи',
    'цврка',
    'цврст',
    'цврчи',
    'цевка',
    'цевки',
    'цевко',
    'цевче',
    'цегер',
    'цеѓта',
    'цекин',
    'целва',
    'целен',
    'целер',
    'целец',
    'целив',
    'целна',
    'целта',
    'целув',
    'ценам',
    'ценат',
    'ценач',
    'ценеа',
    'ценев',
    'ценел',
    'ценет',
    'ценза',
    'цензу',
    'цениш',
    'ценка',
    'цента',
    'центе',
    'центи',
    'центо',
    'центу',
    'цепам',
    'цепат',
    'цепач',
    'цепеа',
    'цепев',
    'цепел',
    'цепен',
    'цепеш',
    'цепка',
    'цепки',
    'цепко',
    'цепне',
    'цепов',
    'цепон',
    'цепот',
    'цепци',
    'цепче',
    'церга',
    'церги',
    'церго',
    'церје',
    'церов',
    'церон',
    'церот',
    'церче',
    'цесар',
    'цецка',
    'цивил',
    'цивка',
    'цивне',
    'цигла',
    'цигли',
    'цигло',
    'цијан',
    'цикне',
    'цимер',
    'цимет',
    'цимка',
    'циник',
    'цинка',
    'цинку',
    'цинче',
    'цирка',
    'цирки',
    'цирко',
    'цирон',
    'цирус',
    'циста',
    'цисти',
    'цисто',
    'цитат',
    'цитра',
    'цитри',
    'цитро',
    'циуна',
    'циуне',
    'циуни',
    'циуну',
    'цифка',
    'цифон',
    'цифра',
    'цифри',
    'цифро',
    'цифун',
    'цицаа',
    'цицав',
    'цицај',
    'цицал',
    'цицам',
    'цицан',
    'цицач',
    'цицаш',
    'цицен',
    'цицер',
    'циции',
    'цицка',
    'цицки',
    'цицко',
    'цицне',
    'цицов',
    'цицон',
    'цицот',
    'цокле',
    'цопаќ',
    'црвек',
    'црвен',
    'црвец',
    'црвје',
    'црвов',
    'црвон',
    'црвот',
    'црвул',
    'црвци',
    'црвче',
    'црева',
    'црево',
    'црека',
    'црена',
    'црене',
    'црену',
    'црепа',
    'црепе',
    'црепу',
    'цреша',
    'цреши',
    'црешо',
    'црква',
    'цркве',
    'цркви',
    'цркво',
    'цркне',
    'црков',
    'црнам',
    'црнат',
    'црнеа',
    'црнев',
    'црнее',
    'црнел',
    'црнец',
    'црниш',
    'црнка',
    'црнки',
    'црнко',
    'црнок',
    'црнци',
    'црнче',
    'црнчи',
    'црњак',
    'црпам',
    'црпат',
    'црпеа',
    'црпев',
    'црпел',
    'црпен',
    'црпиш',
    'црпка',
    'црпне',
    'црпче',
    'цртаа',
    'цртав',
    'цртај',
    'цртал',
    'цртам',
    'цртан',
    'цртач',
    'црташ',
    'цртеж',
    'црцне',
    'црцор',
    'цулуф',
    'цунка',
    'цупка',
    'цурак',
    'цурка',
    'цурко',
    'цутар',
    'цутеж',
    'цутен',
    'цутка',
    'цутне',
    'цутов',
    'цутон',
    'цутот',
    'цутче',
    'цуцка',
    'цуцки',
    'цуцко',
    'цуцла',
    'цуцул',
    'чабав',
    'чабур',
    'чавка',
    'чавки',
    'чавко',
    'чавче',
    'чадар',
    'чаден',
    'чадје',
    'чадов',
    'чадон',
    'чадор',
    'чадот',
    'чадри',
    'чаеви',
    'чаира',
    'чаире',
    'чаири',
    'чаиру',
    'чајов',
    'чајон',
    'чајот',
    'чајре',
    'чајче',
    'чакал',
    'чакии',
    'чакне',
    'чалам',
    'чалга',
    'чалма',
    'чалми',
    'чалмо',
    'чалне',
    'чамец',
    'чамов',
    'чамон',
    'чамот',
    'чамци',
    'чамче',
    'чанак',
    'чанта',
    'чанте',
    'чанти',
    'чанто',
    'чанче',
    'чапја',
    'чапји',
    'чапјо',
    'чапла',
    'чапне',
    'чапов',
    'чапон',
    'чапот',
    'чапче',
    'чарка',
    'чарку',
    'чарта',
    'чарче',
    'часов',
    'часон',
    'часот',
    'часта',
    'часте',
    'часту',
    'часум',
    'чатал',
    'чатии',
    'чатма',
    'чатми',
    'чатмо',
    'чаура',
    'чауша',
    'чауши',
    'чаушу',
    'чашка',
    'чашки',
    'чашко',
    'чевел',
    'чевла',
    'чевле',
    'чевли',
    'чевло',
    'чевол',
    'чевре',
    'чеден',
    'чедна',
    'чедни',
    'чедно',
    'чезне',
    'чеиза',
    'чеизи',
    'чеизу',
    'чекаа',
    'чекав',
    'чекај',
    'чекал',
    'чекам',
    'чекан',
    'чекач',
    'чекаш',
    'чекич',
    'чеков',
    'чекон',
    'чекор',
    'чекот',
    'чекрк',
    'челад',
    'челен',
    'челик',
    'челна',
    'челни',
    'челно',
    'челуз',
    'челца',
    'челце',
    'чемер',
    'чепка',
    'чепне',
    'чепов',
    'чепон',
    'чепот',
    'чепур',
    'чепче',
    'черга',
    'черги',
    'черго',
    'черек',
    'черен',
    'череп',
    'черии',
    'чесен',
    'чесна',
    'чесни',
    'чесно',
    'честа',
    'чести',
    'често',
    'четен',
    'четка',
    'четки',
    'четко',
    'четче',
    'чечка',
    'чечне',
    'чешаа',
    'чешав',
    'чешај',
    'чешал',
    'чешам',
    'чешан',
    'чешаш',
    'чешел',
    'чешен',
    'чешит',
    'чешка',
    'чешла',
    'чешле',
    'чешли',
    'чешма',
    'чешми',
    'чешмо',
    'чешне',
    'чешни',
    'чибук',
    'чивии',
    'чивит',
    'чизии',
    'чизма',
    'чизми',
    'чизмо',
    'чикер',
    'чикме',
    'чилаш',
    'чинам',
    'чинар',
    'чинат',
    'чинго',
    'чинеа',
    'чинев',
    'чинел',
    'чинии',
    'чиниш',
    'чинка',
    'чинов',
    'чинон',
    'чинот',
    'чипав',
    'чипка',
    'чипов',
    'чипон',
    'чипот',
    'чипсу',
    'чипчи',
    'чирав',
    'чирак',
    'чириш',
    'чиров',
    'чирон',
    'чирот',
    'чирче',
    'числа',
    'число',
    'чиста',
    'чисти',
    'чисто',
    'читаа',
    'читав',
    'читај',
    'читак',
    'читал',
    'читам',
    'читан',
    'читач',
    'читаш',
    'читко',
    'читок',
    'чифта',
    'чифте',
    'чифту',
    'чичев',
    'чичек',
    'чичин',
    'чичка',
    'чички',
    'чичко',
    'чичов',
    'чичок',
    'чкепе',
    'чкета',
    'чкето',
    'чкива',
    'чкора',
    'чкоре',
    'чкору',
    'чкрап',
    'чкрка',
    'чкрки',
    'чкрко',
    'чкрта',
    'чкрте',
    'чкрту',
    'чкуле',
    'члена',
    'члене',
    'члену',
    'чмаја',
    'чмају',
    'чобан',
    'човек',
    'чоиве',
    'чоине',
    'чоите',
    'чоиче',
    'чојар',
    'чокан',
    'чокои',
    'чокој',
    'чолак',
    'чолна',
    'чолне',
    'чолну',
    'чолта',
    'чолти',
    'чолто',
    'чомак',
    'чорап',
    'чорба',
    'чорби',
    'чорбо',
    'чорла',
    'чорли',
    'чорло',
    'чочек',
    'чубук',
    'чуваа',
    'чував',
    'чувај',
    'чувал',
    'чувам',
    'чуван',
    'чувар',
    'чувач',
    'чуваш',
    'чудак',
    'чудам',
    'чудат',
    'чудба',
    'чудби',
    'чудбо',
    'чудеа',
    'чудев',
    'чудел',
    'чуден',
    'чудии',
    'чудиш',
    'чудна',
    'чудни',
    'чудно',
    'чуеја',
    'чуела',
    'чуеле',
    'чуело',
    'чуеме',
    'чуена',
    'чуени',
    'чуено',
    'чуете',
    'чуеше',
    'чујам',
    'чујат',
    'чујте',
    'чукаа',
    'чукав',
    'чукај',
    'чукал',
    'чукам',
    'чукан',
    'чукар',
    'чукаш',
    'чукне',
    'чуков',
    'чукон',
    'чукот',
    'чукче',
    'чулав',
    'чулов',
    'чулон',
    'чулот',
    'чулче',
    'чумав',
    'чумак',
    'чумба',
    'чумби',
    'чумбо',
    'чумен',
    'чумче',
    'чунар',
    'чунка',
    'чунки',
    'чунко',
    'чунов',
    'чунон',
    'чунот',
    'чунче',
    'чупче',
    'чуреа',
    'чурек',
    'чуриш',
    'чурук',
    'чучер',
    'чучне',
    'чучур',
    'чушка',
    'чушки',
    'чушко',
    'џавка',
    'џавне',
    'џавте',
    'џагор',
    'џадии',
    'џакет',
    'џамии',
    'џамов',
    'џамон',
    'џамот',
    'џамче',
    'џанам',
    'џанка',
    'џанки',
    'џанко',
    'џанов',
    'џанон',
    'џанот',
    'џапне',
    'џарка',
    'џарне',
    'џбара',
    'џбона',
    'џбуна',
    'џбуне',
    'џбуну',
    'џбура',
    'џвака',
    'џвала',
    'џвало',
    'џврка',
    'џгана',
    'џгане',
    'џгану',
    'џгрта',
    'џгура',
    'џгури',
    'џгуро',
    'џебен',
    'џебна',
    'џебни',
    'џебно',
    'џебов',
    'џебон',
    'џебот',
    'џевап',
    'џезве',
    'џезер',
    'џезов',
    'џезон',
    'џезот',
    'џелат',
    'џелеп',
    'џемов',
    'џемон',
    'џемот',
    'џенем',
    'џенка',
    'џенку',
    'џенче',
    'џепен',
    'џепна',
    'џепни',
    'џепно',
    'џепче',
    'џибра',
    'џивка',
    'џигер',
    'џилит',
    'џилфе',
    'џиљве',
    'џинка',
    'џинки',
    'џинко',
    'џинов',
    'џинон',
    'џинот',
    'џинса',
    'џинсу',
    'џинче',
    'џипов',
    'џипон',
    'џипот',
    'џипче',
    'џитка',
    'џитне',
    'џихад',
    'џиџан',
    'џишне',
    'џокеи',
    'џокеј',
    'џокер',
    'џонка',
    'џонки',
    'џонко',
    'џотка',
    'џотне',
    'џрџне',
    'џувап',
    'џумка',
    'џумки',
    'џумко',
    'џумле',
    'џунка',
    'џунки',
    'џунко',
    'џурка',
    'џурне',
    'шавар',
    'шавне',
    'шаека',
    'шаеку',
    'шаеци',
    'шаече',
    'шаина',
    'шаине',
    'шаини',
    'шаину',
    'шаита',
    'шаите',
    'шаити',
    'шаиту',
    'шајак',
    'шајка',
    'шајки',
    'шајко',
    'шалав',
    'шалаг',
    'шалов',
    'шалон',
    'шалот',
    'шалче',
    'шамак',
    'шаман',
    'шамар',
    'шамии',
    'шамот',
    'шанец',
    'шанка',
    'шанов',
    'шанон',
    'шанот',
    'шанса',
    'шанси',
    'шансо',
    'шанци',
    'шанче',
    'шапка',
    'шапки',
    'шапко',
    'шапов',
    'шапон',
    'шапот',
    'шапче',
    'шараа',
    'шарав',
    'шарај',
    'шарал',
    'шарам',
    'шаран',
    'шарач',
    'шараш',
    'шарба',
    'шарби',
    'шарбо',
    'шарен',
    'шарец',
    'шаржа',
    'шарка',
    'шарки',
    'шарко',
    'шарма',
    'шарме',
    'шарму',
    'шаров',
    'шарон',
    'шарот',
    'шарпа',
    'шарци',
    'шарче',
    'шасии',
    'шасла',
    'шатка',
    'шатки',
    'шатко',
    'шаток',
    'шатор',
    'шатра',
    'шатри',
    'шатро',
    'шатче',
    'шахов',
    'шахон',
    'шахот',
    'шахта',
    'шашав',
    'шашка',
    'шашки',
    'шашко',
    'шашма',
    'шашми',
    'шашмо',
    'шверц',
    'шврка',
    'шебек',
    'шебои',
    'шебој',
    'шевен',
    'шевов',
    'шевон',
    'шевот',
    'шевра',
    'шевро',
    'шевче',
    'шеган',
    'шегач',
    'шегрт',
    'шегун',
    'шедба',
    'шедби',
    'шедбо',
    'шеици',
    'шејов',
    'шејон',
    'шејот',
    'шеќер',
    'шекне',
    'шемет',
    'шенов',
    'шенон',
    'шенот',
    'шепка',
    'шепне',
    'шепот',
    'шепти',
    'шерет',
    'шериф',
    'шеста',
    'шести',
    'шесто',
    'шетаа',
    'шетав',
    'шетај',
    'шетал',
    'шетам',
    'шетан',
    'шетач',
    'шеташ',
    'шетка',
    'шетне',
    'шефов',
    'шефон',
    'шефот',
    'шехов',
    'шехон',
    'шехот',
    'шешир',
    'шибер',
    'шибне',
    'шивач',
    'шиеја',
    'шиела',
    'шиеле',
    'шиело',
    'шиеме',
    'шиена',
    'шиени',
    'шиено',
    'шиења',
    'шиење',
    'шиете',
    'шиеше',
    'шизик',
    'шизла',
    'шизма',
    'шизми',
    'шизмо',
    'шииве',
    'шиине',
    'шиите',
    'шијак',
    'шијам',
    'шијат',
    'шијач',
    'шијте',
    'шикла',
    'шикли',
    'шикло',
    'шикне',
    'шиков',
    'шикон',
    'шикот',
    'шилер',
    'шилец',
    'шилте',
    'шилци',
    'шилче',
    'шилчи',
    'шинаа',
    'шинав',
    'шинал',
    'шинам',
    'шинат',
    'шинеа',
    'шинев',
    'шинел',
    'шинеш',
    'шиник',
    'шипје',
    'шипка',
    'шипки',
    'шипко',
    'шипов',
    'шипон',
    'шипот',
    'ширам',
    'шират',
    'ширеа',
    'ширев',
    'ширел',
    'ширен',
    'ширит',
    'шириш',
    'ширка',
    'ширки',
    'ширко',
    'широк',
    'ширта',
    'ширум',
    'шитка',
    'шитне',
    'шиура',
    'шиуре',
    'шиури',
    'шиуру',
    'шифон',
    'шифра',
    'шифри',
    'шифро',
    'шишка',
    'шишки',
    'шишко',
    'шишов',
    'шишон',
    'шишот',
    'шишти',
    'шкарт',
    'шкафа',
    'шкафе',
    'шкафу',
    'шкета',
    'шкето',
    'шкоба',
    'шкоби',
    'шкобо',
    'школа',
    'школи',
    'школо',
    'шкрта',
    'шкрти',
    'шкрто',
    'шлага',
    'шлагу',
    'шлаже',
    'шлајм',
    'шлака',
    'шлаке',
    'шлаки',
    'шлако',
    'шланк',
    'шлапа',
    'шлапе',
    'шлапу',
    'шлема',
    'шлеме',
    'шлему',
    'шлепа',
    'шлепе',
    'шлепу',
    'шлиба',
    'шлифа',
    'шлифе',
    'шлифу',
    'шлица',
    'шлице',
    'шлицу',
    'шлога',
    'шлогу',
    'шложе',
    'шлупа',
    'шлупе',
    'шлупу',
    'шмрка',
    'шмрку',
    'шмрче',
    'шмука',
    'шнира',
    'шнола',
    'шноли',
    'шноло',
    'шогун',
    'шоков',
    'шокон',
    'шокот',
    'шолја',
    'шолји',
    'шолјо',
    'шолов',
    'шолон',
    'шолот',
    'шопне',
    'шопур',
    'шорне',
    'шофер',
    'шпага',
    'шпаги',
    'шпаго',
    'шпајз',
    'шпара',
    'шпион',
    'шпирт',
    'шпица',
    'шпице',
    'шпици',
    'шпицо',
    'шпицу',
    'шпора',
    'шпори',
    'шпоро',
    'шпота',
    'шприц',
    'шрафа',
    'шрафе',
    'шрафи',
    'шрафу',
    'шрифт',
    'штаба',
    'штабе',
    'штабу',
    'штава',
    'штаве',
    'штави',
    'штаво',
    'штала',
    'штали',
    'штало',
    'штама',
    'штами',
    'штамо',
    'штанд',
    'штеди',
    'штека',
    'штета',
    'штети',
    'штето',
    'штика',
    'штику',
    'штима',
    'штиме',
    'штиму',
    'штипе',
    'штипи',
    'штира',
    'штире',
    'штиру',
    'штита',
    'штите',
    'штити',
    'штиту',
    'штица',
    'штици',
    'штицо',
    'штиче',
    'штофа',
    'штофе',
    'штофу',
    'штрак',
    'штрап',
    'штрви',
    'штрек',
    'штрих',
    'штрка',
    'штрку',
    'штрои',
    'штрче',
    'штука',
    'штуки',
    'штуко',
    'штула',
    'штури',
    'штурм',
    'шугав',
    'шукне',
    'шуќур',
    'шумар',
    'шумее',
    'шумен',
    'шумка',
    'шумне',
    'шумор',
    'шумот',
    'шунка',
    'шупне',
    'шупур',
    'шурка',
    'шурна',
    'шурне',
    'шурти',
    'шутак',
    'шутар',
    'шутка',
    'шутко',
    'шутне',
    'шушка',
    'шушки',
    'шушли',
    'шушне',
    'шушол',
    'шушти',
]
